
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import React from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput
} from 'react-admin';

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const TargetCustomers = (props: any) => {
  const classes = useStyles();
  if (!props.record) { return <></>; }
  if (!props.record.id) { return <></>; }
  if (props.record.id) {
    return (
      <ReferenceManyToManyInput
        source="customer_id"
        reference="customers"
        through="discounts"
        using="discount_rule_id,customer_id"
        label="custom.target_customers"
        className={classes.mobileInput}
        {...props}
      >
        <ReferenceArrayInput
          source="customer_id"
          reference="customers"
          perPage={1000}
          sort={{ field: 'company_name', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            optionText="company_name"
            shouldRenderSuggestions={
              (val: any) => { return val.trim().length > 2 }
            }
          />
        </ReferenceArrayInput>
      </ReferenceManyToManyInput>
    );
  }
  return (<></>);
};

export default TargetCustomers;