import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import TruckList from "./TruckList";
import TruckEdit from "./TruckEdit";
import TruckCreate from "./TruckCreate";

const trucks = {
  list: TruckList,
  create: TruckCreate,
  edit: TruckEdit,
  icon: LocalShippingIcon,
};

export default trucks;
