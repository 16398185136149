
// in src/users.js
import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateDialog } from '@react-admin/ra-form-layout';
import React, { FC } from 'react';
import {
  BooleanInput,
  DeleteButton,

  PasswordInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions
} from 'react-admin';


const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const UserCreateTitle = (record: any) =>
  record
    ? <span>{record.username}</span>
    : null;


const UserCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {

  const { loading, permissions } = usePermissions();
  const condition = (
    !loading
    && permissions.includes("admin")
  );
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const UserCreateMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <CreateDialog
      {...props}
      title={<UserCreateTitle />}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<UserCreateToolbar />}
        style={{
          display: 'block',
          margin: 'auto',
          width: '100%',
        }}
      >
        <TextInput
          source="username"
          className={classes.mobileInput}
        />
        <PasswordInput
          source="password"
          className={classes.mobileInput}
        />
        <BooleanInput
          source="is_active"
          defaultValue={true}
          className={classes.mobileInput}
        />
        <BooleanInput
          source="is_superuser"
          defaultValue={false}
          className={classes.mobileInput}
        />
      </SimpleForm>
    </CreateDialog>
  );
};

export default UserCreateMobile;