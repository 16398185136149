import React, { FC } from 'react';
import { List, SimpleList, useGetOne } from 'react-admin';

const CustomerName = (record: any) => {
  const { data, loading, error } = useGetOne('customers', record["customer_id"]);
  if (loading) { return null; }
  if (error) { return null; }
  try {
    return (
      <>
        {
          data?.company_name
        }
      </>
    );
  }
  catch (e) {
    return <></>;
  };
};


const ShipoxTokenListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
        rowClick="edit"
      >
        <SimpleList
          primaryText={record => `${record.username} - ${record.password}`}
          secondaryText={record => (
            <>
              <CustomerName {...record} />
            </>
          )}
          tertiaryText={record => `${record.date_token.toLocaleString().slice(0, 10)}`}
          linkType="show"
        />
      </List>
    </>
  );
};

export default ShipoxTokenListMobile;