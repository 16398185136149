import React, { FC } from 'react';
import { List, SimpleList, useGetOne } from 'react-admin';

const CustomerName = (record: any) => {
  const { data, loading, error } = useGetOne('customers', record["customer_id"]);
  if (loading) { return null; }
  if (error) { return null; }
  try {
    return (
      <>
        {
          data?.company_name
        }
      </>
    );
  }
  catch (e) {
    return <></>;
  };
};

const AddressListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
      >
        <SimpleList
          primaryText={record => `${record.street}`}
          secondaryText={record => {
            return (
              <>
                {record.zipcode && `${record.zipcode} `}
                {`${record.city} `}
                {`${record.province} `}
              </>
            );
          }}
          tertiaryText={record => (
            <>
              <CustomerName {...record} />
            </>
          )}
        />
      </List>
    </>
  );
};

export default AddressListMobile;