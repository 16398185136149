import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import ExpenseList from "./ExpenseList";
import ExpenseEdit from "./ExpenseEdit";
import ExpenseCreate from "./ExpenseCreate";

const expenses = {
  list: ExpenseList,
  create: ExpenseCreate,
  edit: ExpenseEdit,
  icon: MoneyOffIcon,
};

export default expenses;
