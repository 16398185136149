import {
  Title,
  useGetList,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { EventRecord, Timeline } from "@react-admin/ra-audit-log";
import { Grid, Card, CardContent, Button } from "@material-ui/core";
import { Sync as SyncIcon } from "@material-ui/icons";
import styles from "./Staff.module.css";
import DashboardStaffProps from "./Staff.types";

const DashboardStaff: React.FC<DashboardStaffProps> = ({ isAdmin }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const { data, ids, loaded } = useGetList(
    "events",
    { page: 1, perPage: 10 },
    { field: "date", order: "DESC" }
  );

  //@ts-ignore
  const records: EventRecord[] = ids.map((id) => data[id]);

  const [syncCustomers, { loading: syncCustomersLoading }] = useMutation(
    {
      type: "create",
      resource: "admin/sync/customers",
      payload: { syncme: true },
    },
    {
      onSuccess: () => {
        refresh();
        notify("custom.customers_sync_ok");
      },
      onFailure: () => notify("custom.sync_error", "warning"),
    }
  );

  const [syncProducts, { loading: syncProductsLoading }] = useMutation(
    {
      type: "create",
      resource: "admin/sync/products",
      payload: { syncme: true },
    },
    {
      onSuccess: () => {
        refresh();
        notify("custom.products_sync_ok");
      },
      onFailure: () => notify("custom.sync_error", "warning"),
    }
  );

  const [syncTimeslots, { loading: syncTimeslotsLoading }] = useMutation(
    {
      type: "create",
      resource: "admin/sync/timeslots",
      payload: { syncme: true },
    },
    {
      onSuccess: () => {
        refresh();
        notify("custom.timeslots_sync_ok");
      },
      onFailure: () => notify("custom.sync_error", "warning"),
    }
  );

  const [restart, { loading: restartLoading }] = useMutation(
    {
      type: "create",
      resource: "admin/restart",
      payload: { syncme: true },
    },
    {
      onSuccess: () => {
        notify("custom.server_restart_ok");
      },
      onFailure: () => notify("custom.server_restart_error", "warning"),
    }
  );

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={5}>
        <Card elevation={2} className={styles.card}>
          <CardContent className={styles.cardContent}>
            <Button
              variant="contained"
              onClick={syncCustomers}
              color="primary"
              disabled={syncCustomersLoading}
              startIcon={<SyncIcon />}
              className={styles.button}
            >
              {translate("custom.sync_customers_fatture")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={syncProducts}
              disabled={syncProductsLoading}
              startIcon={<SyncIcon />}
              className={styles.button}
            >
              {translate("custom.sync_products_fatture")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={syncTimeslots}
              disabled={syncTimeslotsLoading}
              startIcon={<SyncIcon />}
              className={styles.button}
            >
              {translate("custom.sync_timeslots_shipox")}
            </Button>

            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={restart}
                disabled={restartLoading}
                startIcon={<SyncIcon />}
                className={styles.button}
              >
                {translate("custom.restart_server")}
              </Button>
            )}
          </CardContent>
        </Card>
      </Grid>
      {isAdmin && loaded && (
        <Grid item xs={12} sm={7}>
          <Card elevation={2} className={styles.timeline}>
            <Title title="custom.dashboard" />
            <CardContent>
              {records && <Timeline loaded={loaded} records={records} />}
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardStaff;
