import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import {
  FilterWithSave,
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";
import { cloneElement, FC } from "react";
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  NumberInput,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  usePermissions,
} from "react-admin";

const ListActions: FC<ListActionsProps> = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const { currentSort, resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="trucks.list.columns"
        columns={TruckListColumns}
      />
      <CreateButton />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const TruckCreateTitle = (record: any) =>
  record ? <span>{record.name}</span> : null;

const TruckCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const TruckFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput source="is_available" />
  </FilterWithSave>
);

const TruckListColumns = {
  id: <TextField source="id" />,
  name: <TextField source="name" />,
  capacity: <TextField source="capacity" />,
  is_available: <BooleanField source="is_available" />,
  gps: <TextField source="gps" />,
};

const TruckListDesktop: FC = ({ permissions, ...props }: any) => {
  const columns = useSelectedColumns({
    preferences: "trucks.list.columns",
    columns: TruckListColumns,
    omit: ["gps"],
  });

  return (
    <>
      <List
        empty={false}
        {...props}
        sort={{ field: "name", order: "ASC" }}
        perPage={25}
        filters={<TruckFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">{columns}</Datagrid>
      </List>
      <CreateDialog {...props} title={<TruckCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<TruckCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="name" />
          <NumberInput source="capacity" />
          <BooleanInput source="is_available" defaultValue={true} />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props} title={<TruckCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<TruckCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="name" />
          <NumberInput source="capacity" />
          <BooleanInput source="is_available" />
        </SimpleForm>
      </EditDialog>
    </>
  );
};

export default TruckListDesktop;
