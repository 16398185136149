// in src/users.js
import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import React, { FC, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DeleteButton,
  // Filter,
  List,
  PasswordInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  TopToolbar,
  // FilterButton,
  CreateButton,
  ExportButton,
  Button,
  SelectInput,
  useNotify,
  useRefresh,
  // useRedirect,
} from "react-admin";
// import LongPagination from '../Components/LongPagination';
import PersonAdd from "@material-ui/icons/PersonAdd";
// import { isPropsEqual } from '@fullcalendar/common';

// const PostFilter = (props: any) => (
//   <Filter {...props}>
//     <TextInput source="search" alwaysOn />
//   </Filter>
// );

const UserCreateTitle = (record: any) =>
  record ? <span>{record.username}</span> : null;

const UserCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const DriverCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const UserListDesktop: FC = ({ permissions, ...props }: any) => {
  const [showDialog, setDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  // const redirect = useRedirect();

  const ListActions = (...props: any) => {
    return (
      <TopToolbar>
        {/* <FilterButton/> */}
        <CreateButton />
        <ExportButton />
        {/* Add your custom actions */}
        <Button
          onClick={() => {
            setDialog(true);
          }}
          label="Add Driver"
        >
          <PersonAdd />
        </Button>
      </TopToolbar>
    );
  };

  const handleCloseDriver = () => {
    setDialog(false);
  };

  const onSuccess = ({ data }: any) => {
    notify(`Added driver ${data.username}`);
    setDialog(false);
    refresh();
  };

  return (
    <>
      <List {...props} actions={<ListActions />}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="username" />
          <BooleanField source="is_active" />
          <BooleanField source="is_superuser" />
          <TextField source="driver_role" />
        </Datagrid>
      </List>
      <CreateDialog {...props} title={<UserCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<UserCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="username" />
          <PasswordInput source="password" />
          <BooleanInput source="is_active" />
          <BooleanInput source="is_superuser" />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props} title={<UserCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<UserCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="name" />
          <TextInput source="surname" />
          <TextInput source="username" />
          <TextInput source="address_home" />
          <TextInput source="address_home2" />
          <TextInput source="address_home3" />
          <TextInput source="address_home4" />

          <SelectInput
            source="driver_role"
            choices={[
              { id: "SuperDriver", name: "SuperDriver" },
              { id: "Driver Advance", name: "Driver Advance" },
              { id: "Driver Basic", name: "Driver Basic" },
              { id: "Guest Driver", name: "Guest Driver" },
            ]}
          />

          <SelectInput
            source="default_hours"
            choices={[
              { id: "8:00", name: "8:00" },
              { id: "8:30", name: "8:30" },
              { id: "9:00", name: "9:00" },
              { id: "9:30", name: "9:30" },
              { id: "10:00", name: "10:00" },
              { id: "10:30", name: "10:30" },
              { id: "11:00", name: "11:00" },
              { id: "11:30", name: "11:30" },
              { id: "12:00", name: "12:00" },
            ]}
          />

          <PasswordInput source="password" />
          <BooleanInput source="is_active" defaultValue={true} />
          <BooleanInput source="is_superuser" defaultValue={false} />
        </SimpleForm>
      </EditDialog>
      <CreateDialog
        {...props}
        title="Add Driver"
        maxWidth="md"
        open={showDialog}
        onClose={handleCloseDriver}
        onCancel={handleCloseDriver}
        onSuccess={onSuccess}
      >
        <SimpleForm
          toolbar={<DriverCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="name" />
          <TextInput source="surname" />
          <TextInput source="username" />
          <TextInput source="address_home" />
          <TextInput source="address_home2" />
          <TextInput source="address_home3" />
          <TextInput source="address_home4" />
          <SelectInput
            source="driver_role"
            choices={[
              { id: "SuperDriver", name: "SuperDriver" },
              { id: "Driver Advance", name: "Driver Advance" },
              { id: "Driver Basic", name: "Driver Basic" },
              { id: "Guest Driver", name: "Guest Driver" },
            ]}
          />

          <SelectInput
            source="default_hours"
            choices={[
              { id: "8:00", name: "8:00" },
              { id: "8:30", name: "8:30" },
              { id: "9:00", name: "9:00" },
              { id: "9:30", name: "9:30" },
              { id: "10:00", name: "10:00" },
              { id: "10:30", name: "10:30" },
              { id: "11:00", name: "11:00" },
              { id: "11:30", name: "11:30" },
              { id: "12:00", name: "12:00" },
            ]}
          />

          <PasswordInput source="password" />
          <BooleanInput source="is_active" />
          <BooleanInput source="is_superuser" />
        </SimpleForm>
      </CreateDialog>
    </>
  );
};

export default UserListDesktop;
