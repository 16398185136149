//@ts-nocheck
import { Theme, useMediaQuery } from "@material-ui/core";
import { Layout } from "@react-admin/ra-enterprise";
import {
  AppLocationContext,
  Breadcrumb,
  ResourceBreadcrumbItems,
} from "@react-admin/ra-navigation";
import { SidebarOpenPreferenceSync } from "@react-admin/ra-preferences";
import polyglotI18nProvider from "ra-i18n-polyglot";
import React from "react";
import { AppBarProps, LayoutProps, resolveBrowserLocale } from "react-admin";
import messages from "../i18n";
import AppBarMobile from "./AppBarMobile";
import AppBarDesktop from "./AppBarDesktop";
import { EventList } from "@react-admin/ra-audit-log";

export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  resolveBrowserLocale(),
  { allowMissing: true }
);

export const CustomEventList = (props: any) => {
  return <EventList eventResource="events" authorResource="users" {...props} />;
};

export const CustomAppBar = (props: JSX.IntrinsicAttributes & AppBarProps) => {
  const isMobile = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down("md") // 1280px min
  );

  return <>{isMobile ? <AppBarMobile /> : <AppBarDesktop />}</>;
};

export const CustomLayout = ({
  children,
  ...props
}: JSX.IntrinsicAttributes & LayoutProps) => {
  return (
    <>
      <AppLocationContext>
        <SidebarOpenPreferenceSync />
        <Layout appBar={CustomAppBar} {...props}>
          <Breadcrumb hasDashboard {...props}>
            <ResourceBreadcrumbItems />
          </Breadcrumb>
          {children}
        </Layout>
      </AppLocationContext>
    </>
  );
};
