import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EditDialog } from '@react-admin/ra-form-layout';
import React, { FC } from 'react';
import {
  DeleteButton,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar
} from 'react-admin';


const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const AddressEditTitle = (record: any) =>
  record
    ? <span>{record.name}</span>
    : null;

const AddressEditToolbar = (props: any) => {
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <DeleteButton />
      <SaveButton />
    </Toolbar>
  );
};

const AddressEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <EditDialog
      {...props}
      title={<AddressEditTitle />}
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<AddressEditToolbar />}
        style={{
          display: 'block',
          margin: 'auto',
          width: '100%',
        }}
      >
        <TextField
          source="name"
          style={{
            fontWeight: 600,
            textAlign: 'center',
            display: 'block',
            margin: 'auto',
            marginBottom: '32px'
          }}
          addLabel={false}
        />
        <TextInput
          disabled
          source="id"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="street"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="zipcode"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="city"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="province"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="country"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="extra"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="place_id"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="lat"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="lng"
          className={classes.mobileInput}
        />
      </SimpleForm>
    </EditDialog>
  );
};

export default AddressEditMobile;