import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import DiscountRuleListDesktop from './DiscountRuleListDesktop';
import DiscountRuleEditMobile from './DiscountRuleEditMobile';

const DiscountRuleEdit: FC = (props: any) => {

    const isSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('md')
    );

    return (
        <>
            {
                isSmall
                    ? <DiscountRuleEditMobile {...props} />
                    : <DiscountRuleListDesktop {...props} />
            }
        </>
    );
};

export default DiscountRuleEdit;