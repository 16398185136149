import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { FC, useState, useEffect } from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  FormGroupContextProvider,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { AddressCreate, AddressPicker } from "../../Components/AddressPicker";
import { ContactCreate, ContactPicker } from "../../Components/ContactPicker";
import FattureTokenURL from "../../Components/FattureTokenURL";
import OrderEditToolbar from "../../Components/OrderEditToolbar";
import ShipoxURL from "../../Components/ShipoxURL";
import Aside from "./Aside";
import Basket from "./Basket";
import Payments from "./Payments";
import useStyles from "./orderStylesDesktop";

//@ts-ignore
const OrderEditForm: FC = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  // ADDRESSES
  // for picker
  const [address, setAddress] = useState<string | undefined>();
  // for create
  const [newAddress, setNewAddress] = useState<string | undefined>();
  const [addAddress, setAddAddress] = useState(false); // toggle to add a new
  const [saveAddress, setSaveAddress] = useState(false); // toggle to save that new

  // CONTACTS
  // for picker
  const [contact, setContact] = useState<string | undefined>();
  const [telephone, setTelephone] = useState<string | undefined>();
  // for create
  const [newContact, setNewContact] = useState<string | undefined>();
  const [newTelephone, setNewTelephone] = useState<string | undefined>();
  const [addContact, setAddContact] = useState(false); // toggle to add a new
  const [saveContact, setSaveContact] = useState(false); // toggle to save that new

  // on mount we set the default values of contact, telephone and address
  useEffect(() => {
    if (props.record.contact_name && props.record.telephone) {
      setContact(props.record.contact_name);
      setTelephone(props.record.telephone);
    }
    if (props.record.shipping_address) {
      setAddress(props.record.shipping_address);
    }
  }, [
    addAddress,
    addContact,
    setContact,
    setTelephone,
    setAddress,
    props.record,
  ]);

  // when addContact or addAddress are false we set the save props to false
  useEffect(() => {
    if (addContact === false) {
      setSaveContact(false);
    }
    if (addAddress === false) {
      setSaveAddress(false);
    }
  }, [addContact, addAddress]);

  return (
    <SimpleForm
      {...props}
      toolbar={
        <OrderEditToolbar
          saveAddress={saveAddress}
          saveContact={saveContact}
          setAddContact={setAddContact}
          setAddAddress={setAddAddress}
          address={addAddress ? newAddress : address}
          name={addContact ? newContact : contact}
          telephone={addContact ? newTelephone : telephone}
        />
      }
      style={{
        justifySelf: "center",
      }}
    >
      {/* <FormTab label="Order"> */}
      <FormGroupContextProvider name="orderDesktop">
        <Grid
          container
          spacing={1}
          className={classes.gridContainer}
          style={{ paddingBottom: "10px" }}
        >
          {/* First row with order info and customer info */}
          <Grid item xs={12} md={6}>
            <Paper elevation={2} className={classes.paperItem}>
              <SimpleShowLayout {...props}>
                <SimpleShowLayout {...props} className={classes.flexRow}>
                  <TextField
                    source="id"
                    addLabel={true}
                    className={classes.gridText}
                  />
                  <SelectInput
                    source="status"
                    choices={[
                      { id: "draft", name: "custom.orders.draft" },
                      { id: "pending", name: "custom.orders.pending" },
                      { id: "confirmed", name: "custom.orders.confirmed" },
                      { id: "delivered", name: "custom.orders.delivered" },
                      { id: "cancelled", name: "custom.orders.cancelled" },
                    ]}
                    style={{ width: "100%" }}
                  />
                </SimpleShowLayout>
                <FattureTokenURL />
                <BooleanInput source="update_fatture" />
                <BooleanInput source="show_fatture_doc" />

                <ShipoxURL />
                <BooleanInput source="update_shipox" />
                <ReferenceInput
                  label="Driver"
                  source="driver_id"
                  reference="users"
                  allowEmpty
                >
                  <SelectInput optionText="username" />
                </ReferenceInput>
              </SimpleShowLayout>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2} className={classes.paperItem}>
              <SimpleShowLayout {...props}>
                <ReferenceField
                  source="customer_id"
                  reference="customers"
                  addLabel={true}
                >
                  <TextField
                    source="company_name"
                    className={classes.gridText}
                  />
                </ReferenceField>

                {addAddress ? (
                  <>
                    <AddressCreate
                      setAddAddress={setAddAddress} // child can disable its state when finished
                      setNewAddress={setNewAddress}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveAddress}
                          onChange={() => setSaveAddress(!saveAddress)}
                          name="saveAddress"
                          color="primary"
                        />
                      }
                      label={translate("custom.save_address")}
                    />
                  </>
                ) : (
                  <>
                    <AddressPicker
                      props={props}
                      setAddAddress={setAddAddress} // child can toggle its create state on
                      address={address}
                      setAddress={setAddress}
                    />
                  </>
                )}

                {addContact ? (
                  <>
                    <ContactCreate
                      setAddContact={setAddContact} // child can disable its state when finished
                      setNewContact={setNewContact}
                      setNewTelephone={setNewTelephone}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveContact}
                          onChange={() => setSaveContact(!saveContact)}
                          name="saveContact"
                          color="primary"
                        />
                      }
                      label={translate("custom.save_contact")}
                    />
                  </>
                ) : (
                  <>
                    <ContactPicker
                      props={props}
                      setAddContact={setAddContact} //child can toggle its state to create
                      contact={contact}
                      setContact={setContact}
                      telephone={telephone}
                      setTelephone={setTelephone}
                    />
                  </>
                )}

                <DateInput
                  source="delivery_date"
                  className={classes.flexInput}
                  helperText={false}
                  locale="it-IT"
                />
                <ReferenceInput
                  source="timeslot_id"
                  reference="timeslots"
                  filter={{ is_active: true }}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput
                  multiline
                  source="internal_note"
                  className={classes.gridText}
                />
              </SimpleShowLayout>
            </Paper>
          </Grid>

          {/* Second row with basket */}
          <Grid item xs={12}>
            <Paper elevation={2} className={classes.paperItem}>
              <Basket {...props} />
            </Paper>
          </Grid>

          {/* Second row with payments */}
          <Grid item xs={12}>
            <Paper elevation={2} className={classes.paperItem}>
              <Payments {...props} />
            </Paper>
          </Grid>

          {/* Third row with accounting info */}
          <Grid item xs={12}>
            <Paper elevation={2} className={classes.paperItem}>
              <SimpleShowLayout {...props} className={classes.flexRowCenter}>
                <NumberField
                  source="shipping_weight"
                  addLabel={true}
                  className={classes.gridTextPadding}
                />
                <NumberField
                  source="taxable_amount"
                  addLabel={true}
                  options={{
                    style: "currency",
                    currency: "EUR",
                  }}
                  className={classes.gridTextPadding}
                />
                <NumberInput
                  source="tax_percentage"
                  min={0}
                  max={100}
                  validate={[
                    required(),
                    minValue(0, "custom.zero_min_error"),
                    maxValue(100, "custom.hundred_max_error"),
                  ]}
                  className={classes.gridTextPadding}
                />
                <NumberField
                  source="tax_amount"
                  addLabel={true}
                  options={{
                    style: "currency",
                    currency: "EUR",
                  }}
                  className={classes.gridTextPadding}
                />
                <NumberField
                  source="total_amount"
                  addLabel={true}
                  options={{
                    style: "currency",
                    currency: "EUR",
                  }}
                  className={classes.gridTextPadding}
                />
                <DateInput
                  source="due_date"
                  className={classes.gridTextPadding}
                />
              </SimpleShowLayout>
            </Paper>
          </Grid>
        </Grid>

        {/* </FormTab> */}
      </FormGroupContextProvider>
    </SimpleForm>
  );
};

const OrderEditDesktop: FC = (props: any) => {
  return (
    <>
      <Edit
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        aside={<Aside />}
        undoable={false}
        {...props}
      >
        <OrderEditForm />
      </Edit>
    </>
  );
};

export default OrderEditDesktop;
