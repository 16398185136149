import SyncIcon from "@material-ui/icons/Sync";
import { EditDialog } from "@react-admin/ra-form-layout";
import {
  FilterWithSave,
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";
import {
  ManyToManyReferenceContextProvider,
  ReferenceManyToManyField,
} from "@react-admin/ra-relationships";
import React, { cloneElement, FC } from "react";
import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Button,
  ChipField,
  Datagrid,
  DateField,
  DeleteButton,
  ExportButton,
  List,
  ListActionsProps,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  useMutation,
  useNotify,
  usePermissions,
  useRefresh,
} from "react-admin";
import LongPagination from "../Components/LongPagination";
import ReferenceAddressField from "../Components/ReferenceAddressField";
import TargetDiscountRules from "./TargetDiscountRules";

const PostFilter = (props: any) => (
  <FilterWithSave {...props}>
    <SearchInput source="search" alwaysOn />
    <BooleanInput source="split_payment" />
    <ReferenceInput source="segment" reference="segments">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="is_active" />
  </FilterWithSave>
);

const ListActions: FC<ListActionsProps> = ({
  //@ts-ignore
  maxResults,
  className,
  filters,
  ...rest
}) => {
  const refresh = useRefresh();
  const { filterValues, resource, displayedFilters, showFilter, currentSort } =
    useListContext();
  const notify = useNotify();
  const { loading, permissions }: any = usePermissions();

  const [sync, { loading: syncLoading }] = useMutation(
    {
      type: "create",
      resource: "admin/sync/customers",
      payload: { syncme: true },
    },
    {
      onSuccess: () => {
        refresh();
        notify("custom.customers_sync_ok");
      },
      onFailure: (error) => notify("custom.sync_error", "warning"),
    }
  );

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      <SelectColumnsButton
        preference="customers.list.columns"
        columns={customerListColumns}
      />
      {!loading ? (
        permissions.includes("admin") ? (
          <ExportButton
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={maxResults}
          />
        ) : null
      ) : null}

      <Button onClick={sync} label="custom.sync_fatture" disabled={syncLoading}>
        <SyncIcon />
      </Button>
    </TopToolbar>
  );
};

const CustomerEditTitle = (record: any) =>
  record ? <span>{record.company_name}</span> : null;

const CustomerEditToolbar = (props: any) => {
  const { loading, permissions } = usePermissions();
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {!loading && permissions.includes("admin") && <DeleteButton />}
      <SaveButton />
    </Toolbar>
  );
};

const customerListColumns = {
  id: <TextField source="id" />,
  fatture_id: <TextField source="fatture_id" />,
  username: <TextField source="username" />,
  name: <TextField source="company_name" />,
  custom_name: <TextField source="custom_name" />,
  main_contact: (
    <ReferenceField source="main_contact" reference="contacts">
      <TextField source="name" />
    </ReferenceField>
  ),
  contacts: (
    <ReferenceManyField
      target="customer_id"
      reference="contacts"
      label="custom.contacts"
    >
      <SingleFieldList linkType={false}>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceManyField>
  ),
  shipping_address: <ReferenceAddressField source="shipping_address" />,
  addresses: (
    <ReferenceManyField
      target="customer_id"
      reference="addresses"
      label="custom.addresses"
    >
      <SingleFieldList linkType={false}>
        <ChipField source="street" />
      </SingleFieldList>
    </ReferenceManyField>
  ),
  discount_rules: (
    <ReferenceManyToManyField
      source="id"
      reference="discount_rules"
      through="discounts"
      using="customer_id,discount_rule_id"
      label="custom.discount_rules"
      perPage={100}
    >
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceManyToManyField>
  ),

  segment: (
    <ReferenceField source="segment" reference="segments">
      <TextField source="name" />
    </ReferenceField>
  ),
  split_payment: <BooleanField source="split_payment" />,
  show_fatture_doc: <BooleanField source="show_fatture_doc" />,
  is_active: <BooleanField source="is_active" />,
  created_at: <DateField source="created_at" />,
  last_visit: <DateField source="last_visit" />,
};

const BulkActionsButtons = (props: any) => {
  const { loading, permissions } = usePermissions();
  const condition =
    (props.filterValues.status === "draft" ||
      props.filterValues.status === "cancelled") &&
    !loading &&
    permissions.includes("admin");

  return <>{condition && <BulkDeleteButton {...props} />}</>;
};

const CustomerListDesktop: FC = ({ permissions, ...props }: any) => {
  const columns = useSelectedColumns({
    preferences: "customers.list.columns",
    columns: customerListColumns,
    omit: [""],
  });
  return (
    <>
      <List
        {...props}
        empty={false}
        filters={<PostFilter />}
        actions={<ListActions />}
        bulkActionButtons={<BulkActionsButtons />}
        perPage={15}
        pagination={<LongPagination />}
      >
        <Datagrid optimized rowClick="edit">
          {columns}
        </Datagrid>
      </List>
      <EditDialog {...props} title={<CustomerEditTitle />} maxWidth="md">
        <ManyToManyReferenceContextProvider>
          <SimpleForm
            toolbar={<CustomerEditToolbar />}
            style={{ display: "block", margin: "auto" }}
          >
            <TextInput source="username" style={{ width: "100%" }} />
            <TextInput source="custom_name" style={{ width: "100%" }} />
            <PasswordInput source="password" style={{ width: "100%" }} />
            <ReferenceInput
              source="segment"
              reference="segments"
              style={{ width: "100%" }}
            >
              <SelectInput source="name" />
            </ReferenceInput>
            <BooleanInput source="is_active" />
            <BooleanInput source="split_payment" />
            <BooleanInput source="show_fatture_doc" />

            <TargetDiscountRules />
          </SimpleForm>
        </ManyToManyReferenceContextProvider>
      </EditDialog>
    </>
  );
};

export default CustomerListDesktop;
