import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import DiscountRuleListDesktop from './DiscountRuleListDesktop';
import DiscountRuleCreateMobile from './DiscountRuleCreateMobile';

const DiscountRuleCreate: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <DiscountRuleCreateMobile {...props} />
          : <DiscountRuleListDesktop {...props} />
      }
    </>
  );
};

export default DiscountRuleCreate;