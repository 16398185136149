import React, { FC } from 'react';


const PercentageField: FC = (props: any) => {
  if (!props.record) { return null; }
  const { record, source, className, style } = props;
  return (
    record && (
      <span className={className} style={style}>
        {
          (record[source] && record[source] !== 0)
            ? record[source].toFixed(2).concat(" %")
            : "-"
        }
      </span >
    )
  )
}

export default PercentageField;