import ScheduleIcon from '@material-ui/icons/Schedule';
import TimeslotList from './TimeslotList';
import TimeslotEdit from './TimeslotEdit';


const Timeslots = {
    list: TimeslotList,
    edit: TimeslotEdit,
    icon: ScheduleIcon,
};

export default Timeslots;