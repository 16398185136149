// @ts-nocheck

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { Theme, withStyles } from "@material-ui/core/styles";
import React from "react";
import { useGetMany } from "react-admin";

const ProductChip = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
  },
  label: {
    borderRadius: "20em",
    border: "5px solid #e0e0e0",
    textTransform: "capitalize",
    fontWeight: 600,
    color: "purple",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: "#ff99ff",
      },
    },
  },
}))(Chip);

const ArticleChip = (props: any) => {
  const orderItems = Object.values(props.data || {});
  const productList = Array.from(
    orderItems.map((item: any) => {
      return item.product_id;
    })
  );

  const resultObject = {};
  for (let orderItem of orderItems) {
    resultObject[orderItem.product_id] = {
      product_id: orderItem.product_id,
      quantity: orderItem.quantity,
    };
  }
  const { data, loading, error } = useGetMany("products", productList);
  if (loading) {
    return <span>loading...</span>;
  }
  if (error) {
    return <p>Error...</p>;
  }
  data.map((item) => {
    return item
      ? (resultObject[item.id] = {
          ...resultObject[item.id],
          abbreviation: item.abbreviation,
          code: item.code,
        })
      : null;
  });
  return (
    <>
      {Object.values(resultObject).map((item) => {
        if (item.code === "SC-R" || item.code === "S-CD") {
          return null;
        }
        return (
          <ProductChip
            avatar={
              <Avatar
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000000",
                  fontWeight: 600,
                  padding: 1,
                }}
              >
                {item.quantity}x
              </Avatar>
            }
            key={item.product_id}
            label={item.abbreviation || item.code}
            // onClick={() => history.push(`products/${item.product_id}`)}
          />
        );
      })}
    </>
  );
};

export default ArticleChip;
