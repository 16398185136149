import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import OrderEditDesktop from './desktop/OrderEditDesktop';
import OrderEditMobile from './mobile/OrderEditMobile';

const OrderEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );
  return (
    <>
      {
        isSmall
          ? <OrderEditMobile {...props} />
          : <OrderEditDesktop {...props} />
      }
    </>
  );
};

export default OrderEdit;