import React from 'react';

const AddressField = ({ record, source }: any) => {
  if (!record || !record[source]) { return null }
  try {
    const address = JSON.parse(record[source]);
    const fullAddress = [
      address.street,
      address.zipcode,
      address.province,
      address.city,
    ].filter(Boolean).join(", ");
    return (
      <span>
        {fullAddress}
      </span>
    );
  } catch (e) {
    console.log(e, record);
    return null;
  }
};


export default AddressField;