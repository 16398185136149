import React from "react";
import Typography from "@material-ui/core/Typography";

const ContactField = (props: any) => {
  if (!props.record) {
    return null;
  }
  return (
    <Typography>
      {props.record.contact_name} &nbsp;
      {props.record.telephone}
    </Typography>
  );
};

export default ContactField;
