import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FilterWithSave } from '@react-admin/ra-preferences';
import * as React from 'react';
import { FC, useState } from 'react';
import {
  CreateButton,
  FilterProps,
  List,
  sanitizeListRestProps,
  SearchInput,
  SimpleList,
  TopToolbar,
  useListContext,
  useTranslate
} from 'react-admin';
import useStyles from './orderStylesMobile';

export const OrderActions: FC = (props: any) => {
  const translate = useTranslate();
  const classes = useStyles();

  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    filterValues,
    setFilters,
  } = useListContext();

  const [filterDate, setFilterDate] = useState("today");
  const [filterStatus, setFilterStatus] = useState(filterValues.status || "all");

  const dateButtons = {
    all: <Button
      color="primary"
      variant="contained"
      className={classes.buttonAll}
      onClick={() => {
        //@ts-ignore
        setFilters({
          ...filterValues,
          "delivery_date": new Date(((new Date()).setDate((new Date()).getDate() - 1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
        });
        setFilterDate("yesterday");
      }}
    >
      {translate('custom.filters.all')}
    </Button>,
    yesterday: <Button
      color="primary"
      variant="contained"
      className={classes.buttonConfirmed}
      onClick={() => {
        // Golf to obtain the Date in ISO but with correct Timezone to avoid skipping over a day because of Timezone
        // Produces a string such as "2021-03-28T12:57:11.543Z" except it is not UTC it is the local time
        //@ts-ignore
        setFilters({
          ...filterValues,
          "delivery_date": new Date(((new Date()).setDate((new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
        });
        setFilterDate("today");
      }}
    >
      {translate('custom.filters.yesterday')}
    </Button>,
    today: <Button
      color="primary"
      variant="contained"
      className={classes.buttonConfirmed}
      onClick={() => {
        //@ts-ignore
        setFilters({
          ...filterValues,
          "delivery_date": new Date(((new Date()).setDate((new Date()).getDate() + 1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
        });
        setFilterDate("tomorrow");
      }}
    >
      {translate('custom.filters.today')}
    </Button>,
    tomorrow: <Button
      color="primary"
      variant="contained"
      className={classes.buttonConfirmed}
      onClick={() => {

        const currentFilters = { ...filterValues }
        delete currentFilters.delivery_date
        //@ts-ignore
        setFilters(currentFilters);
        setFilterDate("all");
      }}
    >
      {translate('custom.filters.tomorrow')}
    </Button>
  };

  const statusButtons = {
    all: <Button
      className={classes.buttonAll}
      color="primary"
      variant="contained"
      onClick={() => {
        //@ts-ignore
        setFilters({ ...filterValues, "status": "draft" });
        setFilterStatus("draft");
      }}
    >
      {translate('custom.filters.all')}
    </Button>,
    draft: <Button
      className={classes.buttonDraft}
      color="primary"
      variant="contained"
      onClick={() => {
        //@ts-ignore
        setFilters({ ...filterValues, "status": "pending" });
        setFilterStatus("pending");
      }}
    >
      {translate('custom.orders.draft')}
    </Button>,
    pending: <Button
      className={classes.buttonPending}
      color="primary"
      variant="contained"
      onClick={() => {
        //@ts-ignore
        setFilters({ ...filterValues, "status": "confirmed" });
        setFilterStatus("confirmed");
      }}
    >
      {translate('custom.orders.pending')}
    </Button>,
    confirmed: <Button
      className={classes.buttonConfirmed}
      color="primary"
      variant="contained"
      onClick={() => {
        //@ts-ignore
        setFilters({ ...filterValues, "status": "delivered" });
        setFilterStatus("delivered");
      }}
    >
      {translate('custom.orders.confirmed')}
    </Button>,
    delivered: <Button
      className={classes.buttonDelivered}
      color="primary"
      variant="contained"
      onClick={() => {
        //@ts-ignore
        setFilters({ ...filterValues, "status": "cancelled" });
        setFilterStatus("cancelled");
      }}
    >
      {translate('custom.orders.delivered')}
    </Button>,
    cancelled: <Button
      className={classes.buttonCancelled}
      color="primary"
      variant="contained"
      onClick={() => {
        const currentFilters = { ...filterValues }
        delete currentFilters.status
        //@ts-ignore
        setFilters(currentFilters);
        setFilterStatus("all");
      }}
    >
      {translate('custom.orders.cancelled')}
    </Button>
  };

  return (
    <TopToolbar
      {...sanitizeListRestProps(rest)}
    >
      {
        //@ts-ignore
        statusButtons[filterStatus]
      }
      {
        //@ts-ignore
        dateButtons[filterDate]
      }
      {/* {
        filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          selectedIds,
          context: 'button',
        })
      } */}
      <CreateButton basePath="/orders" />
    </TopToolbar >
  )
};

const OrderFilter: FC<Omit<FilterProps, 'children'>> = (props: any) => {
  return (
    <FilterWithSave {...props}>
      <SearchInput source="q" alwaysOn />
    </FilterWithSave>
  );
};

const CustomerName = (record: any) => {
  const classes = useStyles();
  return (
    <>
      {
        record.status === "draft" &&
        <Typography className={classes.titleDraft}>
          {record?.customer_name}
        </Typography>
      }
      {
        record.status === "pending" &&
        <Typography className={classes.titlePending}>
          {record?.customer_name}
        </Typography>
      }
      {
        record.status === "confirmed" &&
        <Typography className={classes.titleConfirmed}>
          {record?.customer_name}
        </Typography>
      }
      {
        record.status === "delivered" &&
        <Typography className={classes.titleDelivered}>
          {record?.customer_name}
        </Typography>
      }
      {
        record.status === "cancelled" &&
        <Typography className={classes.titleCancelled}>
          {record?.customer_name}
        </Typography>
      }
    </>
  )
}

const OrderListMobile: FC = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <List
        {...props}
        filterDefaultValues={{
          status: 'pending',
          "delivery_date": new Date(((new Date()).setDate((new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
        }}
        sort={{ field: 'delivery_time', order: 'ASC' }}
        filters={<OrderFilter />}
        actions={<OrderActions />}
        className={classes.screenWrap}
      >
        <SimpleList
          primaryText={record => (
            <>
              <CustomerName {...record} />
            </>
          )}
          secondaryText={record => `${record.delivery_note}`}
          tertiaryText={record => (
            <>
              <div style={{ textAlign: 'right' }}>
                {new Date(record.delivery_date).toLocaleDateString()}
                <br />
                {record.delivery_time}
              </div>
            </>
          )}
          linkType="show"
        />
      </List>
    </>
  );
};


export default OrderListMobile;