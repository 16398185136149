import React from "react";
import Typography from '@material-ui/core/Typography';

const readDateString = (dateString: string) => {
  if (!dateString) { return null }
  return dateString.replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$3/$2/$1');
};

const DeliveryDateField = (props: any) => {
  return (
    props.record ?
      <Typography>
        {readDateString(props.record.delivery_date)} <br />
        {props.record.delivery_time}
      </Typography>
      : null
  )
};

export default DeliveryDateField;