import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import React, { FC } from 'react';
import { List, SimpleList } from 'react-admin';

const TimeslotListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
        rowClick="edit"
      >
        <SimpleList
          primaryText={record => `${record.name}`}
          secondaryText={record => `${record.start_time} - ${record.end_time}`}
          tertiaryText={record => {
            return record.is_active ? <CheckIcon /> : <ClearIcon />
          }}
          linkType="show"
        />
      </List>
    </>
  );
};

export default TimeslotListMobile;