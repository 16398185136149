import SyncIcon from '@material-ui/icons/Sync';
import { EditDialog } from '@react-admin/ra-form-layout';
import { FilterWithSave, SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, FC } from 'react';
import {
  BooleanField,
  BooleanInput,
  Button,
  Datagrid,
  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  NumberField,
  NumberInput,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  useMutation,
  useNotify,
  usePermissions, useRefresh
} from 'react-admin';
import LongPagination from '../Components/LongPagination';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  formInput: {
    margin: 0,
    padding: 0,
  }
}));


const ListActions: FC<ListActionsProps> = (props: any) => {
  const refresh = useRefresh();
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  const notify = useNotify();

  const [sync, { loading: syncLoading }] = useMutation(
    {
      type: 'create',
      resource: 'admin/sync/products',
      payload: { "syncme": true }
    },
    {
      onSuccess: () => {
        refresh();
        notify('custom.products_sync_ok');
      },
      onFailure: error => notify('custom.sync_error', 'warning'),
    }
  );

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="products.list.columns"
        columns={productListColumns}
      />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />

      <Button
        onClick={sync}
        label="custom.sync_fatture"
        disabled={syncLoading}
      >
        <SyncIcon />
      </Button>

    </TopToolbar>
  );
};

const ProductFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="code" />
    <TextInput source="abbreviation" />
    <TextInput source="category" />
  </FilterWithSave>
);

const ProductEditTitle = (record: any) =>
  record
    ? <span>{record.name}</span>
    : null;

const ProductEditToolbar = (props: any) => {
  const { loading, permissions } = usePermissions();
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {!loading && permissions.includes("admin") && <DeleteButton />}
      <SaveButton />
    </Toolbar>
  );
};

const productListColumns = {
  id: <TextField source="id" />,
  fatture_id: <TextField source="fatture_id" />,
  code: <TextField source="code" />,
  name: <TextField source="name" />,
  shop_name: <TextField source="shop_name" />,
  abbreviation: <TextField source="abbreviation" />,
  fatture_category: <TextField source="fatture_category" />,
  net_price: <NumberField source="net_price" options={{ style: 'currency', currency: 'EUR' }} />,
  weight_kg: <NumberField source="weight_kg" />,
  length: <NumberField source="length" />,
  width: <NumberField source="width" />,
  height: <NumberField source="height" />,
  step: <NumberField source="step" />,
  shop_order: <NumberField source="shop_order" />,
  current_stock: <NumberField source="current_stock" />,
  shop_enabled: <BooleanField source="shop_enabled" />,
  fatture_enabled: <BooleanField source="fatture_enabled" />
};

const ProductListDesktop: FC = (props) => {
  const classes = useStyles();
  const columns = useSelectedColumns({
    preferences: 'products.list.columns',
    columns: productListColumns,
    omit: [''],
  });
  return (
    <>
      <List
        {...props}
        sort={{ field: 'code', order: 'DESC' }}
        perPage={15}
        empty={false}
        pagination={<LongPagination />}
        filters={<ProductFilter />}
        actions={<ListActions />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="edit">
          {columns}
        </Datagrid>
      </List>
      <EditDialog
        {...props}
        title={<ProductEditTitle />}
      >
        <SimpleForm
          toolbar={<ProductEditToolbar />}
        >
          <TextInput source="abbreviation" className={classes.formInput} />
          <NumberInput source="weight_kg" className={classes.formInput} />
          <NumberInput source="length" className={classes.formInput} />
          <NumberInput source="width" className={classes.formInput} />
          <NumberInput source="height" className={classes.formInput} />
          <NumberInput source="step" className={classes.formInput} />
          <TextInput source="shop_name" className={classes.formInput} />
          <NumberInput source="shop_order" className={classes.formInput} />
          <BooleanInput source="shop_enabled" className={classes.formInput} />
        </SimpleForm>
      </EditDialog>
    </>
  )
};

export default ProductListDesktop;