import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import OrderShowDesktop from './desktop/OrderShowDesktop';
import OrderShowMobile from './mobile/OrderShowMobile';

const OrderShow: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <OrderShowMobile {...props} />
          : <OrderShowDesktop {...props} />
      }
    </>
  );
};

export default OrderShow;