const raAuditLogLanguageItalian = {
  'ra-audit-log': {
    event: {
      create: 'Creato %{resource} %{name}',
      delete: 'Cancellato %{resource} %{name}',
      deleteMany: 'Cancellato %{resource} %{ids}',
      update: 'Aggiornato %{resource} %{name}',
      updateMany: 'Aggiornato %{resource} %{ids}',
    },
    inline_event: {
      create: 'creato %{resource} %{name}',
      delete: 'cancellato %{resource} %{name}',
      deleteMany: 'cancellato %{resource} %{ids}',
      update: 'aggiornato %{resource} %{name}',
      updateMany: 'aggiornato %{resource} %{ids}',
    },
    author: '{fullName}',
    period_filters: {
      today: 'Oggi',
      this_week: 'Questa settimana',
      this_month: 'Questo mese',
      this_quarter: 'Questo trimestre',
    },
    filter: {
      author: 'Per autore',
      date: 'Per data',
      resource: 'Per risorsa',
    },
    fields: {
      author: 'Autore',
      action: 'Azione',
      date: 'Data',
      resource: 'Risorsa',
    },
  },
};

export default raAuditLogLanguageItalian;