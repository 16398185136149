import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EditDialog } from '@react-admin/ra-form-layout';
import React, { FC } from 'react';
import {
  DateInput,
  DeleteButton,



  SaveButton,
  SimpleForm,
  TextField,

  TextInput,

  Toolbar,
  usePermissions
} from 'react-admin';


const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const ShipoxTokenEditTitle = (record: any) =>
  record
    ? <span>{record.name}</span>
    : null;

const ShipoxTokenEditToolbar = (props: any) => {
  const { loading, permissions } = usePermissions();
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {!loading && permissions.includes("admin") && <DeleteButton />}
      <SaveButton />
    </Toolbar>
  );
};

const ShipoxTokenEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <EditDialog
      {...props}
      title={<ShipoxTokenEditTitle />}
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<ShipoxTokenEditToolbar />}
        style={{
          display: 'block',
          margin: 'auto',
          width: '100%',
        }}
      >
        <TextField
          source="username"
          style={{
            fontWeight: 600,
            textAlign: 'center',
            display: 'block',
            margin: 'auto',
            marginBottom: '32px'
          }}
          addLabel={false}
        />
        <TextInput
          disabled
          source="id"
          className={classes.mobileInput}
        />
        <TextInput
          source="username"
          className={classes.mobileInput}
        />
        <TextInput
          source="password"
          className={classes.mobileInput}
        />
        <DateInput
          showTime
          disabled
          source="date_token"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="shipox_id"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="customer_id"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="user_id"
          className={classes.mobileInput}
        />
        <TextInput
          disabled
          source="token"
          className={classes.mobileInput}
        />
      </SimpleForm>
    </EditDialog>
  );
};

export default ShipoxTokenEditMobile;