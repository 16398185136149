import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BusinessIcon from '@material-ui/icons/Business';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EuroIcon from '@material-ui/icons/Euro';
import { FilterWithSave } from '@react-admin/ra-preferences';
import React, { FC } from 'react';
import { FilterProps, List, SearchInput, SimpleList, useGetOne } from 'react-admin';

const CustomerSegment = (record: any) => {
  const { data, loading, error } = useGetOne('segments', record["segment"]);
  if (loading) { return null; }
  if (error) { return null; }
  try {
    return (
      <>
        {
          data?.name === "B2B"
            ? <BusinessIcon />
            : <EmojiPeopleIcon />
        }
      </>
    );
  }
  catch (e) {
    return <></>;
  };
};


const CustomerFilter: FC<Omit<FilterProps, 'children'>> = (props: any) => {
  return (
    <FilterWithSave
      {...props}
    >
      <SearchInput
        source="q"
        alwaysOn
      />
    </FilterWithSave >
  );
};

const CustomerListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
        rowClick="edit"
        filters={<CustomerFilter />}
      >
        <SimpleList
          primaryText={record => (
            `${record.username} ${record.company_name}`
          )}
          secondaryText={record => (
            <>
              <CustomerSegment {...record} />
            </>
          )}
          tertiaryText={record => {
            return (record.split_payment || Number(record.payment_terms) !== 0) ? <AccountBalanceIcon /> : <EuroIcon />
          }}
          linkType="show"
        />
      </List>
    </>
  );
};

export default CustomerListMobile;