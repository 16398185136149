import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import TruckList from "./ExpenseList";
import TruckEditMobile from "./ExpenseEditMobile";

const UserEdit: FC = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <>{isSmall ? <TruckEditMobile {...props} /> : <TruckList {...props} />}</>
  );
};

export default UserEdit;
