
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
  gridContainer: {
    width: '900px',
    margin: 0,
    padding: 0,
  },
  paperItem: {
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    overflow: 'hidden',
    marginBottom: '8px',
  },
  datagrid: {
    maxWidth: '100vw !important',
    padding: 0,
    margin: 0,
    marginLeft: -15,
  },
  gridItem: {
    height: '100%',
  },
  gridText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1rem',
  },
  flexRow: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // marginRight: '10%',
    textAlign: 'center',
    width: '100%',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '5%',
    textAlign: 'center',
    width: '98%',
    margin: 0,
    padding: 0,
  },
  gridTextPadding: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1rem',
    padding: theme.spacing(0, 1)
  },
  flexRowItem: {
    display: 'grid',
    gridTemplateColumns: "repeat(3, 1fr)",
    textAlign: 'left',
    padding: 0,
    width: '100%',
  },
  flexInput: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  wrapper: {
    filter: 'blur(5px)',
    userSelect: 'none',
    pointerEvents: 'none',
    transition: '0.3s',
  },
  wrapperText: {
    textAlign: 'center',
    textDecoration: 'italic',
    color: theme.palette.error.dark,
  },
}));

export default useStyles;