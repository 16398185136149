import SettingsIcon from '@material-ui/icons/Settings';
import SettingList from './SettingList';
import SettingEdit from './SettingEdit';


const Settings = {
    list: SettingList,
    edit: SettingEdit,
    icon: SettingsIcon,
};

export default Settings;