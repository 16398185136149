// in src/users.js
import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CreateDialog } from "@react-admin/ra-form-layout";
import React, { FC, useState } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Toolbar,
  usePermissions,
} from "react-admin";
import { components } from 'react-select';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import poweredByGoogle from '../Components/powered_by_google_on_white.png';
import AddressPickerSingle from '../Components/AddressPickerSingle';

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: "100%",
    margin: 0,
    padding: 0,
  },
}));

type Place = {
  description: string;
  place_id: string;
  label: string;
  lat: string;
  lng: string;
}

const WarehouseCreateMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const [place, setPlace] = useState<Place>({label:'', description:'', place_id:'', lat:'', lng:''});

  const WarehouseCreateTitle = (record: any) =>
  record ? <span>{record.username}</span> : null;

  // const handleSave = (values: any, extraData:any) => {
  //   debugger;
  // }

  const transformWarehouse = (data:any) => (
    {
      ...data,
      latitude: place.lat,
      longitude: place.lng,
      address: place.label
  });

  const WarehouseCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {

    const { loading, permissions } = usePermissions();
    const condition = (
      !loading
      && permissions.includes("admin")
    );
    return (
      <Toolbar
        {...props}
        style={{
          display: 'flex',
          justifyContent: condition ? "space-between" : "flex-end",
        }}
      >
        {condition && <DeleteButton />}
        <SaveButton redirect="list" transform={transformWarehouse} />
        {/* <SaveButton redirect="list" onSave={handleSave} /> */}
      </Toolbar>
    );
  };

  return (
    <CreateDialog
      {...props}
      title={<WarehouseCreateTitle />}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<WarehouseCreateToolbar />}
        style={{
          display: "block",
          margin: "auto",
          width: "100%",
        }}
        {...props}
      >
        <TextInput source="name" />
        <AddressPickerSingle props={props} newAddress={setPlace} />
        <SelectInput source="typology" style={{ zIndex: 0 }} choices={[
          { id: 'basepoint', name: 'Basepoint' },
          { id: 'checkpoint', name: 'Checkpoint' },
        ]} />
        <SelectInput source="hour_start" style={{ zIndex: 0 }} choices={[
          { id: '8:00', name: '8:00' },
          { id: '8:30', name: '8:30' },
          { id: '9:00', name: '9:00' },
          { id: '9:30', name: '9:30' },
          { id: '10:00', name: '10:00' },
          { id: '10:30', name: '10:30' },
          { id: '11:00', name: '11:00' },
          { id: '11:30', name: '11:30' },
          { id: '12:00', name: '12:00' },
          { id: '13:00', name: '13:00' },
          { id: '14:00', name: '14:00' },
          { id: '15:00', name: '15:00' },
          { id: '16:00', name: '16:00' },
          { id: '17:00', name: '17:00' },
          { id: '18:00', name: '18:00' },
          { id: '19:00', name: '19:00' },
        ]} />
        <SelectInput source="hour_end" style={{ zIndex: 0 }} choices={[
          { id: '8:00', name: '8:00' },
          { id: '8:30', name: '8:30' },
          { id: '9:00', name: '9:00' },
          { id: '9:30', name: '9:30' },
          { id: '10:00', name: '10:00' },
          { id: '10:30', name: '10:30' },
          { id: '11:00', name: '11:00' },
          { id: '11:30', name: '11:30' },
          { id: '12:00', name: '12:00' },
          { id: '13:00', name: '13:00' },
          { id: '14:00', name: '14:00' },
          { id: '15:00', name: '15:00' },
          { id: '16:00', name: '16:00' },
          { id: '17:00', name: '17:00' },
          { id: '18:00', name: '18:00' },
          { id: '19:00', name: '19:00' },
        ]} />
      </SimpleForm>
    </CreateDialog>
  );
};

export default WarehouseCreateMobile;
