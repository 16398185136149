
import { TableCell, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CancelEditButton, EditableDatagrid, SaveRowButton } from '@react-admin/ra-editable-datagrid';
import React, { FC, ReactElement } from 'react';
import {
  AutocompleteInput,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  TextField,
  useFormGroup,
  useTranslate
} from 'react-admin';
import { Form } from 'react-final-form';
import PercentageField from '../../Components/PercentageField';
import datagridStyles from './basketStyles';
import useStyles from './orderStylesMobile';

const OrderItemEditForm: FC = (props: any) => {
  const classes = useStyles();
  const {
    record,
    id,
    quitEditMode,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    undoable,
    ...rest
  } = props;

  return (
    <Form
      initialValues={{ ...record }}
      onSubmit={save}
      {...rest}
    >
      {({ handleSubmit, invalid, dirty }): ReactElement => (
        <TableRow className={classes.formRow} key={id}>
          <TableCell className={classes.formCell}>
            <ReferenceInput
              source="product_id"
              reference="products"
              label=""
              perPage={100}
              filter={{ shop_enabled: true }}
              sort={{ field: 'name', order: 'ASC' }}
              className={classes.datagridInput}
              record={record}
              resource={resource}
              basePath={basePath}
            >
              <AutocompleteInput source="name" />
            </ReferenceInput>

          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              source="quantity"
              validate={required()}
              label={<></>}
              record={record}
              resource={resource}
              basePath={basePath}
              className={classes.datagridInput}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              source="discount_percentage"
              defaultValue={0}
              min={0}
              label={<></>}
              max={100}
              validate={
                [
                  minValue(0, "custom.zero_min_error"),
                  maxValue(100, "custom.hundred_max_error"),
                ]
              }
              record={record}
              resource={resource}
              basePath={basePath}
              className={classes.datagridInput}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            <SaveRowButton
              dirty={dirty}
              handleSubmit={handleSubmit}
              invalid={invalid}
              quitEditMode={quitEditMode}
              saving={saving}
              undoable={undoable}
            />
            <CancelEditButton cancel={quitEditMode} />
          </TableCell>
        </TableRow>
      )}
    </Form>
  );
};

const OrderItemCreateForm: FC = (props: any) => {
  const classes = useStyles();
  const {
    record,
    id,
    quitEditMode,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    undoable,
    ...rest
  } = props;

  return (
    <Form
      initialValues={{ ...record }}
      onSubmit={save}
      {...rest}
    >
      {({ handleSubmit, invalid, dirty }): ReactElement => (
        <TableRow className={classes.formRow} key={id}>
          <TableCell className={classes.formCell}>
            <ReferenceInput
              source="product_id"
              reference="products"
              perPage={100}
              filter={{ shop_enabled: true }}
              sort={{ field: 'name', order: 'ASC' }}
              className={classes.datagridInput}
              record={record}
              resource={resource}
              basePath={basePath}
              label=""
            >
              <AutocompleteInput
                source="name"
              />
            </ReferenceInput>

          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              source="quantity"
              validate={required()}
              record={record}
              resource={resource}
              basePath={basePath}
              className={classes.datagridInput}
              label={<></>}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              label={<></>}
              disabled
              source="discount_percentage"
              defaultValue={0}
              min={0}
              max={100}
              validate={
                [
                  minValue(0, "custom.zero_min_error"),
                  maxValue(100, "custom.hundred_max_error"),
                ]
              }
              record={record}
              resource={resource}
              basePath={basePath}
              className={classes.datagridInput}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              label={<></>}
              disabled
              source="net_price"
              className={classes.datagridInput}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            <SaveRowButton
              dirty={dirty}
              handleSubmit={handleSubmit}
              invalid={invalid}
              quitEditMode={quitEditMode}
              saving={saving}
              undoable={undoable}
            />
            <CancelEditButton cancel={quitEditMode} />
          </TableCell>
        </TableRow>
      )}
    </Form>
  );
};

const BasketWrapper = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const formGroupState = useFormGroup("orderMobile");
  if (formGroupState.invalid || formGroupState.dirty) {
    return <>
      <div>
        <Typography
          className={classes.wrapperText}
        >
          {translate('custom.save_changes_products')}
        </Typography>
      </div>
      <div className={classes.wrapper}>
        {props.children}
      </div>
    </>;
  }
  return (
    <>
      {props.children}
    </>
  );
};

const BasketItems: FC = (props: any) => {
  const datagrid = datagridStyles();
  const classes = useStyles();
  const id = props.record.id;
  return (
    <ReferenceManyField
      target="order_id"
      reference="order_items"
      addLabel={false}
      {...props}
    >
      <EditableDatagrid
        undoable
        rowClick="edit"
        classes={{
          table: datagrid.table,
          thead: datagrid.thead,
          tbody: datagrid.tbody,
          headerCell: datagrid.headerCell,
          headerRow: datagrid.headerRow,
          row: datagrid.row,
          rowCell: datagrid.rowCell,
        }}
        createForm={
          <OrderItemCreateForm
            //@ts-ignore
            initialValues={{ order_id: id }}
          />
        }
        editForm={<OrderItemEditForm />}
      >
        <ReferenceField
          source="product_id"
          reference="products"
          label="custom.mobile_datagrid.product_id"
        >
          <TextField
            source="abbreviation"
            className={classes.datagridText}
          />
        </ReferenceField>
        <NumberField
          source="quantity"
          className={classes.datagridText}
          label="custom.mobile_datagrid.quantity"
        />
        <PercentageField
          //@ts-ignore
          source="discount_percentage"
          label="custom.mobile_datagrid.discount_percentage"
          style={{
            float: 'right',
          }}
          className={classes.datagridText}
        />
        <NumberField
          label="custom.mobile_datagrid.net_price"
          source="net_price"
          options={{
            style: 'currency',
            currency: 'EUR',
          }}
          className={classes.datagridText}
        />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

const BasketMobile: FC = (props: any) => {
  if (!props.record) { return <></>; }
  return (
    <>
      <BasketWrapper {...props}>
        <BasketItems {...props} />
      </BasketWrapper>
    </>
  );
};

export default BasketMobile;