import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import TimeslotListDesktop from './TimeslotListDesktop';
import TimeslotEditMobile from './TimeslotEditMobile';

const TimeslotEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <TimeslotEditMobile {...props} />
          : <TimeslotListDesktop {...props} />
      }
    </>
  );
};

export default TimeslotEdit;