import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import React from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput
} from 'react-admin';


const TargetDiscountRules = (props: any) => {
  if (!props.record) { return <></>; }
  if (!props.record.id) { return <></>; }
  if (props.record.id) {
    return (
      <ReferenceManyToManyInput
        source="id"
        reference="discount_rules"
        through="discounts"
        using="customer_id,discount_rule_id"
        label="custom.discount_rules"
        fullWidth
        {...props}
      >
        <ReferenceArrayInput
          source="name"
          reference="discount_rules"
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            optionText="name"
          />
        </ReferenceArrayInput>
      </ReferenceManyToManyInput>
    );
  }
  return (<></>);
};

export default TargetDiscountRules;