import React from 'react';
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  usePermissions,
  useNotify,
  useRefresh
} from 'react-admin';

const OrderEditToolbar = (
  props: any,
) => {
  const { loading, permissions } = usePermissions();
  const condition = (
    props.record
    && props.record.status === "draft"
    && !loading
    && permissions.includes("admin")
  );

  const {
    saveAddress,
    saveContact,
    setAddContact,
    setAddAddress,
    address,
    name,
    telephone,
    ...rest
  } = props;

  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Toolbar
      {...rest}
      style={{
        display: 'flex',
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {
        condition && <DeleteButton />
      }
      <SaveButton
        transform={data => ({
          ...data,
          shipping_address: address,
          contact_name: name,
          telephone: telephone,
          save_address: saveAddress,
          save_contact: saveContact,
        })}
        redirect={false}
        onSuccess={() => {
          setAddContact(false);
          setAddAddress(false);
          notify('custom.order_updated');
          refresh();
        }}
        submitOnEnter={true}
        label="custom.save_and_stay"
      />
      <SaveButton
        transform={data => ({
          ...data,
          shipping_address: address,
          contact_name: name,
          telephone: telephone,
          save_address: saveAddress,
          save_contact: saveContact,
        })}
        redirect="show"
        submitOnEnter={false}
      />
    </Toolbar >
  );
};

export default OrderEditToolbar;