import { stringify } from "query-string";
import { HttpError } from "react-admin";
import { axiosHttp } from "./axiosHttp";

const httpClient = axiosHttp;

const axiosDataProvider = {
  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };
    const url = `/${resource}?${stringify(query)}`;
    return httpClient
      .get(url)
      .then((response) => {
        return {
          data: response.data,
          total: parseInt(
            response.headers["content-range"].split("/").pop(),
            10
          ),
        };
      })
      .catch((error) => {
        console.log("error", error);
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  getOne: (resource: any, params: any) => {
    return httpClient
      .get(`/${resource}/${params.id}`)
      .then((response) => ({
        data: response.data,
      }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `/${resource}?${stringify(query)}`;
    return httpClient
      .get(url)
      .then((response) => ({ data: response.data }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  getManyReference: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;
    return httpClient
      .get(url)
      .then((response) => ({
        data: response.data,
        total: parseInt(response.headers["content-range"].split("/").pop(), 10),
      }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  update: (resource: any, params: any) => {
    return httpClient
      .put(`/${resource}/${params.id}`, JSON.stringify(params.data))
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  updateMany: (resource: any, params: any) => {
    return Promise.all(
      params.ids.map((id: string) => {
        return httpClient.put(
          `/${resource}/${id}`,
          JSON.stringify(params.data)
        );
      })
    )
      .then((responses) => ({
        data: responses.map((response: any) => response.data.id),
      }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  create: (resource: any, params: any) => {
    return httpClient
      .post(`/${resource}`, JSON.stringify(params.data))
      .then((response) => ({
        data: { ...params.data, id: response.data.id },
      }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  delete: (resource: any, params: any) => {
    return httpClient
      .delete(`/${resource}/${params.id}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },

  deleteMany: (resource: any, params: any) => {
    return Promise.all(
      params.ids.map((id: string) => {
        return httpClient.delete(`/${resource}/${id}`);
      })
    )
      .then((responses) => ({
        data: responses.map((response: any) => response.data.id),
      }))
      .catch((error) => {
        return Promise.reject(
          new HttpError(
            (error.response && error.response.data.detail) ||
              error.response.statusText,
            error.response.status,
            error.response
          )
        );
      });
  },
};

export default axiosDataProvider;
