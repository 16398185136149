import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import React, { useState } from "react";
import {
  Button,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany
} from 'react-admin';

const AllConfirmButton = withStyles((theme) => ({
  button: {
    border: `2px solid ${theme.palette.primary.dark}`,
    marginTop: '-10px',
    fontWeight: 600,
    fontSize: '1rem',
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1, 4),
    "&:hover": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark,
      }
    }
  }
}))(Button);


const CancelButton = withStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(1, 1, 1, 0),
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      }
    }
  }
}))(Button);

const ConfirmButton = withStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      }
    }
  }
}))(Button);


const OrderDeliverButton = (props: any) => {

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const translate = useTranslate();

  const [open, setOpen] = useState(false);
  const [checkedFatture, setCheckedFatture] = useState(true);
  const [checkedShipox, setCheckedShipox] = useState(true);

  const [updateMany, { loading }] = useUpdateMany(
    'orders',
    props.selectedIds,
    {
      status: "confirmed",
      update_shipox: checkedShipox,
      update_fatture: checkedFatture,
    },
    {
      onSuccess: () => {
        refresh();
        notify('custom.orders_confirmed');
        unselectAll('orders');
      },
      onFailure: error => notify('custom.orders_confirmed_error', 'warning'),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AllConfirmButton
        label="custom.confirm_orders"
        variant="contained"
        disabled={loading}
        onClick={handleClickOpen}
      >
        <DoneAllIcon />
      </AllConfirmButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {translate('custom.mass_confirm')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('custom.mass_confirm_question')}
          </DialogContentText>
          <ul>
            {
              props.selectedIds.map((id: string) => <li key={id}>{id}</li>)
            }
          </ul>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedFatture}
                onChange={() => setCheckedFatture(!checkedFatture)}
                name="checkedFatture"
              />
            }
            label={translate('custom.update_fatture')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedShipox}
                onChange={() => setCheckedShipox(!checkedShipox)}
                name="checkedShipox"
              />
            }
            label={translate('custom.update_shipox')}
          />
        </DialogContent>
        <DialogActions>
          <CancelButton
            onClick={handleClose}
            variant="contained"
            label="ra.action.cancel"
          />
          <ConfirmButton
            onClick={updateMany}
            color="primary"
            variant="contained"
            label="custom.confirm_orders"
          />

        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderDeliverButton;