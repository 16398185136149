import * as React from "react";
import { usePermissions } from "react-admin";
import DashboardStaff from "./staff/Staff";
import DashboardDriver from "./driver/Driver";

const RenderDashboard: React.FC = (props: any) => {
  const { loading, permissions } = usePermissions();
  if (loading) return null;
  const isStaff = permissions.includes("staff");
  if (isStaff) {
    const isAdmin = permissions.includes("admin");

    return <DashboardStaff isAdmin={isAdmin} />;
  } else {
    return <DashboardDriver {...props} />;
  }
};

export const Dashboard: React.FC = (props: any) => {
  return <RenderDashboard {...props} />;
};
