import React, { FC } from 'react';
import {
  Datagrid,
  EditButton,
  NumberField,
  ReferenceField,
  ReferenceManyField, Show, SimpleShowLayout, TextField, TopToolbar
} from 'react-admin';
import AddressField from '../../Components/AddressField';
import { ItalianDateField } from '../../Components/ItalianDateField';
import PercentageField from '../../Components/PercentageField';
import StatusChip from '../../Components/StatusChip';
import FattureTokenURL from '../../Components/FattureTokenURL';
import useStyles from './orderStylesMobile';
import datagridStyles from './basketStyles';
import ShipoxURL from '../../Components/ShipoxURL';

const OrderShowMobileActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <EditButton
        basePath={basePath}
        record={data}
      />
      {/* Add your custom actions */}
    </TopToolbar >
  );
};

const OrderShowMobile: FC = (props: any) => {
  const classes = useStyles();
  const datagrid = datagridStyles();
  return (
    <>
      <Show
        actions={<OrderShowMobileActions />}
        {...props}
        className={classes.screenWrap}
      >
        <SimpleShowLayout {...props}>
          <StatusChip source="status" addLabel={true} />
          <ReferenceField
            source="customer_id"
            reference="customers"
            addLabel={true}
          >
            <TextField
              source="company_name"
              className={classes.gridText}
            />
          </ReferenceField>
          <FattureTokenURL />
          <ShipoxURL />

          <AddressField
            source="shipping_address"
            addLabel={true}
            className={classes.gridText}
          />
          <TextField
            source="contact_name"
            addLabel={true}
            className={classes.gridText}
          />
          <TextField
            source="telephone"
            addLabel={false}
            className={classes.gridText}
          />
          <ItalianDateField
            source="delivery_date"
            addLabel={true}
            locales="it-IT"
            className={classes.gridText}
          />
          <TextField
            source="delivery_time"
            addLabel={false}
            className={classes.gridText}
          />
          <TextField
            source="delivery_instructions"
            addLabel={true}
            className={classes.gridText}
          />
          <TextField
            source="internal_note"
            addLabel={true}
            className={classes.gridText}
          />

          <ReferenceManyField
            addLabel={false}
            target="order_id"
            reference="order_items"
          >
            <Datagrid
              className={classes.datagrid}
              classes={{
                table: datagrid.table,
                thead: datagrid.thead,
                tbody: datagrid.tbody,
                headerCell: datagrid.headerCell,
                headerRow: datagrid.headerRow,
                row: datagrid.row,
                rowCell: datagrid.rowCell,
              }}
            >
              <ReferenceField
                source="product_id"
                reference="products"
                label="custom.mobile_datagrid.product_id"
              >
                <TextField
                  source="abbreviation"
                  className={classes.gridText}
                />
              </ReferenceField>
              <NumberField
                source="quantity"
                className={classes.gridText}
                label="custom.mobile_datagrid.quantity"
              />
              <PercentageField
                //@ts-ignore
                source="discount_percentage"
                label="custom.mobile_datagrid.discount_percentage"
                addLabel={true}
                style={{ float: 'right' }}
                className={classes.gridText}
              />
              <NumberField
                label="custom.mobile_datagrid.net_price"
                source="net_price"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
                className={classes.gridText}
              />
            </Datagrid>
          </ReferenceManyField>
          <NumberField
            source="shipping_weight"
            addLabel={true}
            className={classes.gridText}
          />
          <NumberField
            source="total_amount"
            addLabel={true}
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
            className={classes.gridText}
          />
          <ItalianDateField
            source="due_date"
            addLabel={false}
            className={classes.gridText}
          />
          <FattureTokenURL />
          <ShipoxURL />
          <TextField
            source="driver_name"
            addLabel={true}
            className={classes.gridText}
          />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default OrderShowMobile;