import ApartmentIcon from '@material-ui/icons/Apartment';

import WarehouseList from "./WarehouseList";
import WarehouseEdit from "./WarehouseEdit";
import WarehouseCreate from "./WarehouseCreate";

const warehouses = {
  list: WarehouseList,
  create: WarehouseCreate,
  edit: WarehouseEdit,
  icon: ApartmentIcon,
};

export default warehouses;
