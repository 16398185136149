
import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateDialog } from '@react-admin/ra-form-layout';
import React, { FC } from 'react';
import {
  SimpleForm,
  TextInput
} from 'react-admin';


const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const DiscountRuleCreateMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (

    <CreateDialog
      {...props}
      fullScreen={isSmall}
    >
      <SimpleForm
        style={{
          display: 'block',
          margin: 'auto',
          width: '100%',
        }}
      >
        <TextInput
          source="name"
          className={classes.mobileInput}
        />
      </SimpleForm>
    </CreateDialog>
  );
};

export default DiscountRuleCreateMobile;