import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import {
  FilterWithSave,
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";
import { cloneElement, FC, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  NumberInput,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  Toolbar,
  TopToolbar,
  useListContext,
  usePermissions,
} from "react-admin";
import AddressPickerSingle from '../Components/AddressPickerSingle';

const ListActions: FC<ListActionsProps> = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const { currentSort, resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="warehouses.list.columns"
        columns={WarehouseListColumns}
      />
      <CreateButton />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const WarehouseCreateTitle = (record: any) =>
  record ? <span>{record.name}</span> : null;

const WarehouseFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
  </FilterWithSave>
);

const WarehouseListColumns = {
  id: <TextField source="id" />,
  name: <TextField source="name" />,
  address: <TextField source="address" />,
  hour_start: <TextField source="hour_start" />,
  hour_end: <TextField source="hour_end" />,
};

type Place = {
  description: string;
  place_id: string;
  label: string;
  lat: string;
  lng: string;
}

const WarehouseListDesktop: FC = ({ permissions, ...props }: any) => {
  const [address, setAddress] = useState<string | undefined>();
  const [place, setPlace] = useState<Place>({label:'', description:'', place_id:'', lat:'', lng:''});

  const transformWarehouse = (data:any) => (
    {
      ...data,
      latitude: place.lat,
      longitude: place.lng,
      address: place.label
  });
  
  const WarehouseCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
    const { loading, permissions } = usePermissions();
    const condition = !loading && permissions.includes("admin");
    return (
      <Toolbar
        {...props}
        style={{
          display: "flex",
          justifyContent: condition ? "space-between" : "flex-end",
        }}
      >
        {condition && <DeleteButton />}
        <SaveButton redirect="list" transform={transformWarehouse} />
      </Toolbar>
    );
  };

  const columns = useSelectedColumns({
    preferences: "warehouses.list.columns",
    columns: WarehouseListColumns
  });

  return (
    <>
      <List
        empty={false}
        {...props}
        sort={{ field: "name", order: "ASC" }}
        perPage={25}
        filters={<WarehouseFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">
          {columns}
        </Datagrid>
      </List>
      <CreateDialog {...props} title={<WarehouseCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<WarehouseCreateToolbar />}
          style={{
            display: "block",
            margin: "auto",
            width: "100%",
          }}
          {...props}
        >
          <TextInput source="name" />
          <AddressPickerSingle props={props} newAddress={setPlace} />
          <SelectInput source="typology" style={{ zIndex: 0 }} choices={[
            { id: 'basepoint', name: 'Basepoint' },
            { id: 'checkpoint', name: 'Checkpoint' },
          ]} />
          <SelectInput source="hour_start" style={{ zIndex: 0 }} choices={[
            { id: '8:00', name: '8:00' },
            { id: '8:30', name: '8:30' },
            { id: '9:00', name: '9:00' },
            { id: '9:30', name: '9:30' },
            { id: '10:00', name: '10:00' },
            { id: '10:30', name: '10:30' },
            { id: '11:00', name: '11:00' },
            { id: '11:30', name: '11:30' },
            { id: '12:00', name: '12:00' },
            { id: '13:00', name: '13:00' },
            { id: '14:00', name: '14:00' },
            { id: '15:00', name: '15:00' },
            { id: '16:00', name: '16:00' },
            { id: '17:00', name: '17:00' },
            { id: '18:00', name: '18:00' },
            { id: '19:00', name: '19:00' },
          ]} />
          <SelectInput source="hour_end" style={{ zIndex: 0 }} choices={[
            { id: '8:00', name: '8:00' },
            { id: '8:30', name: '8:30' },
            { id: '9:00', name: '9:00' },
            { id: '9:30', name: '9:30' },
            { id: '10:00', name: '10:00' },
            { id: '10:30', name: '10:30' },
            { id: '11:00', name: '11:00' },
            { id: '11:30', name: '11:30' },
            { id: '12:00', name: '12:00' },
            { id: '13:00', name: '13:00' },
            { id: '14:00', name: '14:00' },
            { id: '15:00', name: '15:00' },
            { id: '16:00', name: '16:00' },
            { id: '17:00', name: '17:00' },
            { id: '18:00', name: '18:00' },
            { id: '19:00', name: '19:00' },
          ]} />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props} title={<WarehouseCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<WarehouseCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="name" />
          {/* <AddressPickerSingle props={props} address={address} setAddress={setAddress} /> */}
          <SelectInput source="typology" style={{ zIndex: 0 }} choices={[
            { id: 'basepoint', name: 'Basepoint' },
            { id: 'checkpoint', name: 'Checkpoint' },
          ]} />
          <SelectInput source="hour_start" choices={[
            { id: '8:00', name: '8:00' },
            { id: '8:30', name: '8:30' },
            { id: '9:00', name: '9:00' },
            { id: '9:30', name: '9:30' },
            { id: '10:00', name: '10:00' },
            { id: '10:30', name: '10:30' },
            { id: '11:00', name: '11:00' },
            { id: '11:30', name: '11:30' },
            { id: '12:00', name: '12:00' },
            { id: '13:00', name: '13:00' },
            { id: '14:00', name: '14:00' },
            { id: '15:00', name: '15:00' },
            { id: '16:00', name: '16:00' },
            { id: '17:00', name: '17:00' },
            { id: '18:00', name: '18:00' },
            { id: '19:00', name: '19:00' },
          ]} />
          <SelectInput source="hour_end" choices={[
            { id: '8:00', name: '8:00' },
            { id: '8:30', name: '8:30' },
            { id: '9:00', name: '9:00' },
            { id: '9:30', name: '9:30' },
            { id: '10:00', name: '10:00' },
            { id: '10:30', name: '10:30' },
            { id: '11:00', name: '11:00' },
            { id: '11:30', name: '11:30' },
            { id: '12:00', name: '12:00' },
            { id: '13:00', name: '13:00' },
            { id: '14:00', name: '14:00' },
            { id: '15:00', name: '15:00' },
            { id: '16:00', name: '16:00' },
            { id: '17:00', name: '17:00' },
            { id: '18:00', name: '18:00' },
            { id: '19:00', name: '19:00' },
          ]} />
        </SimpleForm>
      </EditDialog>
    </>
  );
};

export default WarehouseListDesktop;
