
import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { EditDialog } from '@react-admin/ra-form-layout';
import { ManyToManyReferenceContextProvider } from '@react-admin/ra-relationships';
import React, { FC } from 'react';
import {
  AutocompleteInput,
  DeleteButton,
  maxValue,
  minValue,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions
} from 'react-admin';
import PercentageField from '../Components/PercentageField';
import TargetCustomers from './TargetCustomers';

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const DiscountRuleForm: FC = props => {
  return (
    <RowForm {...props}>
      <ReferenceInput
        source="product_id"
        reference="products"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={100}
      >
        <AutocompleteInput
          optionText="name"
        />
      </ReferenceInput>
      <NumberInput
        source="discount_percentage"
        min={0}
        max={100}
        validate={
          [
            minValue(0, "custom.zero_min_error"),
            maxValue(100, "custom.hundred_max_error"),
          ]
        }
      />
    </RowForm>
  );
};

const DiscountRuleToolbar = (props: any) => {
  const { loading, permissions } = usePermissions();
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {!loading && permissions.includes("admin") && <DeleteButton />}
      <SaveButton />
    </Toolbar>
  );
};

const DiscountRuleCreateMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );
  const { id } = props;

  return (
    <EditDialog
      {...props}
      id={id}
      fullWidth
      maxWidth="md"
      fullScreen={isSmall}
    >
      <ManyToManyReferenceContextProvider>
        <SimpleForm
          toolbar={<DiscountRuleToolbar />}
          undoable={false}
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%',
          }}
        >
          <TextInput
            source="name"
            className={classes.mobileInput}
          />

          <ReferenceManyField
            target="discount_rule_id"
            reference="discount_items"
            className={classes.mobileInput}

          >
            <EditableDatagrid
              undoable
              createForm={
                <DiscountRuleForm
                  //@ts-ignore
                  initialValues={{ discount_rule_id: id }}
                />
              }
              editForm={<DiscountRuleForm />}
            >
              <ReferenceField
                source="product_id"
                reference="products"
                label="custom.target_products"
              >
                <TextField source="name" />
              </ReferenceField>
              <PercentageField
                //@ts-ignore
                source="discount_percentage"
              />
            </EditableDatagrid>
          </ReferenceManyField>

          <TargetCustomers />

        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </EditDialog>
  );
};

export default DiscountRuleCreateMobile;