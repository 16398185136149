import { Divider, Tab, Tabs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { CreateDialog } from "@react-admin/ra-form-layout";
import {
  FilterWithSave,
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";
import jsonExport from "jsonexport/dist";
import * as React from "react";
import { cloneElement, FC, useCallback, useState } from "react";
import {
  AutocompleteInput,
  BulkDeleteButton,
  CreateButton,
  Datagrid,
  DatagridProps,
  DateInput,
  downloadCSV,
  ExportButton,
  FilterProps,
  List,
  ListContextProvider,
  ListProps,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  sanitizeListRestProps,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  useListContext,
  usePermissions,
  useTranslate,
} from "react-admin";
import AddressField from "../../Components/AddressField";
import ArticleChip from "../../Components/ArticleChip";
import ContactField from "../../Components/ContactField";
import DeliveryDateField from "../../Components/DeliveryDateField";
import { ItalianDateTimeField } from "../../Components/ItalianDateField";
import LongPagination from "../../Components/LongPagination";
import OrderConfirmButton from "../../Components/OrderConfirmButton";
// import OrderDeliverButton from '../../Components/OrderDeliverButton';
import OrderDriverButton from "../../Components/OrderDriverButton";
import ProductCounterBar from "../../Components/ProductCounterBar";

interface Address {
  id: string;
  street: string;
  zipcode: string;
  city: string;
  province: string;
  extra: string;
  country: string;
  customer_id: string;
}

const exporter = (records: any) => {
  const addressFormatting = (jsonAddress: Address) => {
    const address = JSON.parse(String(jsonAddress));
    const fullAddress = [
      address.street,
      address.zipcode,
      address.province,
      address.city,
    ]
      .filter(Boolean)
      .join(", ");
    return fullAddress;
  };

  const selectedColumns = records.map((record: any) => {
    const {
      customer_name, // COMPANY NAME
      contact_name, // CONTACT
      delivery_instructions, // CUSTOMER NOTES
      delivery_time, // TIME SLOT (only the slot such as [10: 30-12: 30]
      delivery_note, // "ABBREVITION" ITEMS TO BE DELIVERED
      internal_note, // TEAM NOTES
      segment, // B2B / B2C
      shipping_address, // ADDRESS
      telephone, // PHONE NUMBER
      total_amount, //AMOUNT
      driver_id, //AMOUNT
    } = record;
    const selectedColumns = {
      customer_name,
      contact_name,
      delivery_instructions,
      delivery_time,
      delivery_note,
      internal_note,
      segment,
      shipping_address,
      telephone,
      total_amount,
      driver_id,
    };
    selectedColumns.delivery_note = delivery_note.replace("\n", "+");
    selectedColumns.total_amount = total_amount + " EUR";
    selectedColumns.shipping_address = addressFormatting(shipping_address);
    return selectedColumns;
  });

  jsonExport(
    selectedColumns,
    {
      headers: [
        "customer_name",
        "delivery_note",
        "delivery_time",
        "shipping_address",
        "contact_name",
        "telephone",
        "total_amount",
        "delivery_instructions",
        "internal_note",
        "segment",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "orders"); // download as 'posts.csv` file
    }
  );
};

const OrderFilter: FC<Omit<FilterProps, "children">> = (props: any) => {
  return (
    <FilterWithSave {...props}>
      <SearchInput source="q" alwaysOn />
      <TextInput source="customer_name" />
      <SelectInput
        source="segment"
        choices={[
          { id: "B2C", name: "B2C" },
          { id: "B2B", name: "B2B" },
        ]}
        allowEmpty={false}
      />
      <DateInput source="created_at_gte" />
      <DateInput source="created_at_lte" />
      <DateInput source="delivery_date_lte" />
      <DateInput source="delivery_date_gte" />
      <NumberInput source="total_amount_lte" />
      <NumberInput source="total_amount_gte" />
      <ReferenceInput
        label="Driver"
        source="driver_id"
        reference="users"
        allowEmpty
      >
        <SelectInput optionText="username" />
      </ReferenceInput>
      <SelectInput
        source="last_payment_status"
        choices={[
          { id: "Payed in cash", name: "Payed in cash" },
          { id: "Contabile", name: "Contabile" },
          { id: "Pos", name: "Pos" },
          { id: "Link", name: "Link" },
          { id: "Parziale", name: "Parziale" },
          { id: "Insoluto", name: "Insoluto" },
          { id: "Assegno", name: "Assegno" },
          { id: "Conto 1", name: "Conto 1" },
          { id: "Conto 2", name: "Conto 2" },
        ]}
      />
    </FilterWithSave>
  );
};

interface TabbedDatagridProps extends DatagridProps {}

const useGetTotals = (filterValues: any) => {
  const { total: totalDraft } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: "draft" }
  );
  const { total: totalPending } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: "pending" }
  );
  const { total: totalConfirmed } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: "confirmed" }
  );
  const { total: totalDelivered } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: "delivered" }
  );
  const { total: totalCancelled } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: "cancelled" }
  );

  return {
    draft: totalDraft,
    pending: totalPending,
    confirmed: totalConfirmed,
    delivered: totalDelivered,
    cancelled: totalCancelled,
  };
};

const orderListColumns = {
  created_at: <ItalianDateTimeField source="created_at" />,
  confirmed_at: <ItalianDateTimeField source="confirmed_at" />,
  confirmed_by: <TextField source="confirmed_by" />,
  cancelled_at: <ItalianDateTimeField source="cancelled_at" />,
  cancelled_by: <TextField source="cancelled_by" />,
  customer_id: (
    <ReferenceField source="customer_id" reference="customers">
      <TextField source="company_name" />
    </ReferenceField>
  ),
  articles: (
    <ReferenceManyField
      reference="order_items"
      target="order_id"
      label="custom.products"
    >
      <ArticleChip />
    </ReferenceManyField>
  ),
  delivery_date: (
    <DeliveryDateField source="delivery_date" sortBy="delivery_time" />
  ),
  delivery_address: <AddressField source="shipping_address" />,
  contact: <ContactField label="custom.contact" />,
  delivery_instructions: <TextField source="delivery_instructions" />,
  internal_note: <TextField source="internal_note" />,
  id: <TextField source="id" />,
  segment: <TextField source="segment" />,
  total: (
    <NumberField
      source="total_amount"
      options={{
        style: "currency",
        currency: "EUR",
      }}
    />
  ),
  driver_id: (
    <ReferenceField source="driver_id" reference="users">
      <TextField source="username" />
    </ReferenceField>
  ),
  last_payment_status: <TextField source="last_payment_status" />,
  last_payment_amount: (
    <NumberField
      source="last_payment_amount"
      options={{
        style: "currency",
        currency: "EUR",
      }}
    />
  ),
};

const OrderBulkActionButtons = (props: any) => {
  const { loading, permissions } = usePermissions();
  const condition =
    (props.filterValues.status === "draft" ||
      props.filterValues.status === "cancelled") &&
    !loading &&
    permissions.includes("admin");

  return (
    <>
      {condition && (
        <BulkDeleteButton
          {...props}
          style={{
            fontWeight: 600,
            fontSize: "1rem",
            border: "2px solid red",
            minHeight: "50px",
            marginTop: "-10px",
            marginRight: "40px",
            paddingLeft: "32px",
            paddingRight: "32px",
          }}
        />
      )}
      {(props.filterValues.status === "draft" ||
        props.filterValues.status === "pending") && (
        <OrderConfirmButton {...props} />
      )}

      {props.filterValues.status === "pending" && (
        <OrderDriverButton {...props} />
      )}
    </>
  );
};

export const OrderActions: FC = (props: any) => {
  const translate = useTranslate();
  const [filterDate, setFilterDate] = useState("today");

  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    setFilters,
    selectedIds,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <ButtonGroup color="primary" style={{ marginRight: 16 }}>
        <Button
          disabled={filterDate === "all"}
          onClick={() => {
            const currentFilters = { ...filterValues };
            delete currentFilters.delivery_date;
            //@ts-ignore
            setFilters(currentFilters);
            setFilterDate("all");
          }}
        >
          {translate("custom.filters.all")}
        </Button>
        <Button
          disabled={filterDate === "yesterday"}
          onClick={() => {
            // Golf to obtain the Date in ISO but with correct Timezone to avoid skipping over a day because of Timezone
            // Produces a string such as "2021-03-28T12:57:11.543Z" except it is not UTC it is the local time
            //@ts-ignore
            setFilters({
              ...filterValues,
              delivery_date: new Date(
                new Date().setDate(new Date().getDate() - 1) -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .slice(0, 10),
            });
            setFilterDate("yesterday");
          }}
        >
          {translate("custom.filters.yesterday")}
        </Button>
        <Button
          disabled={filterDate === "today"}
          onClick={() => {
            //@ts-ignore
            setFilters({
              ...filterValues,
              delivery_date: new Date(
                new Date().setDate(new Date().getDate()) -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .slice(0, 10),
            });
            setFilterDate("today");
          }}
        >
          {translate("custom.filters.today")}
        </Button>
        <Button
          disabled={filterDate === "tomorrow"}
          onClick={() => {
            //@ts-ignore
            setFilters({
              ...filterValues,
              delivery_date: new Date(
                new Date().setDate(new Date().getDate() + 1) -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .slice(0, 10),
            });
            setFilterDate("tomorrow");
          }}
        >
          {translate("custom.filters.tomorrow")}
        </Button>
      </ButtonGroup>

      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          selectedIds,
          context: "button",
        })}

      <CreateButton basePath="/orders" />
      <SelectColumnsButton
        preference="orders.list.columns"
        columns={orderListColumns}
      />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const InnerDataGrid: FC = (props: any) => {
  const listContext = useListContext();
  const { ids } = listContext;
  const columns = useSelectedColumns({
    preferences: "orders.list.columns",
    columns: orderListColumns,
    omit: [""],
  });

  return (
    <>
      <ListContextProvider value={{ ...listContext, ids: ids }}>
        <Datagrid {...props} optimized rowClick="show">
          {columns}
          <ProductCounterBar {...props} />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

const TabbedDatagrid: FC<TabbedDatagridProps> = (props: any) => {
  const listContext = useListContext();
  const translate = useTranslate();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const totals = useGetTotals(filterValues);

  const tabs = [
    { id: "draft", name: translate("custom.orders.draft") },
    { id: "pending", name: translate("custom.orders.pending") },
    { id: "confirmed", name: translate("custom.orders.confirmed") },
    { id: "delivered", name: translate("custom.orders.delivered") },
    { id: "cancelled", name: translate("custom.orders.cancelled") },
  ];

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              //@ts-ignore
              totals[choice.id]
                ? //@ts-ignore
                  `${choice.name} (${totals[choice.id]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <InnerDataGrid {...props} />
    </>
  );
};

const OrderListDesktop: FC<ListProps> = (props: any) => {
  return (
    <>
      <List
        {...props}
        filterDefaultValues={{
          status: "pending",
          delivery_date: new Date(
            new Date().setDate(new Date().getDate()) -
              new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 10),
        }}
        sort={{ field: "delivery_time", order: "ASC" }}
        perPage={15}
        pagination={<LongPagination />}
        filters={<OrderFilter />}
        bulkActionButtons={<OrderBulkActionButtons />}
        actions={<OrderActions />}
        style={{ marginBottom: 200 }}
        exporter={exporter}
      >
        <TabbedDatagrid />
      </List>
      <CreateDialog {...props}>
        <SimpleForm>
          <ReferenceInput
            source="customer_id"
            reference="customers"
            perPage={1000}
            sort={{ field: "company_name", order: "ASC" }}
          >
            <AutocompleteInput
              optionText="company_name"
              shouldRenderSuggestions={(val: any) => {
                return val.trim().length > 2;
              }}
              validate={required()}
            />
          </ReferenceInput>
          <SelectInput
            source="status"
            defaultValue="pending"
            choices={[
              { id: "draft", name: "custom.orders.draft" },
              { id: "pending", name: "custom.orders.pending" },
            ]}
            style={{ width: "100%" }}
            validate={required()}
          />
          <ReferenceInput
            label="Driver"
            source="driver_id"
            reference="users"
            allowEmpty
            filter={{
              driver_role_nnil: true,
            }}
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        </SimpleForm>
      </CreateDialog>
    </>
  );
};

export default OrderListDesktop;
