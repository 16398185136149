import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import WarehouseList from "./WarehouseList";
import WarehouseEditMobile from "./WarehouseEditMobile";

const WarehouseEdit: FC = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <>{isSmall ? <WarehouseEditMobile {...props} /> : <WarehouseList {...props} />}</>
  );
};

export default WarehouseEdit;
