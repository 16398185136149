// import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import UserList from './UserList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';


const users = {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    icon: AssignmentIndIcon,
};

export default users;