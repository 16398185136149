import React, { FC } from 'react';
import { List, SimpleList, useGetOne } from 'react-admin';

const CustomerName = (record: any) => {
  const { data, loading, error } = useGetOne('customers', record["customer_id"]);
  if (loading) { return null; }
  if (error) { return null; }
  try {
    return (
      <>
        {
          data?.company_name
        }
      </>
    );
  }
  catch (e) {
    return <></>;
  };
};

const ContactListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
      >
        <SimpleList
          primaryText={record => `${record.name}`}
          secondaryText={record => {
            return (
              <>
                {record.telephone && `${record.telephone} `}
                {record.email && `${record.email} `}
                {record.electronic_post && `${record.electronic_post} `}
                {record.fax && `${record.fax} `}
              </>
            );
          }}
          tertiaryText={record => (
            <>
              <CustomerName {...record} />
            </>
          )}
        />
      </List>
    </>
  );
};

export default ContactListMobile;