import { useGetOne } from "react-admin";
import React from "react";

const ReferenceAddressField = (props: any) => {
  const { record, source } = props;
  const { data, loading, error } = useGetOne('addresses', record[source]);
  if (loading || error) { return null; }
  try {
    const fullAddress = [
      data?.street,
      data?.zipcode,
      data?.province,
      data?.city,
    ].filter(Boolean).join(", ");
    return (
      <span>
        {fullAddress}
      </span>
    );
  }
  catch (e) {
    return null;
  }
};

export default ReferenceAddressField;