import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import ReportListDesktop from "./ReportListDesktop";
import ReportListMobile from "./ReportListMobile";

const ExpenseList: FC = (props: any) => {
  const isMobile = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down("md") // 1280px min
  );

  return (
    <>
      {isMobile ? (
        <ReportListMobile {...props} />
      ) : (
        <ReportListDesktop {...props} />
      )}
    </>
  );
};

export default ExpenseList;
