//@ts-nocheck
import { AppBar } from "@react-admin/ra-enterprise";
import React from "react";
import {
  AppBarProps
} from "react-admin";


const AppBarMobile: FC = (props: JSX.IntrinsicAttributes & AppBarProps) => (
  <AppBar {...props} />
);

export default AppBarMobile;