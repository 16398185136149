import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { CreateDialog } from "@react-admin/ra-form-layout";
import React, { FC } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import useStyles from './orderStylesMobile';

const OrderCreateMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  // const FullNameField = ({ record }:any) => <span>{record.username} {record.driver_role}</span>;

  return (
    <>
      <CreateDialog
        {...props}
        fullScreen={isSmall}
        className={classes.screenWrap}
      >
        <SimpleForm>
          <ReferenceInput
            source="customer_id"
            reference="customers"
            perPage={1000}
            sort={{ field: "company_name", order: "ASC" }}
            className={classes.mobileInput}
          >
            <AutocompleteInput
              optionText="company_name"
              shouldRenderSuggestions={(val: any) => {
                return val.trim().length > 2;
              }}
              validate={required()}
              className={classes.mobileInput}
            />
          </ReferenceInput>
          <SelectInput
            source="status"
            defaultValue="pending"
            choices={[
              { id: "draft", name: "custom.orders.draft" },
              { id: "pending", name: "custom.orders.pending" },
            ]}
            className={classes.mobileInput}
            validate={required()}
          />
          <ReferenceInput
            label="Driver"
            source="driver_id"
            reference="users"
            allowEmpty
            filter={{
              driver_role_nnil: true,
            }}
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        </SimpleForm>
      </CreateDialog>
    </>
  );
};

export default OrderCreateMobile;
