import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DiscountRuleList from './DiscountRuleList';
import DiscountRuleEdit from './DiscountRuleEdit';
import DiscountRuleCreate from './DiscountRuleCreate';

const discountRules = {
    list: DiscountRuleList,
    create: DiscountRuleCreate,
    edit: DiscountRuleEdit,
    icon: LocalOfferIcon,
};

export default discountRules;
