import React, { FC } from 'react';
import { List, SimpleList } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const TelegramListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
      >
        <SimpleList
          primaryText={record => `${record.name} ${record.trigger}`}
          secondaryText={record => record.message}
          tertiaryText={record => record.is_active ? <CheckIcon /> : <ClearIcon />}
          linkType="edit"
        />
      </List>
      <div
        style={{
          padding: 16,
          margin: 16,
        }}
      >
        COMMANDS:
          <ul>
          <li>[cliente]</li>
          <li>[consegna]</li>
          <li>[contatto]</li>
          <li>[indirizzo]</li>
          <li>[nome]</li>
          <li>[ordine]</li>
          <li>[prodotti]</li>
          <li>[status]</li>
          <li>[telefono]</li>
          <li>[timeslot]</li>
          <li>[totale]</li>
        </ul>
      </div>
    </>
  );
};

export default TelegramListMobile;