const customItalianMessages = {
  custom: {
    dashboard: 'Cruscotto',
    customers_sync_ok: 'Clienti sincronizzati con successo.',
    products_sync_ok: 'Prodotti sincronizzati con successo.',
    timeslots_sync_ok: 'I timeslot sono stati sincronizzati con successo.',
    sync_error: 'Impossibile sincronizzare.',
    sync_fatture: 'Sincronizzazione con Fatture in Cloud',
    sync_timeslots: 'Sincronizzazione con ShipOx',
    sync_customers_fatture: 'Sincronizza i clienti con Fatture in Cloud',
    sync_products_fatture: 'Sincronizza i prodotti con Fatture in Cloud',
    sync_timeslots_shipox: 'Sincronizza i timeslots con ShipOx',
    save_changes_products: 'Salva le modifiche prima di modificare i prodotti',
    contacts: 'Contatti',
    contact: 'Contatto',
    addresses: 'Indirizzi',
    discount_rules: 'Regole di sconto',
    status: "Stato",
    dli: {
      rules: {
        select: 'Seleziona regole',
        unselect: 'Rimuovi regole',
        availableItems: 'Regole disponibili',
        selectedItems: 'Regole selezionate',
      },
      customers: {
        select: 'Seleziona i clienti',
        unselect: 'Rimuovi clienti',
        availableItems: 'Clienti disponibili',
        selectedItems: 'Seleziona clienti',
      }
    },
    target_products: 'Prodotti di destinazione',
    products: 'Prodotti',
    target_customers: 'Clienti target',
    zero_min_error: "0 è il minimo",
    hundred_max_error: "100 è il massimo",
    orders: {
      draft: 'bozza',
      pending: 'attesa',
      confirmed: 'confermato',
      delivered: 'consegnato',
      cancelled: 'cancellato',
    },
    filters: {
      all: 'Tutti',
      yesterday: 'Ieri',
      today: 'Oggi',
      tomorrow: 'Domani',
    },
    order_updated: "Ordine aggiornato",
    orders_confirmed: "Gli ordini sono stati confermati",
    order_confirmed_error: "Impossibile aggiornare gli ordini",
    confirm_orders: 'Confermare gli ordini',
    pcb: {
      draft: 'Totale prodotti in bozze di ordini selezionati',
      pending: 'Totale dei prodotti negli ordini in attesa selezionati',
      confirmed: 'Totale dei prodotti negli ordini confermati selezionati',
      delivered: 'Totale dei prodotti negli ordini consegnati selezionati',
      cancelled: 'Totale dei prodotti negli ordini cancellati selezionati',
    },
    menu: {
      dashboard: 'Cruscotto',
      orders: 'Ordini',
      customers: 'Clienti',
      products: 'Prodotti',
      discount_rules: 'Regole di sconto',
      users: 'Utente',
      events: 'Eventi',
      settings: 'Impostazioni',
    },
    mobile_datagrid: {
      product_id: "Prodotto",
      quantity: "Quantita",
      discount_percentage: "Sconto",
      net_price: "Prezzo",
    },
    save_and_stay: "Salva e rimani",
    mass_confirm: "Conferma di massa",
    mass_confirm_question: "Vuoi confermare tutti questi ordini?",
    update_fatture: "Aggiorna Fatture in Cloud",
    update_shipox: "Aggiorna ShipOx",
    restart_server: "Riavviare il server",
    server_restart_error: 'Errore durante il riavvio',
    server_restart_ok: 'Server riavviato, attendere 30 secondi',
    telephone: 'Telefono',
    delivery_address: 'Indirizzo di consegna',
    save_contact: 'Salva contatto',
    save_address: 'Salva indirizzo',
    deliver_orders: "Contrassegna gli ordini come consegnati",
    mass_deliver: "Consegna in massa",
    mass_deliver_question: "Vuoi contrassegnare tutti questi ordini come consegnati?",
    orders_delivered: "Gli ordini sono stati contrassegnati come consegnati ",
    order_delivered_error: "Impossibile aggiornare gli ordini come consegnati",
    customer_name: "Nome cliente",
    telegram: {
      "admin_create_order": "Ordine creato dal team",
      "admin_create_order_urgent": "Urgente ordine creato dal team",
      "admin_update_order": "Ordine aggiornato dal team",
      "admin_update_order_urgent": "Urgente ordine aggiornato dal team",
      "admin_confirm_order": "Ordine confermato dal team",
      "admin_confirm_order_urgent": "Urgente ordine confermato dal team",
      "admin_deliver_order": "Ordine segnato come consegnato dal team",
      "admin_deliver_order_urgent": "Urgente ordine segnato come consegnato dal team",
      "admin_cancel_order": "Ordine annullato dal team",
      "admin_cancel_order_urgent": "Urgente ordine annullato dal team",
      "customer_create_order": "Ordine creato dal cliente",
      "customer_create_order_urgent": "Urgente ordine creato dal cliente",
      "customer_confirm_order": "Ordine confermato dal cliente",
      "customer_confirm_order_urgent": "Urgente ordine confermato dal cliente",
      "customer_cancel_order": "Ordine annullato dal cliente",
      "customer_cancel_order_urgent": "Urgente ordine annullato dal cliente",
    }
  }
};

export default customItalianMessages;