import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import TruckListDesktop from "./TruckListDesktop";
import TruckListMobile from "./TruckListMobile";

const TruckList: FC = (props: any) => {
  const isMobile = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down("md") // 1280px min
  );

  return (
    <>
      {isMobile ? (
        <TruckListMobile {...props} />
      ) : (
        <TruckListDesktop {...props} />
      )}
    </>
  );
};

export default TruckList;
