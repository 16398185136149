import jwt_decode from "jwt-decode";
import { login, logout } from "./axiosHttp";

const authProvider = {
  permissions: ["none"],
  identity: { id: "", username: "" },

  login: async ({ username, password }: any) => {
    return login({ username, password }).then((access_token: any) => {
      const decoded: any = jwt_decode(access_token);
      if (
        decoded.permissions.includes("admin") ||
        decoded.permissions.includes("staff")
      ) {
        authProvider.permissions = decoded.permissions;
        authProvider.identity = JSON.parse(decoded.sub);
      } else if (decoded.permissions.includes("driver")) {
        authProvider.permissions = decoded.permissions;
        authProvider.identity = JSON.parse(decoded.sub);
      }
    });
  },

  logout: () => {
    authProvider.permissions = ["none"];
    authProvider.identity = { id: "", username: "" };
    logout();
    return Promise.resolve();
  },

  checkError: async (error: any) => {
    if (error.status === 401) {
      authProvider.permissions = ["none"];
      authProvider.identity = { id: "", username: "" };
      return Promise.reject(error.response);
    }
    return Promise.resolve();
  },

  checkAuth: async () => {
    if (
      authProvider.permissions.includes("admin") ||
      authProvider.permissions.includes("staff") ||
      authProvider.permissions.includes("driver")
    ) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },

  getIdentity: () => {
    const { id, username } = authProvider.identity;
    return id && username
      ? Promise.resolve({ id: id, fullName: username, avatar: "" })
      : Promise.reject({ message: "Invalid identity" });
  },

  getPermissions: () => {
    const permissions: object = authProvider.permissions;
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
};

export default authProvider;
