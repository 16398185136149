import React, { FC } from 'react';
import { List, SimpleList } from 'react-admin';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const UserListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        rowClick="edit"
        exporter={false}
      >
        <SimpleList
          primaryText={record => record.username}
          tertiaryText={record => {
            return record.is_superuser ? <SupervisorAccountIcon /> : <></>;
          }}
          linkType="show"
        />
      </List>
    </>
  );
};

export default UserListMobile;