import React, { FC } from 'react';
import { List, SimpleList } from 'react-admin';

const SettingListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
      >
        <SimpleList
          primaryText={record => record.key}
          secondaryText={record => record.value}
          linkType="edit"
        />
      </List>
    </>
  );
};

export default SettingListMobile;