import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EditDialog } from '@react-admin/ra-form-layout';
import React, { FC } from 'react';
import { BooleanInput, DeleteButton, SelectInput } from 'react-admin';
import {
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required
} from 'react-admin';


const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const TelegramEditTitle = (record: any) =>
  record
    ? <span>{record.key}</span>
    : null;

const TelegramEditToolbar = (props: any) => {
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <DeleteButton />
      <SaveButton />
    </Toolbar>
  );
};

const TelegramEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <>
      <EditDialog
        {...props}
        title={<TelegramEditTitle />}
        fullScreen={isSmall}
      >
        <SimpleForm
          toolbar={<TelegramEditToolbar />}
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%',
          }}
        >
          <TextInput disabled source="id" className={classes.mobileInput} />
          <TextInput source="name" className={classes.mobileInput} />
          <SelectInput
            source="trigger"
            choices={[
              { id: 'admin_create_order', name: 'custom.telegram.admin_create_order' },
              { id: 'admin_create_order_urgent', name: 'custom.telegram.admin_create_order_urgent' },
              { id: 'admin_update_order', name: 'custom.telegram.admin_update_order' },
              { id: 'admin_update_order_urgent', name: 'custom.telegram.admin_update_order_urgent' },
              { id: 'admin_confirm_order', name: 'custom.telegram.admin_confirm_order' },
              { id: 'admin_confirm_order_urgent', name: 'custom.telegram.admin_confirm_order_urgent' },
              { id: 'admin_deliver_order', name: 'custom.telegram.admin_deliver_order' },
              { id: 'admin_deliver_order_urgent', name: 'custom.telegram.admin_deliver_order_urgent' },
              { id: 'admin_cancel_order', name: 'custom.telegram.admin_cancel_order' },
              { id: 'admin_cancel_order_urgent', name: 'custom.telegram.admin_cancel_order_urgent' },
              { id: 'customer_create_order', name: 'custom.telegram.customer_create_order' },
              { id: 'customer_create_order_urgent', name: 'custom.telegram.customer_create_order_urgent' },
              { id: 'customer_confirm_order', name: 'custom.telegram.customer_confirm_order' },
              { id: 'customer_confirm_order_urgent', name: 'custom.telegram.customer_confirm_order_urgent' },
              { id: 'customer_cancel_order', name: 'custom.telegram.customer_cancel_order' },
              { id: 'customer_cancel_order_urgent', name: 'custom.telegram.customer_cancel_order_urgent' },
            ]}
            allowEmpty={false}
            style={{ width: '100%' }}
            validate={required()}
            className={classes.mobileInput}
          />
          <TextInput source="recipient" className={classes.mobileInput} />
          <TextInput multiline source="message" className={classes.mobileInput} />
          <BooleanInput source="is_active" className={classes.mobileInput} />
        </SimpleForm>
      </EditDialog>
    </>
  );
};

export default TelegramEditMobile;