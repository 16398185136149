import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import UserList from './UserList';
import UserEditMobile from './UserEditMobile';

const UserEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <UserEditMobile {...props} />
          : <UserList {...props} />
      }
    </>
  );
};

export default UserEdit;