// in src/users.js
import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CreateDialog } from "@react-admin/ra-form-layout";
import React, { FC } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
  SelectInput,
  required,
} from "react-admin";

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: "100%",
    margin: 0,
    padding: 0,
  },
}));

const ExpenseCreateTitle = (record: any) =>
  record ? <span>{record.username}</span> : null;

const ExpenseCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const ExpenseCreateMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  return (
    <CreateDialog
      {...props}
      title={<ExpenseCreateTitle />}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<ExpenseCreateToolbar />}
        style={{
          display: "block",
          margin: "auto",
          width: "100%",
        }}
      >
        <SelectInput source="expense" validate={required()} choices={[
          { id: 'Rifornimento', name: 'Rifornimento' },
          { id: 'Peddagi', name: 'Peddagi' },
          { id: 'Cibo', name: 'Cibo' },
          { id: 'Compensi', name: 'Compensi' },
          { id: 'Altro', name: 'Altro' },
        ]} />
        <NumberInput source="amount" className={classes.mobileInput} />
      </SimpleForm>
    </CreateDialog>
  );
};

export default ExpenseCreateMobile;
