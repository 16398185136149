import { Address } from '../types/types'

export const googleAddressFormatting = (googleAddress: any): string | undefined => {
    const newAddress = {
        street: "",
        zipcode: "",
        province: "",
        city: "",
        country: "",
        place_id: "",
    };
    if (googleAddress && Object.keys(googleAddress).length !== 0) {
        const base = googleAddress.value
        const l = base?.terms.length;
        newAddress["street"] = base?.structured_formatting.main_text;
        if (l >= 6) {
            newAddress["zipcode"] = base?.terms[2].value;
        }
        newAddress["city"] = base?.terms[l - 3].value;
        newAddress["province"] = base?.terms[l - 2].value;
        newAddress["country"] = base?.terms[l - 1].value;
        newAddress["place_id"] = base?.place_id;
        return JSON.stringify(newAddress);
    } else { return undefined; }
}


export const addressFormatting = (address: Address) => {
    const fullAddress = [
        address.street,
        address.zipcode,
        address.province,
        address.city
    ].filter(Boolean).join(", ");
    return fullAddress;
};