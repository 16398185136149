import Typography from "@material-ui/core/Typography";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import React, { FC } from "react";
import {
  AutocompleteInput,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  TextField,
  useFormGroup,
  useTranslate,
} from "react-admin";
import PercentageField from "../../Components/PercentageField";
import useStyles from "./orderStylesDesktop";

const OrderItemCreateForm: FC = (props: any) => {
  return (
    <RowForm {...props}>
      <ReferenceInput
        source="product_id"
        reference="products"
        perPage={100}
        filter={{ shop_enabled: true }}
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <NumberInput source="quantity" validate={required()} />
      <NumberInput source="base_price" defaultValue={0} disabled />
      <NumberInput
        disabled
        source="discount_percentage"
        defaultValue={0}
        min={0}
        max={100}
        validate={[
          minValue(0, "custom.zero_min_error"),
          maxValue(100, "custom.hundred_max_error"),
        ]}
      />
      <NumberInput disabled defaultValue={0} source="net_price" />
    </RowForm>
  );
};

const OrderItemEditForm: FC = (props: any) => {
  return (
    <RowForm {...props}>
      <ReferenceInput
        source="product_id"
        reference="products"
        perPage={100}
        filter={{ shop_enabled: true }}
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <NumberInput source="quantity" validate={required()} />
      <NumberField source="base_price" />
      <NumberInput
        source="discount_percentage"
        min={0}
        max={100}
        validate={[
          minValue(0, "custom.zero_min_error"),
          maxValue(100, "custom.hundred_max_error"),
        ]}
      />
      <NumberField source="net_price" />
    </RowForm>
  );
};

const BasketWrapper = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const formGroupState = useFormGroup("orderDesktop");
  if (formGroupState.invalid || formGroupState.dirty) {
    return (
      <>
        <div>
          <Typography className={classes.wrapperText}>
            {translate("custom.save_changes_products")}
          </Typography>
        </div>
        <div className={classes.wrapper}>{props.children}</div>
      </>
    );
  }
  return <>{props.children}</>;
};

const BasketItems = (props: any) => {
  const id = props.record.id;

  return (
    <ReferenceManyField target="order_id" reference="order_items">
      <EditableDatagrid
        undoable={true}
        createForm={
          <OrderItemCreateForm
            //@ts-ignore
            initialValues={{ order_id: id }}
          />
        }
        editForm={<OrderItemEditForm />}
        style={{
          width: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        <ReferenceField source="product_id" reference="products">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="quantity" />
        <NumberField
          source="base_price"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        />

        <PercentageField
          //@ts-ignore
          source="discount_percentage"
          style={{
            float: "right",
          }}
        />

        <NumberField
          source="net_price"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

const Basket: FC = (props: any) => {
  if (!props.record) {
    return <></>;
  }
  return (
    <>
      <BasketWrapper {...props}>
        <BasketItems {...props} />
      </BasketWrapper>
    </>
  );
};

export default Basket;
