import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import TimeslotListDesktop from './TimeslotListDesktop';
import TimeslotListMobile from './TimeslotListMobile';

const TimeslotList: FC = (props: any) => {

  const isMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md') // 1280px min
  );

  return (
    <>
      {
        isMobile
          ? <TimeslotListMobile {...props} />
          : <TimeslotListDesktop {...props} />
      }
    </>
  );
};

export default TimeslotList;