import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spanDraft: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px #e0e0e0`,
      color: theme.palette.common.black,
      backgroundColor: "#e0e0e0",
      fontWeight: 600,
      padding: theme.spacing(0, 4),
    },
    spanPending: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.warning.main}`,
      // color: theme.palette.warning.contrastText,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
      fontWeight: 600,
      padding: theme.spacing(0, 4),
    },
    spanConfirmed: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}`,
      // color: theme.palette.primary.contrastText,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 600,
      padding: theme.spacing(0, 4),
    },
    spanDelivered: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.success.main}`,
      // color: theme.palette.success.contrastText,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
      fontWeight: 600,
      padding: theme.spacing(0, 4),
    },
    spanCancelled: {
      borderRadius: 12,
      boxShadow: `0px 0px 0px 8px ${theme.palette.error.main}`,
      // color: theme.palette.error.contrastText,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: 600,
      padding: theme.spacing(0, 4),
    },
  })
);

const StatusChip = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!props.record) {
    return null;
  }
  return (
    <>
      {props.record.status === "draft" && (
        <span className={classes.spanDraft}>
          {translate("custom.orders.draft")}
        </span>
      )}
      {props.record.status === "pending" && (
        <span className={classes.spanPending}>
          {translate("custom.orders.pending")}
        </span>
      )}
      {props.record.status === "confirmed" && (
        <span className={classes.spanConfirmed}>
          {translate("custom.orders.confirmed")}
        </span>
      )}
      {props.record.status === "delivered" && (
        <span className={classes.spanDelivered}>
          {translate("custom.orders.delivered")}
        </span>
      )}
      {props.record.status === "cancelled" && (
        <span className={classes.spanCancelled}>
          {translate("custom.orders.cancelled")}
        </span>
      )}
    </>
  );
};

export default StatusChip;
