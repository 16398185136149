import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import ReportList from "./ReportList";

const reports = {
  list: ReportList,
  icon: AccountBalanceIcon,
};

export default reports;
