const customEnglishMessages = {
  custom: {
    dashboard: 'Dashboard',
    customers_sync_ok: 'Customers synchronized successfully.',
    products_sync_ok: 'Products synchronized successfully.',
    timeslots_sync_ok: 'Timeslots synchronized successfully.',
    sync_error: 'Could not synchronize.',
    sync_fatture: 'Sync with Fatture in Cloud',
    sync_timeslots: 'Sync with ShipOx',
    sync_customers_fatture: 'Sync customers with Fatture in Cloud',
    sync_products_fatture: 'Sync products with Fatture in Cloud',
    sync_timeslots_shipox: 'Sync timeslots with ShipOx',
    save_changes_products: 'Please save your changes before editing products',
    contacts: 'Contacts',
    contact: 'Contact',
    addresses: 'Addresses',
    discount_rules: 'Discount rules',
    status: "Status",
    dli: {
      rules: {
        select: 'Select rules',
        unselect: 'Remove rules',
        availableItems: 'Available rules',
        selectedItems: 'Select rules',
      },
      customers: {
        select: 'Select customers',
        unselect: 'Remove customers',
        availableItems: 'Available customers',
        selectedItems: 'Select customers',
      }
    },
    target_products: 'Target products',
    products: 'Products',
    target_customers: 'Target customers',
    zero_min_error: "0 is the minimum",
    hundred_max_error: "100 is the maximum",
    orders: {
      draft: 'draft',
      pending: 'pending',
      confirmed: 'confirmed',
      delivered: 'delivered',
      cancelled: 'cancelled',
    },
    filters: {
      all: 'All',
      yesterday: 'Yesterday',
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
    order_updated: "Order updated",
    orders_confirmed: "Orders have been confirmed",
    order_confirmed_error: "Could not update orders",
    confirm_orders: 'Confirm orders',
    pcb: {
      draft: 'Total products in selected draft orders',
      pending: 'Total products in selected pending orders',
      confirmed: 'Total products in selected confirmed orders',
      delivered: 'Total products in selected delivered orders',
      cancelled: 'Total products in selected cancelled orders',
    },
    menu: {
      dashboard: 'Dashboard',
      orders: 'Orders',
      customers: 'Customers',
      products: 'Products',
      discount_rules: 'Discount rules',
      users: 'Users',
      events: 'Events',
      settings: 'Settings',
    },
    mobile_datagrid: {
      product_id: "Prd",
      quantity: "Qty",
      discount_percentage: "Dsc%",
      net_price: "Prc",
    },
    save_and_stay: "Save and stay",
    mass_confirm: "Mass Confirm",
    mass_confirm_question: "Would you like to confirm all these orders?",
    update_fatture: "Update Fatture in Cloud",
    update_shipox: "Update ShipOx",
    restart_server: "Restart server",
    server_restart_error: 'Error while restarting',
    server_restart_ok: 'Server restarted please wait 30 seconds',
    delivery_address: 'Delivery Address',
    telephone: 'Telephone',
    save_contact: 'Save Contact',
    save_address: 'Save Address',
    deliver_orders: "Mark orders as Delivered",
    mass_deliver: "Mass Deliver",
    mass_deliver_question: "Would you like to mark all these orders as delivered?",
    orders_delivered: "Orders have been marked as delivered",
    order_delivered_error: "Could not update orders as delivered",
    customer_name: "Customer name",
    telegram: {
      "admin_create_order": "Order created by team",
      "admin_create_order_urgent": "Urgent order created by team",
      "admin_update_order": "Order updated by team",
      "admin_update_order_urgent": "Urgent order updated by team",
      "admin_confirm_order": "Order confirmed by team",
      "admin_confirm_order_urgent": "Urgent order confirmed by team",
      "admin_deliver_order": "Order marked as delivered by team",
      "admin_deliver_order_urgent": "Urgent order marked as delivered by team",
      "admin_cancel_order": "Order cancelled by team",
      "admin_cancel_order_urgent": "Urgent order cancelled by team",
      "customer_create_order": "Order created by customer",
      "customer_create_order_urgent": "Urgent order created by customer",
      "customer_confirm_order": "Order confirmed by customer",
      "customer_confirm_order_urgent": "Urgent order confirmed by customer",
      "customer_cancel_order": "Order cancelled by customer",
      "customer_cancel_order_urgent": "Urgent order cancelled by customer",
    }
  }
};

export default customEnglishMessages;