import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import SettingListDesktop from './SettingListDesktop';
import SettingListMobile from './SettingListMobile';

const SettingList: FC = (props: any) => {

  const isMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md') // 1280px min
  );

  return (
    <>
      {
        isMobile
          ? <SettingListMobile {...props} />
          : <SettingListDesktop {...props} />
      }
    </>
  );
};

export default SettingList;