import { makeStyles, Theme } from '@material-ui/core/styles';
import { CreateDialog, EditDialog } from '@react-admin/ra-form-layout';
import { FilterWithSave, SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, FC } from 'react';
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  required,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext
} from 'react-admin';
import LongPagination from '../Components/LongPagination';

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const TelegramFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput source="is_active" />
  </FilterWithSave>
);

const TelegramEditTitle = (record: any) =>
  record
    ? <span>{record.name}</span>
    : null;

const TelegramEditToolbar = (props: any) => {
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <DeleteButton />
      <SaveButton />
    </Toolbar>
  );
};

const TelegramListColumns = {
  id: <TextField source="id" />,
  name: <TextField source="name" />,
  trigger: <TextField source="trigger" />,
  recipient: <TextField source="recipient" />,
  message: <TextField source="message" />,
  is_active: <BooleanField source="is_active" />,
};

const ListActions: FC<ListActionsProps> = (props: any) => {

  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      { filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })
      }
      <CreateButton basePath="/telegrams" />

      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="telegrams.list.columns"
        columns={TelegramListColumns}
      />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />

    </TopToolbar >
  );
};

const TelegramListDesktop: FC = (props) => {
  const classes = useStyles();
  const columns = useSelectedColumns({
    preferences: 'telegrams.list.columns',
    columns: TelegramListColumns,
    omit: [''],
  });

  return (
    <>
      <List
        {...props}
        hasCreate
        empty={false}
        perPage={100}
        sort={{ field: 'id', order: 'ASC' }}
        pagination={<LongPagination />}
        filters={<TelegramFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">
          {columns}
        </Datagrid>
      </List>
      <CreateDialog {...props}>
        <SimpleForm>
          <TextInput source="name" />
        </SimpleForm>
      </CreateDialog>
      <EditDialog
        {...props}
        title={<TelegramEditTitle />}
        maxWidth="sm"
        fullWidth
      >
        <SimpleForm
          toolbar={<TelegramEditToolbar />}
        >
          <TextInput disabled source="id" className={classes.mobileInput} />
          <TextInput source="name" className={classes.mobileInput} />
          <SelectInput
            source="trigger"
            choices={[
              { id: 'admin_create_order', name: 'custom.telegram.admin_create_order' },
              { id: 'admin_create_order_urgent', name: 'custom.telegram.admin_create_order_urgent' },
              { id: 'admin_update_order', name: 'custom.telegram.admin_update_order' },
              { id: 'admin_update_order_urgent', name: 'custom.telegram.admin_update_order_urgent' },
              { id: 'admin_confirm_order', name: 'custom.telegram.admin_confirm_order' },
              { id: 'admin_confirm_order_urgent', name: 'custom.telegram.admin_confirm_order_urgent' },
              { id: 'admin_deliver_order', name: 'custom.telegram.admin_deliver_order' },
              { id: 'admin_deliver_order_urgent', name: 'custom.telegram.admin_deliver_order_urgent' },
              { id: 'admin_cancel_order', name: 'custom.telegram.admin_cancel_order' },
              { id: 'admin_cancel_order_urgent', name: 'custom.telegram.admin_cancel_order_urgent' },
              { id: 'customer_create_order', name: 'custom.telegram.customer_create_order' },
              { id: 'customer_create_order_urgent', name: 'custom.telegram.customer_create_order_urgent' },
              { id: 'customer_confirm_order', name: 'custom.telegram.customer_confirm_order' },
              { id: 'customer_confirm_order_urgent', name: 'custom.telegram.customer_confirm_order_urgent' },
              { id: 'customer_cancel_order', name: 'custom.telegram.customer_cancel_order' },
              { id: 'customer_cancel_order_urgent', name: 'custom.telegram.customer_cancel_order_urgent' },
            ]}
            allowEmpty={false}
            style={{ width: '100%' }}
            validate={required()}
            className={classes.mobileInput}
          />
          <TextInput source="recipient" className={classes.mobileInput} />
          <TextInput multiline source="message" className={classes.mobileInput} />
          <BooleanInput source="is_active" className={classes.mobileInput} />
        </SimpleForm>
      </EditDialog>
      <div
        style={{
          padding: 16,
          margin: 16,
        }}
      >
        COMMANDS:
        <ul>
          <li>[cliente]</li>
          <li>[consegna]</li>
          <li>[contatto]</li>
          <li>[indirizzo]</li>
          <li>[nome]</li>
          <li>[ordine]</li>
          <li>[prodotti]</li>
          <li>[status]</li>
          <li>[telefono]</li>
          <li>[timeslot]</li>
          <li>[totale]</li>
        </ul>

        HTML:
        <ul>
          <li>&lt;b&gt;<b>bold</b>&lt;/b&gt;, &lt;strong&gt;<strong>bold</strong>&lt;/strong&gt;</li>
          <li>&lt;i&gt;<i>italic</i>&lt;/i&gt;, &lt;em&gt;<em>italic</em>&lt;/em&gt;</li>
          <li>&lt;u&gt;<u>underline</u>&lt;/u&gt;, &lt;ins&gt;<ins>underline</ins>&lt;/ins&gt;</li>
          <li>&lt;s&gt;<s>strikethrough</s>&lt;/s&gt;, &lt;strike&gt;<s>strikethrough</s>&lt;/strike&gt;, &lt;del&gt;<del>strikethrough</del>&lt;/del&gt;</li>
          <li>
            &lt;b&gt;<b>bold</b>
            &lt;i&gt;<b><i>italic bold</i></b>
            &lt;s&gt;<b><i><s>italic bold strikethrough</s></i></b>&lt;/s&gt;
            &lt;u&gt;<b><i><u>underline italic bold</u></i></b>&lt;/u&gt;
            &lt;/i&gt; <b>bold</b>&lt;/b&gt;
          </li>
          <li>&lt;a href="http://www.example.com/"&gt;<a href="http://www.example.com/">inline URL</a>&lt;/a&gt;</li>
          <li>&lt;a href="tg://user?id=123456789"&gt;<a href="tg://user?id=123456789">inline mention of a user</a>&lt;/a&gt;</li>
          <li>&lt;code&gt;<code>inline fixed-width code</code>&lt;/code&gt;</li>
          <li>&lt;pre&gt;<pre>pre-formatted fixed-width code block</pre>&lt;/pre&gt;</li>
          <li>&lt;pre&gt;&lt;code class="language-python"&gt;<pre><code>pre-formatted fixed-width code block written in the Python programming language</code></pre>&lt;/code&gt;&lt;/pre&gt;</li>
        </ul>
      </div >
    </>
  );
};

export default TelegramListDesktop;