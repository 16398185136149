import Typography from "@material-ui/core/Typography";
import {
  EditableDatagrid,
  RowForm,
  CancelEditButton,
  SaveRowButton,
} from "@react-admin/ra-editable-datagrid";
import React, { FC, useState, useEffect } from "react";
import {
  AutocompleteInput,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  TextField,
  SelectInput,
  useFormGroup,
  useTranslate,
  TopToolbar,
  ListButton,
  ShowButton,
  SimpleShowLayout,
  FunctionField,
} from "react-admin";
import { ItalianDateTimeField } from "../../Components/ItalianDateField";
import PercentageField from "../../Components/PercentageField";
import useStyles from "./orderStylesDesktop";

const OrderItemCreateForm: FC = (props: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [amount, setAmount] = useState(0);

  const handleStatusChange = (e: any) => {
    let status = e.target.value;
    if (status === "Parziale") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (status === "Insoluto") {
      setAmount(0);
    }
  };

  const isRequired = (value: any, allValues: any, props: any) => {
    // if (!value) {
    //   return 'Amount is required';
    // }

    // if (parseFloat(value) <= 0) {
    //   return 'Amount must be greater than 0';
    // }

    // if (isDisabled) {
    //   if (!value) {
    //     return 'Amount is required';
    //   }
    // }

    return undefined;
  };

  let choices: Array<any> = [];
  let theBalance = `${props.balance}`;
  let total_amount = `${props.initialValues.total_amount}`;
  if (theBalance === "0.0") {
    // payments done
    choices = [];
  } else if (theBalance === total_amount) {
    // initial payment
    choices = [
      { id: "Payed in cash", name: "Payed in cash" },
      { id: "Contabile", name: "Contabile" },
      { id: "Pos", name: "Pos" },
      { id: "Link", name: "Link" },
      { id: "Parziale", name: "Parziale" },
      { id: "Insoluto", name: "Insoluto" },
      { id: "Assegno", name: "Assegno" },
      { id: "Conto 1", name: "Conto 1" },
      { id: "Conto 2", name: "Conto 2" },
    ];
  } else {
    // Partially paid
    choices = [{ id: "Parziale", name: "Parziale" }];
  }

  return (
    <RowForm {...props}>
      <SelectInput
        source="status"
        validate={required()}
        onChange={handleStatusChange}
        defaultValue="Payed in cash"
        choices={choices}
      />
      <NumberInput
        source="amount"
        label={<></>}
        disabled={isDisabled}
        validate={isRequired}
      />
    </RowForm>
  );
};

const OrderItemEditForm: FC = (props: any) => {
  const {
    record,
    id,
    quitEditMode,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    undoable,
    ...rest
  } = props;

  return (
    <RowForm actions={<></>} toolbar={<></>} {...props}>
      <SelectInput
        source="status"
        validate={required()}
        disabled
        choices={[
          { id: "Payed in cash", name: "Payed in cash" },
          { id: "Contabile", name: "Contabile" },
          { id: "Pos", name: "Pos" },
          { id: "Link", name: "Link" },
          { id: "Parziale", name: "Parziale" },
          { id: "Insoluto", name: "Insoluto" },
          { id: "Assegno", name: "Assegno" },
          { id: "Conto 1", name: "Conto 1" },
          { id: "Conto 2", name: "Conto 2" },
        ]}
      />
      <NumberInput
        source="amount"
        validate={required()}
        label={<></>}
        disabled
      />
    </RowForm>
  );
};

const PaymentWrapper = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const formGroupState = useFormGroup("orderDesktop");
  if (formGroupState.invalid || formGroupState.dirty) {
    return (
      <>
        <div>
          <Typography className={classes.wrapperText}>
            {translate("custom.save_changes_products")}
          </Typography>
        </div>
        <div className={classes.wrapper}>{props.children}</div>
      </>
    );
  }
  return <>{props.children}</>;
};

const PaymentItems = (props: any) => {
  const [letAdd, setLetAdd] = useState(false);
  const [balance, setBalance] = useState(0);
  const id = props.record.id;
  const total_amount = props.record.total_amount || 0;
  const payment_balance = props.record.payment_balance || 0;

  const translate = useTranslate();

  const PostEditActions = () => (
    <TopToolbar>
      <ShowButton />
      {/* Add your custom actions */}
      <ListButton />
      {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
  );

  useEffect(() => {
    if (payment_balance === "0.0") {
      setBalance(parseFloat(payment_balance));
      setLetAdd(false);
    } else {
      setBalance(parseFloat(payment_balance));
      setLetAdd(true);
    }
  }, []);

  return (
    <SimpleShowLayout {...props}>
      <Typography variant="caption">
        {translate("ra.message.payments")}
      </Typography>
      <ReferenceManyField target="order_id" reference="payments">
        <EditableDatagrid
          undoable={false}
          createForm={
            letAdd ? (
              <OrderItemCreateForm
                //@ts-ignore
                initialValues={{ order_id: id, total_amount: total_amount }}
                balance={payment_balance}
              />
            ) : (
              <></>
            )
          }
          editForm={<OrderItemEditForm />}
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
          }}
          actions={<PostEditActions />}
        >
          <TextField source="status" />
          <TextField source="amount" />
          <ReferenceField source="user_id" reference="users">
            <FunctionField
              render={(record: any) => {
                if (record?.name) return record.name;
                return record?.username;
              }}
            />
          </ReferenceField>
          <ItalianDateTimeField
            source="created_at"
            addLabel={true}
            // className={classes.gridText}
          />
        </EditableDatagrid>
      </ReferenceManyField>
      <Typography variant="subtitle1">
        {translate("ra.message.total_balance")} {payment_balance}
      </Typography>
    </SimpleShowLayout>
  );
};

const Payment: FC = (props: any) => {
  if (!props.record) {
    return <></>;
  }
  return (
    <>
      <PaymentWrapper {...props}>
        <PaymentItems {...props} />
      </PaymentWrapper>
    </>
  );
};

export default Payment;
