import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ShipoxTokenEdit from './ShipoxTokenEdit';
import ShipoxTokenList from './ShipoxTokenList';

const ShipoxTokens = {
    list: ShipoxTokenList,
    edit: ShipoxTokenEdit,
    icon: VpnKeyIcon,
};

export default ShipoxTokens;