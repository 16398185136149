import { Theme, useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';
import ShipoxTokenEditMobile from './ShipoxTokenEditMobile';
import ShipoxTokenListDesktop from './ShipoxTokenListDesktop';

const ShipoxTokenEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <ShipoxTokenEditMobile {...props} />
          : <ShipoxTokenListDesktop {...props} />
      }
    </>
  );
};

export default ShipoxTokenEdit;