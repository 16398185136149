import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useGetManyReference, useTranslate } from 'react-admin';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { components } from 'react-select';
import poweredByGoogle from './powered_by_google_on_white.png';
import { addressFormatting, googleAddressFormatting } from './utils';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmGridAddress: {
      margin: theme.spacing(1, 0),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      flex: '1 auto',
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
    },
    orderConfirmAddress: {
      width: '100%',
      minHeight: '50px',
      minWidth: '100%',
    },
    orderConfirmAddressButton: {
      width: '100%',
      margin: theme.spacing(1, 0, 0),
      fontWeight: 600,
      maxWidth: '100%',
      borderRadius: '20em',
    },
    specialButton: {
      marginTop: theme.spacing(1),
      fontWeight: 600,
      width: '50%',
      display: 'inline-block',
      borderRadius: '20em',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
    }
  })
);

interface AddressPickerProps {
  // address: string | undefined;
  newAddress: React.Dispatch<React.SetStateAction<Place>>;
  props: any;
};

type Place = {
  description: string;
  place_id: string;
  label: string;
  lat: string;
  lng: string;
}

const AddressPickerSingle = ({props, newAddress}: AddressPickerProps,) => {
  const classes = useStyles();
  const [place, setPlace] = useState<Place>({label:'', description:'', place_id:'', lat:'', lng:''});

  const handlePlaceSelected = async (thePlace: any) => {
    const results = await geocodeByAddress(thePlace.label);
    const latLng = await getLatLng(results[0]);

    newAddress({...thePlace, ...latLng })
  }

  const Menu = (props: any) => {
    return (
      <>
        <components.Menu {...props}>
          {props.children}
          <div style={{ textAlign: 'center', zIndex: 999 }}>
            <img src={poweredByGoogle} alt="Google logo" />
          </div>
        </components.Menu>
      </>
    );
  };

  return (
    <>
      <Grid
        item
        className={classes.orderConfirmGridAddress}
      >
        <GooglePlacesAutocomplete
          selectProps={{
            place,
            onChange: handlePlaceSelected,
            components: {
              Menu,
            },
            // menuPortalTarget: document.body,
            styles: {
              container: (provided: any) => ({
                ...provided,
                width: '100%',
              }),
              input: (provided: any) => ({
                ...provided,
                fontSize: 'clamp(0.75rem, 2vw, 1rem)',
                fontWeight: 600,
                width: '100%',
                minWidth: '100%',
                display: 'inline',
                margin: 0,
                lineHeight: '46px',
                minHeight: '50px',
                zIndex: 1000,
              })
            },
          }}
          apiOptions={{ language: 'it', region: 'it' }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['it'],
            },
            types: ["address"]
          }}
        />
      </Grid>

    </>
  );
};

export default AddressPickerSingle;