const fieldsItalian = {
  resources: {
    addresses: {
      name: "Indirizzi",
      fields: {
        street: "Strada",
        zipcode: "Codice postale",
        city: "Città",
        province: "Provincia",
        country: "Paese",
        extra: "Extra",
        place_id: "Luogo id",
        lat: "Lat",
        lng: "Lng",
        customer_id: "Id cliente",
      },
    },
    contacts: {
      name: "Contatti",
      fields: {
        name: "Nome",
        email: "Email",
        telephone: "Telefono",
        electronic_post: "Posta elettronica",
        fax: "Fax",
        customer_id: "Id cliente",
      },
    },
    customers: {
      name: "Clienti",
      fields: {
        fatture_id: "Id Fatture in Cloud",
        username: "Nome utente",
        company_name: "Nome azienda",
        custom_name: "Nome personalizzato",
        country: "Paese",
        vat_number: "Partita IVA",
        fiscal_code: "Codice fiscale",
        payment_terms: "Termini di pagamento",
        extra: "Extra",
        public_administration: "Amministrazione pubblica",
        pa_code: "Codice PA",
        created_at: "Creato a",
        last_visit: "Ultima visita",
        last_order: "Ultimo ordine",
        is_active: "È attivo",
        segment: "Segmento",
        main_contact: "Contatto principale",
        shipping_address: "Indirizzo di consegna",
        split_payment: "Split payment",
        show_fatture_doc: "Mostra Fatture in Cloud doc",
      },
    },
    discount_items: {
      name: "Articoli in sconto",
      fields: {
        discount_percentage: "Percentuale di sconto",
        discount_rule_id: "Id regola di sconto",
        product_id: "Id prodotto",
      },
    },
    discount_rules: {
      name: "Regole di sconto",
      fields: {
        name: "Nome",
      },
    },
    discounts: {
      name: "Sconto",
      fields: {
        customer_id: "Id cliente",
        discount_rule_id: "Id regola sconto",
      },
    },
    events: {
      name: "Eventi",
      fields: {
        date: "Data",
        author: "Autore",
        resource: "Risorsa",
        action: "Azione",
        payload: "Payload",
      },
    },
    order_items: {
      name: "Articoli d'ordine",
      fields: {
        quantity: "Quantità",
        base_price: "Prezzo base",
        discount_percentage: "Percentuale di sconto",
        net_price: "Prezzo netto",
        order_id: "Id ordine",
        product_id: "Id prodotto",
      },
    },
    orders: {
      name: "Ordini",
      fields: {
        basket: "Carrello",
        cancelled_at: "Cancellato a",
        cancelled_by: "Cancellato da",
        confirmed_at: "Confermato a",
        confirmed_by: "Confermato da",
        contact_name: "Nome contatto",
        created_at: "Creato a",
        created_at_gte: "Creato dopo",
        created_at_lte: "Creato prima",
        currency: "Valuta",
        customer_id: "Id cliente",
        customer_name: "Nome cliente",
        delivery_date: "Data di consegna",
        delivery_date_gte: "Data di consegna dopo",
        delivery_date_lte: "Data di consegna prima",
        delivery_instructions: "Istruzioni di consegna",
        delivery_time: "Tempo di consegna",
        document_link: "Link documento",
        delivery_note: "Bolla di consegna",
        document_number: "Numero documento",
        document_type: "Tipo di documento",
        due_date: "Data di scadenza",
        internal_note: "Nota interna",
        last_update: "Ultimo aggiornamento",
        order_confirmation_token: "Token conferma ordine",
        segment: "Segmento",
        shipox_customer_id: "Id cliente shipox",
        shipox_order_id: "Id ordine shipox",
        shipox_order_number: "Numero ordine shipox",
        shipping_address: "Indirizzo di consegna",
        shipping_method: "Metodo di spedizione",
        shipping_method_id: "Id del metodo di spedizione",
        shipping_price: "Prezzo di spedizione",
        shipping_price_id: "Id prezzo di spedizione",
        shipping_weight: "Peso della spedizione",
        status: "Stato",
        tax_amount: "Importo imposta",
        tax_percentage: "Percentuale imposta",
        taxable_amount: "Importo imponibile",
        telephone: "Telefono",
        timeslot_id: "Id della fascia oraria",
        total_amount: "Importo totale",
        total_amount_gte: "Importo totale sotto",
        total_amount_lte: "Importo totale sopra",
        update_fatture: "Aggiorna Fatture in Cloud",
        update_shipox: "Aggiorna ShipOx",
        show_fatture_doc: "Mostra Fatture in Cloud doc",
        waybill: "Lettera di vettura",
        last_payment_status: "Ultimo stato di pagamento",
        last_payment_amount: "Ultimo importo di pagamento",
        payment_balance: "Bilancio di pagamento",
      },
    },
    products: {
      name: "Prodotti",
      fields: {
        abbreviation: "Abbreviazione",
        code: "Codice",
        current_stock: "Stock attuale",
        fatture_category: "Categoria Fatture in Cloud",
        fatture_enabled: "Fatture in Cloud abilitate",
        fatture_id: "Id Fatture in Cloud",
        name: "Nome",
        net_price: "Prezzo netto",
        shop_enabled: "Negozio abilitato",
        shop_name: "Nome negozio",
        shop_order: "Ordine negozio",
        step: "Passo",
        unit: "Unità",
        weight_kg: "Peso (kg)",
        length: "lunghezza (cm)",
        width: "larghezza (cm)",
        height: "altezza (cm)",
      },
    },
    segments: {
      name: "Segmenti",
      fields: {
        name: "Nome",
      },
    },
    shipox_tokens: {
      name: "Gettoni Shipox",
      fields: {
        username: "Nome utente",
        password: "Password",
        token: "Token",
        date_token: "Token data",
        shipox_id: "Id shipox",
        customer_id: "Id cliente",
        user_id: "Id utente",
      },
    },
    timeslots: {
      name: "Fasce orarie",
      fields: {
        timeslot_id: "Id timeslot",
        timeslot_availability_id: "Id disponibilità timeslot",
        name: "Nome",
        description: "Descrizione",
        start_time: "Ora di inizio",
        end_time: "Ora di fine",
        cutoff_time: "Ora limite",
        courier_type: "Tipo di corriere",
        capacity: "Capacità",
        is_active: "È attivo",
      },
    },
    users: {
      name: "Utenti",
      fields: {
        username: "Nome utente",
        hashed_password: "Hashed password",
        is_superuser: "È superuser",
        is_active: "È attivo",
      },
    },
    settings: {
      fields: {
        key: "Chiave",
        value: "Valore",
        description: "Descrizione",
      },
    },
    telegrams: {
      fields: {
        name: "Nome",
        trigger: "Trigger",
        recipient: "Destinatario",
        message: "Messaggio",
        is_active: "è attivo",
      },
    },
    trucks: {
      name: "Furgoni",
      fields: {
        name: "Nome",
        capacity: "Portata",
        is_available: "Disponibile",
      },
    },
    warehouses: {
      name: "Maggazzino",
      fields: {
        name: "Nome",
        typology: "Tipologia",
        hour_start: "Ora di inizio",
        hour_end: "Fine ora",
      },
    },
    payments: {
      fields: {
        status: "Stato",
        amount: "Importo",
        user_id: "Utente",
        created_at: "Creato a",
      },
    },
  },
};

export default fieldsItalian;
