import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import {
  FilterWithSave,
  SelectColumnsButton,
  useSelectedColumns,
} from "@react-admin/ra-preferences";
import { cloneElement, FC } from "react";
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  NumberInput,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  usePermissions,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  required
} from "react-admin";
import { ItalianDateTimeField } from "../Components/ItalianDateField";

const ListActions: FC<ListActionsProps> = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const { currentSort, resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* Add your custom actions */}
      {/* <SelectColumnsButton
        preference="expenses.list.columns"
        columns={ExpenseListColumns}
      /> */}
      <CreateButton />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const ExpenseCreateTitle = (record: any) =>
  record ? <span>{record.name}</span> : null;

const ExpenseCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const ExpenseEditToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      {/* <SaveButton redirect="list" /> */}
    </Toolbar>
  );
};

const ExpenseFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
        label="Driver"
        source="driver_id"
        reference="users"
        allowEmpty
        filter={{
          driver_role_nnil: true,
        }}
      >
        <SelectInput optionText="username" />
      </ReferenceInput>
  </FilterWithSave>
);

const ExpenseListColumns = {
  id: <TextField source="id" />,
  name: <TextField source="name" />,
  capacity: <TextField source="capacity" />,
  is_available: <BooleanField source="is_available" />,
  gps: <TextField source="gps" />,
};

const ExpenseListDesktop: FC = ({ permissions, ...props }: any) => {
  const columns = useSelectedColumns({
    preferences: "expenses.list.columns",
    columns: ExpenseListColumns,
  });

  return (
    <>
      <List
        empty={false}
        {...props}
        // sort={{ field: "expense", order: "ASC" }}
        perPage={25}
        filters={<ExpenseFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="expense" />
          <TextField source="amount" />
          <ReferenceField
            source="driver_id"
            reference="users"
            label="Driver"
          >
            <TextField source="username" />
          </ReferenceField>,
          <ItalianDateTimeField
            source="created_at"
            addLabel={true}
            // className={classes.gridText}
          />
        </Datagrid>
      </List>
      <CreateDialog {...props} title={<ExpenseCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<ExpenseCreateToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <SelectInput source="expense" validate={required()} choices={[
            { id: 'Rifornimento', name: 'Rifornimento' },
            { id: 'Peddagi', name: 'Peddagi' },
            { id: 'Cibo', name: 'Cibo' },
            { id: 'Compensi', name: 'Compensi' },
            { id: 'Altro', name: 'Altro' },
          ]} />
          <NumberInput source="amount" />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props} title={<ExpenseCreateTitle />} maxWidth="md">
        <SimpleForm
          toolbar={<ExpenseEditToolbar />}
          style={{ display: "block", margin: "auto" }}
        >
          <TextInput source="expense" disabled />
          <NumberInput source="amount" disabled />
        </SimpleForm>
      </EditDialog>
    </>
  );
};

export default ExpenseListDesktop;
