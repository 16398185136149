
import { makeStyles, Theme } from '@material-ui/core/styles';

const datagridStyles = makeStyles((theme: Theme) => ({
  table: {
    margin: 0,
    padding: 0,
  },
  thead: {
    margin: 0,
    padding: 0,
  },
  tbody: {
    margin: 0,
    padding: 0,
  },
  headerCell: {
    margin: 0,
    padding: 0,
    paddingLeft: 4,
    textAlign: "right",
    '&:first-child': {
      textAlign: 'left !important',
    },
  },
  headerRow: {
    margin: 0,
  },
  row: {
    margin: 0,
    padding: 0,
  },
  rowCell: {
    margin: 0,
    padding: 0,
    paddingLeft: 4,
    '&:first-child': {
      textAlign: "left !important",
    },
  },
  rowEven: {},
  rowOdd: {},
  clickableRow: {},
  expandHeader: {},
  expandIconCell: {},
  expandIcon: {},
  expanded: {},
  checkbox: {},
}));

export default datagridStyles;