import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import {
  Button,
  useGetList,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";

import _ from "lodash-es";

const AllConfirmButton = withStyles((theme) => ({
  button: {
    border: `2px solid ${theme.palette.primary.dark}`,
    marginTop: "-10px",
    fontWeight: 600,
    fontSize: "1rem",
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1, 4),
    "&:hover": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(1, 1, 1, 0),
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
  },
}))(Button);

const ConfirmButton = withStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}))(Button);

const OrderDriverButton = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const translate = useTranslate();

  const [open, setOpen] = useState(false);
  const [driverId, setDriverId] = useState(null);

  const [updateMany, { loading }] = useUpdateMany(
    "orders",
    props.selectedIds,
    {
      status: "pending",
      driver_id: driverId,
    },
    {
      onSuccess: () => {
        refresh();
        notify("custom.orders_confirmed");
        unselectAll("orders");
      },
      onFailure: (error) => notify("custom.orders_confirmed_error", "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDriverChange = (e: any) => {
    setDriverId(e.target.value);
  };

  const { data } = useGetList(
    "users",
    { page: 1, perPage: 10 },
    { field: "username", order: "ASC" },
    { driver_role_nnil: true }
  );

  return (
    <>
      <AllConfirmButton
        label="Set Driver"
        variant="contained"
        disabled={loading}
        onClick={handleClickOpen}
      >
        <DoneAllIcon />
      </AllConfirmButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate("custom.mass_confirm")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("custom.mass_confirm_question")}
          </DialogContentText>
          <ul>
            {props.selectedIds.map((id: string) => (
              <li key={id}>{id}</li>
            ))}
          </ul>
          {/* Here must to be the driver input */}
          {/* <FormControlLabel
            control={
              <ReferenceInput
                label="Driver"
                source="driver_id"
                reference="users"
                allowEmpty
              > */}
          {/* <SelectInput optionText="username" /> */}
          <FormControlLabel
            control={
              <Select
                native
                // value={state.age}
                // onChange={handleChange}
                inputProps={{
                  name: "age",
                  id: "age-native-simple",
                }}
                onChange={(e) => {
                  handleDriverChange(e);
                }}
              >
                <option aria-label="None" value="" />
                {_.map(data, (user: any, key: string) => (
                  <option value={user.id} key={key}>
                    {user.username}
                  </option>
                ))}
              </Select>
            }
            // label={translate("custom.update_fatture")}
            label={"Driver"}
          />
          {/* </ReferenceInput>
            }
            label={translate("custom.update_fatture")}
          /> */}
        </DialogContent>
        <DialogActions>
          <CancelButton
            onClick={handleClose}
            variant="contained"
            label="ra.action.cancel"
          />
          <ConfirmButton
            onClick={updateMany}
            color="primary"
            variant="contained"
            label="Confirm Driver"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderDriverButton;
