import React from "react";
import { DateField, useLocale } from "react-admin";

export const ItalianDateField = (props: any) => {
  const locale = useLocale();
  return (
    <DateField
      {...props}
      locales={locale === "it" ? "it-IT" : "en-GB"}
      options={{
        year: "numeric",
        month: "long",
        day: "numeric",
      }}
    />
  );
};

export const ItalianDateTimeField = (props: any) => {
  const locale = useLocale();
  return (
    <DateField
      {...props}
      locales={locale === "it" ? "it-IT" : "en-GB"}
      options={{
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }}
    />
  );
};
