import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import TelegramListDesktop from './TelegramListDesktop';
import TelegramEditMobile from './TelegramEditMobile';

const TelegramEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <TelegramEditMobile {...props} />
          : <TelegramListDesktop {...props} />
      }
    </>
  );
};

export default TelegramEdit;