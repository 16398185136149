import { addEventsForMutations } from "@react-admin/ra-audit-log";
import { Admin } from "@react-admin/ra-enterprise";
import jwt_decode from "jwt-decode";
import * as React from "react";
import { useEffect, useState } from "react";
import { HttpError, Resource, usePermissions, useTranslate } from "react-admin";
import authProvider from "./authProvider";
import axiosDataProvider from "./axiosDataProvider";
import { refresh } from "./axiosHttp";
import customers from "./customers";
import discounts from "./discounts";
import {
  CustomEventList,
  CustomLayout,
  darkTheme,
  Dashboard,
  i18nProvider,
  lightTheme,
  MyLoginPage,
} from "./layout";
import orders from "./orders";
import products from "./products";
import settings from "./settings";
import timeslots from "./timeslots";
import users from "./users";
import addresses from "./addresses";
import contacts from "./contacts";
import shipox_tokens from "./shipox_tokens";
import telegrams from "./telegrams";
import trucks from "./trucks";
import warehouses from "./warehouses";
import expenses from "./expenses";
import reports from "./reports";

const dataProvider = addEventsForMutations(axiosDataProvider, authProvider, {
  resources: [
    "orders",
    "customers",
    "products",
    "discount_rules",
    "timeslots",
    "users",
    "trucks",
    "warehouses",
    "expenses",
    "reports",
    "settings",
  ],
});

const App = () => {
  //@ts-ignore
  const [authloading, setAuthloading] = useState(true);
  const translate = useTranslate();
  //eslint-disable-next-line
  const { loading, permissions }: any = usePermissions();

  const refreshToken = () => {
    refresh()
      .then((response) => {
        if (response instanceof HttpError) {
          console.log("Error while refreshing token0: ", response);
          // ignore it
        }
        if (typeof response === "string") {
          const decoded: any = jwt_decode(response);
          if (
            decoded.permissions.includes("admin") ||
            decoded.permissions.includes("staff") ||
            decoded.permissions.includes("driver")
          ) {
            authProvider.permissions = decoded.permissions;
            authProvider.identity = JSON.parse(decoded.sub);
          }
        }
      })
      .catch((error) => {
        console.log("Error while refreshing token1: ", error);
      })
      .finally(() => {
        setAuthloading(false);
      });
  };

  // Should be executed once on mount
  useEffect(() => {
    refreshToken();
    //eslint-disable-next-line
  }, []);

  // Should be executed each period to auto refresh
  useEffect(() => {
    const intervalRefresh = setInterval(() => {
      refreshToken();
      console.log(`Refreshing at ${new Date().toISOString()}`);
    }, 600 * 1000);
    return () => clearInterval(intervalRefresh);
  });

  return loading ? (
    <>{translate("ra.page.loading")}</>
  ) : (
    <>
      {authloading ? (
        "Loading..."
      ) : (
        <Admin
          layout={CustomLayout}
          loginPage={MyLoginPage}
          dashboard={Dashboard}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          disableTelemetry
        >
          {(permissions) => [
            <Resource
              name="orders"
              {...orders}
              options={{ label: "custom.menu.orders" }}
            />,
            <Resource name="orders/list/unpaid" />,
            <Resource
              name="customers"
              {...customers}
              options={{ label: "custom.menu.customers" }}
            />,

            <Resource name="addresses" {...addresses} />,

            <Resource name="contacts" {...contacts} />,

            <Resource
              name="products"
              {...products}
              options={{ label: "custom.menu.products" }}
            />,
            permissions.includes("staff") ? (
              <Resource
                name="discount_rules"
                {...discounts}
                options={{ label: "custom.menu.discount_rules" }}
              />
            ) : (
              <Resource
                name="discount_rules"
                options={{ label: "custom.menu.discount_rules" }}
              />
            ),
            permissions.includes("admin") ? (
              <Resource {...telegrams} name="telegrams" />
            ) : null,
            permissions.includes("admin") ? (
              <Resource {...timeslots} name="timeslots" />
            ) : (
              <Resource name="timeslots" />
            ),
            permissions.includes("admin") || permissions.includes("driver") ? (
              <Resource {...trucks} name="trucks" />
            ) : (
              <Resource name="trucks" />
            ),
            permissions.includes("admin") || permissions.includes("driver") ? (
              <Resource {...warehouses} name="warehouses" />
            ) : (
              <Resource name="warehouses" />
            ),
            permissions.includes("admin") || permissions.includes("driver") ? (
              <Resource {...expenses} name="expenses" />
            ) : (
              <Resource name="expenses" />
            ),
            permissions.includes("admin") ? (
              <Resource
                name="reports/balance"
                {...reports}
                options={{ label: "reports" }}
              />
            ) : null,
            permissions.includes("admin") ? (
              <Resource {...shipox_tokens} name="shipox_tokens" />
            ) : null,
            permissions.includes("admin") ? (
              <Resource
                name="users"
                {...users}
                options={{ label: "custom.menu.users" }}
              />
            ) : (
              <Resource name="users" />
            ),
            permissions.includes("admin") ? (
              <Resource
                name="events"
                list={CustomEventList}
                options={{ label: "custom.menu.events" }}
              />
            ) : null,
            permissions.includes("admin") ? (
              <Resource
                name="settings"
                {...settings}
                options={{ label: "custom.menu.settings" }}
              />
            ) : null,
            permissions.includes("staff") ? (
              <Resource name="segments" />
            ) : (
              <Resource name="segments" />
            ),
            permissions.includes("staff") ? (
              <Resource name="order_items" />
            ) : (
              <Resource name="order_items" />
            ),
            permissions.includes("staff") ? (
              <Resource name="payments" />
            ) : (
              <Resource name="payments" />
            ),
            permissions.includes("staff") ? (
              <Resource name="discount_items" />
            ) : (
              <Resource name="discount_items" />
            ),
            permissions.includes("staff") ? (
              <Resource name="discounts" />
            ) : (
              <Resource name="discounts" />
            ),
          ]}
        </Admin>
      )}
    </>
  );
};

export default App;
