import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { useGetManyReference, useTranslate } from 'react-admin';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invertedIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
    newContactField: {
      margin: theme.spacing(1, 0),
      width: '100%',
      maxWidth: '100%',
    },
    inputIcon: {
      color: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
    telephone: {
      width: '100%',
      margin: theme.spacing(1, 0),
    },
    input: {
      fontSize: '1rem',
    },
  })
);

interface ContactPickerProps {
  props: any,
  setAddContact: React.Dispatch<React.SetStateAction<boolean>>;
  contact: string | undefined;
  setContact: React.Dispatch<React.SetStateAction<string | undefined>>;
  telephone: string | undefined;
  setTelephone: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const ContactPicker = (
  {
    props,
    setAddContact,
    contact,
    setContact,
    telephone,
    setTelephone,
  }: ContactPickerProps
) => {

  const classes = useStyles();
  const translate = useTranslate();
  const [selectedContact, setSelectedContact] = useState<string | undefined>("");
  const [contactList, setContactList] = useState<Array<any>>([]);

  const { data, loading, error } = useGetManyReference(
    'contacts',
    'customer_id',
    props.record.customer_id,
    { page: 1, perPage: 100 },
    { field: 'name', order: 'DESC' },
    {},
    'orders',
  );

  // set the contact list array to the value of data if the contact list is empty
  useEffect(() => {
    if (data && contactList.length === 0) {
      setContactList(Object.values(data));
    }
  }, [data, contactList.length]);

  // only executed on mount once and if there is no selectedContact already
  useEffect(() => {
    // we have the initial contact and telephone
    // we have the contactList set
    // we have no currently selectedContact to avoid infinite loop
    if (contact && telephone && !loading && !selectedContact) {
      // we try to find if the contact is existing in the list of contacts
      const idx = contactList.findIndex(
        (element: any) => element.name === contact && element.telephone === telephone
      );
      // if it does exist then we set the selectedContact to this guy
      if (contactList[idx]) {
        setSelectedContact(JSON.stringify(contactList[idx]));
      }
      // else we want to set the selectedContact value to directly be the value of contact
      // we want to achieve this by first extending the contactList to add this new Value
      // then by selecting this value as the selectedChoice
      else {
        const unsaved_contact = { id: "unsaved", name: contact, telephone: telephone };
        setContactList((prevContactList: any) => ([unsaved_contact, ...prevContactList]));
        setSelectedContact(JSON.stringify(unsaved_contact));
      }
    }
  }, [contact, selectedContact, telephone, contactList, loading]);

  // executed every time we manually select a contact
  useEffect(() => {
    if (selectedContact) {
      setContact(JSON.parse(selectedContact).name);
      setTelephone(JSON.parse(selectedContact).telephone);
    }
  }, [setContact, setTelephone, selectedContact]);

  if (loading) { return <></>; }
  if (error) { return <></>; }

  return (
    <>
      <TextField
        select
        label={translate('custom.contact')}
        value={selectedContact}
        variant="outlined"
        className={classes.newContactField}
        onChange={(event: any) => setSelectedContact(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={() => setAddContact(true)}>
              <IconButton>
                <AddCircleIcon
                  className={classes.invertedIcon}

                />
              </IconButton>
            </InputAdornment>
          )
        }}
      >
        <span></span>
        {
          contactList.map((contact: any) => {
            return (
              <MenuItem
                key={contact.id}
                id={contact.id}
                value={JSON.stringify(contact)}
              >
                {contact.id === "unsaved" ? "*" : null}{contact.name} {contact.telephone}
              </MenuItem>
            );
          })
        }
      </TextField>
    </>
  );
};


interface ContactCreateProps {
  setAddContact: React.Dispatch<React.SetStateAction<boolean>>;
  setNewContact: React.Dispatch<React.SetStateAction<string | undefined>>;
  setNewTelephone: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const ContactCreate = (
  {
    setAddContact,
    setNewContact,
    setNewTelephone
  }: ContactCreateProps
) => {

  const classes = useStyles();
  const translate = useTranslate();
  const [contact, setContact] = useState<string | undefined>("");
  const [telephone, setTelephone] = useState<string | undefined>("");

  useEffect(() => {
    setNewContact(contact);
    setNewTelephone(telephone?.replace(/\s/g, ''));
  }, [setNewContact, setNewTelephone, contact, telephone]);

  // on unmount reset setAddContact to false
  useEffect(() => {
    // component will mount
    return () => {
      // component will unmount
      setAddContact(false);
    }
  }, [setAddContact]);

  return (
    <>

      <TextField
        variant="outlined"
        label={translate('custom.contact')}
        className={classes.newContactField}
        value={contact}
        onChange={event => setContact(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={() => setAddContact(false)}>
              <IconButton>
                <ImportContactsIcon
                  className={classes.inputIcon}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <MuiPhoneNumber
        label={translate('custom.telephone')}
        variant="outlined"
        className={classes.telephone}
        defaultCountry={'it'}
        onlyCountries={['it']}
        InputProps={{
          classes: {
            input: classes.input,
          }
        }}
        value={telephone}
        onChange={setTelephone}
      />
    </>
  );
};