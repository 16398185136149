import { Theme, useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';
import ShipoxTokenListDesktop from './ShipoxTokenListDesktop';
import ShipoxTokenListMobile from './ShipoxTokenListMobile';

const ShipoxTokenList: FC = (props: any) => {

  const isMobile = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md') // 1280px min
  );

  return (
    <>
      {
        isMobile
          ? <ShipoxTokenListMobile {...props} />
          : <ShipoxTokenListDesktop {...props} />
      }
    </>
  );
};

export default ShipoxTokenList;