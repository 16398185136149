import SyncIcon from "@material-ui/icons/Sync";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { FilterWithSave } from "@react-admin/ra-preferences";
import React, { FC } from "react";
import {
  Button,
  CreateButton,
  FilterProps,
  List,
  sanitizeListRestProps,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  useMutation,
  useNotify,
} from "react-admin";
import LongPagination from "../Components/LongPagination";

const SettingFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
  </FilterWithSave>
);

const SettingCreateForm: FC = (props: any) => {
  return (
    <RowForm {...props}>
      <TextInput disabled source="id" />
      <TextInput source="key" />
      <TextInput source="value" />
      <TextInput multiline source="description" />
    </RowForm>
  );
};

const SettingEditForm: FC = (props: any) => {
  return (
    <RowForm {...props}>
      <TextInput disabled source="id" />
      <TextInput disabled source="key" />
      <TextInput source="value" />
      <TextInput multiline source="description" />
    </RowForm>
  );
};

//@ts-ignore
const ListActions: FC<ListActionsProps> = ({
  maxResults,
  className,
  filters,
  ...rest
}) => {
  const notify = useNotify();

  const [restart, { loading: restartLoading }] = useMutation(
    {
      type: "create",
      resource: "admin/restart",
      payload: { syncme: true },
    },
    {
      onSuccess: () => {
        notify("custom.server_restart_ok");
      },
      onFailure: (error) => notify("custom.server_restart_error", "warning"),
    }
  );

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath="/settings" />
      <Button
        onClick={restart}
        label="custom.restart_server"
        disabled={restartLoading}
      >
        <SyncIcon />
      </Button>
    </TopToolbar>
  );
};

const SettingListDesktop: FC = (props) => {
  return (
    <>
      <List
        {...props}
        hasCreate
        empty={false}
        perPage={100}
        sort={{ field: "key", order: "ASC" }}
        pagination={<LongPagination />}
        filters={<SettingFilter />}
        actions={<ListActions />}
      >
        <EditableDatagrid
          editForm={<SettingEditForm />}
          createForm={<SettingCreateForm />}
          rowClick="edit"
          noDelete
          hasCreate
        >
          <TextField source="id" />
          <TextField source="key" />
          <TextField source="value" />
          <TextField source="description" />
        </EditableDatagrid>
      </List>
    </>
  );
};

export default SettingListDesktop;
