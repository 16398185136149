import React, { FC } from 'react';
import { List, SimpleList } from 'react-admin';


// const TargetProducts = (record: any) => {
//   const { data, loading, error } = useGetOne('products', record["segment"]);
//   if (loading) { return null; }
//   if (error) { return null; }
//   try {
//     return (
//       <>
//         {
//           data?.name === "B2B"
//             ? <BusinessIcon />
//             : <EmojiPeopleIcon />
//         }
//       </>
//     );
//   }
//   catch (e) {
//     return <></>;
//   };
// };

const DiscountRuleListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        rowClick="edit"
        exporter={false}
      >
        <SimpleList
          primaryText={record => record.name}
          linkType="show"
        />
      </List>
    </>
  );
};

export default DiscountRuleListMobile;