import React, { FC } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from "react-admin";
import AddressField from "../../Components/AddressField";
import {
  ItalianDateField,
  ItalianDateTimeField,
} from "../../Components/ItalianDateField";
import PercentageField from "../../Components/PercentageField";
import StatusChip from "../../Components/StatusChip";
import Aside from "./Aside";
import FattureTokenURL from "../../Components/FattureTokenURL";
import useStyles from "./orderStylesDesktop";
import ShipoxURL from "../../Components/ShipoxURL";

const OrderAccountingInfo: FC = (props) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout {...props} className={classes.flexRowCenter}>
      <NumberField
        source="shipping_weight"
        addLabel={true}
        className={classes.gridTextPadding}
      />
      <NumberField
        source="taxable_amount"
        addLabel={true}
        options={{
          style: "currency",
          currency: "EUR",
        }}
        className={classes.gridTextPadding}
      />
      <PercentageField
        //@ts-ignore
        source="tax_percentage"
        addLabel={true}
        options={{
          style: "currency",
          currency: "EUR",
        }}
        className={classes.gridTextPadding}
      />
      <NumberField
        source="tax_amount"
        addLabel={true}
        options={{
          style: "currency",
          currency: "EUR",
        }}
        className={classes.gridTextPadding}
      />
      <NumberField
        source="total_amount"
        addLabel={true}
        options={{
          style: "currency",
          currency: "EUR",
        }}
        className={classes.gridTextPadding}
      />
      <ItalianDateField
        source="due_date"
        addLabel={true}
        className={classes.gridTextPadding}
      />
    </SimpleShowLayout>
  );
};

export const OrderShowLayout: FC = (props: any) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      className={classes.gridContainer}
      style={{ paddingBottom: "10px" }}
    >
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.paperItem}>
          <OrderPaymentsDueInfo {...props} />
        </Paper>
      </Grid>
      {/* First row with order info and customer info */}
      <Grid item xs={12} md={6}>
        <Paper elevation={2} className={classes.paperItem}>
          <OrderInfo {...props} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={2} className={classes.paperItem}>
          <OrderDeliveryInfo {...props} />
        </Paper>
      </Grid>

      {/* Second row with basket */}
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.paperItem}>
          <OrderBasketInfo {...props} />
        </Paper>
      </Grid>

      {/* Second row with payment */}
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.paperItem}>
          <OrderPaymentsInfo {...props} />
        </Paper>
      </Grid>

      {/* Third row with accounting info */}
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.paperItem}>
          <OrderAccountingInfo {...props} />
        </Paper>
      </Grid>
    </Grid>
  );
};

const OrderInfo: FC = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <SimpleShowLayout {...props}>
        <SimpleShowLayout {...props} className={classes.flexRow}>
          Id:{" "}
          <TextField source="id" addLabel={true} className={classes.gridText} />
          Status: <StatusChip source="status" addLabel={true} />
        </SimpleShowLayout>
        <FattureTokenURL />
        <BooleanField source="update_fatture" />
        <BooleanField source="show_fatture_doc" />
        <ShipoxURL />
        <BooleanField source="update_shipox" />
      </SimpleShowLayout>
    </>
  );
};

const OrderDeliveryInfo: FC = (props: any) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout {...props}>
      <ReferenceField
        source="customer_id"
        reference="customers"
        addLabel={true}
      >
        <TextField source="company_name" className={classes.gridText} />
      </ReferenceField>
      <AddressField
        source="shipping_address"
        addLabel={false}
        className={classes.gridText}
      />
      <TextField
        source="contact_name"
        addLabel={true}
        className={classes.gridText}
      />
      <TextField
        source="telephone"
        addLabel={false}
        className={classes.gridText}
      />
      <ItalianDateField
        source="delivery_date"
        addLabel={true}
        locales="it-IT"
        className={classes.gridText}
      />
      <TextField
        source="delivery_time"
        addLabel={false}
        className={classes.gridText}
      />
      <TextField
        source="delivery_instructions"
        addLabel={true}
        className={classes.gridText}
      />
      <TextField
        source="internal_note"
        addLabel={true}
        className={classes.gridText}
      />
      <TextField
        source="driver_name"
        addLabel={true}
        className={classes.gridText}
      />
    </SimpleShowLayout>
  );
};

const OrderBasketInfo: FC = (props: any) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout {...props}>
      <ReferenceManyField
        addLabel={false}
        target="order_id"
        reference="order_items"
      >
        <Datagrid>
          <ReferenceField source="product_id" reference="products">
            <TextField source="name" className={classes.gridText} />
          </ReferenceField>
          <NumberField source="quantity" className={classes.gridText} />
          <NumberField
            source="base_price"
            options={{
              style: "currency",
              currency: "EUR",
            }}
            className={classes.gridText}
          />
          <PercentageField
            //@ts-ignore
            source="discount_percentage"
            addLabel={true}
            style={{ float: "right" }}
            className={classes.gridText}
          />
          <NumberField
            source="net_price"
            options={{
              style: "currency",
              currency: "EUR",
            }}
            className={classes.gridText}
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

const OrderPaymentsInfo: FC = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const payment_balance = props.record.payment_balance || 0;
  return (
    <SimpleShowLayout {...props}>
      <Typography variant="caption">
        {translate("ra.message.payments")}
      </Typography>
      <ReferenceManyField
        addLabel={false}
        target="order_id"
        reference="payments"
      >
        <Datagrid>
          <TextField source="status" />
          <TextField source="amount" />
          <ReferenceField source="user_id" reference="users">
            <FunctionField
              render={(record: any) => {
                if (record?.name) return record.name;
                return record?.username;
              }}
            />
          </ReferenceField>
          <ItalianDateTimeField
            source="created_at"
            addLabel={true}
            // className={classes.gridText}
          />
        </Datagrid>
      </ReferenceManyField>
      <Typography variant="subtitle1">
        {translate("ra.message.total_balance")} {payment_balance}
      </Typography>
    </SimpleShowLayout>
  );
};

const OrderPaymentsDueInfo: FC = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <SimpleShowLayout {...props}>
      <Typography variant="caption">
        {translate("ra.message.payments_due")}
      </Typography>
      <ReferenceManyField
        addLabel={false}
        target="customer_id"
        reference="orders"
        source="customer_id"
        filter={{
          payment_balance_gte: 0,
          id_ne: props.record?.id,
        }}
      >
        <Datagrid rowClick="show">
          <TextField source="id" className={classes.gridText} />
          <TextField source="internal_note" className={classes.gridText} />
          <TextField
            source="last_payment_status"
            className={classes.gridText}
          />
          <NumberField
            source="last_payment_amount"
            addLabel={true}
            options={{
              style: "currency",
              currency: "EUR",
            }}
            className={classes.gridTextPadding}
          />
          <NumberField
            source="payment_balance"
            options={{
              style: "currency",
              currency: "EUR",
            }}
            className={classes.gridText}
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

const OrderShowDesktop: FC = (props: any) => {
  return (
    <Show
      {...props}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      aside={<Aside />}
    >
      <OrderShowLayout />
    </Show>
  );
};

export default OrderShowDesktop;
