import Link from '@material-ui/core/Link';
import React, { FC } from 'react';
import useStyles from '../orders/desktop/orderStylesDesktop';

const ShipoxURL: FC = (props: any) => {
  const classes = useStyles();
  if (!props.record) { return null; }
  const { record } = props;
  const href = `https://icebreakersrl.app.shipox.com/dashboard/order/list?search_type=order_number&view_v2=${record.shipox_order_id}`
  return (
    <>
      {
        record?.shipox_order_id && (
          <Link
            href={href}
            target="_blank"
            rel="noreferrer"
            color="inherit"
            className={classes.gridText}
          >
            ShipOx:
            <br />
            {record?.shipox_order_number}
            <br />
            <br />
          </Link>
        )
      }
    </>
  )
};

export default ShipoxURL;