import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EditDialog } from "@react-admin/ra-form-layout";
import React, { FC } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from "react-admin";

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: "100%",
    margin: 0,
    padding: 0,
  },
}));

const ExpenseCreateTitle = (record: any) =>
  record ? <span>{record.username}</span> : null;

const ExpenseCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const ExpenseEditToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      {/* <SaveButton redirect="list" /> */}
    </Toolbar>
  );
};

const ExpenseEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  return (
    <EditDialog
      {...props}
      title={<ExpenseCreateTitle />}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<ExpenseEditToolbar />}
        style={{
          display: "block",
          margin: "auto",
          width: "100%",
        }}
      >
        <TextInput source="expense" disabled className={classes.mobileInput} />
        <NumberInput source="amount" disabled className={classes.mobileInput} />
      </SimpleForm>
    </EditDialog>
  );
};

export default ExpenseEditMobile;
