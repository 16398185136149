import { cloneElement, FC } from "react";
import {
  BooleanField,
  Datagrid,
  // ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  DateInput,
} from "react-admin";
import { ItalianDateTimeField } from "../Components/ItalianDateField";

import Typography from "@material-ui/core/Typography";
import _ from "lodash-es";

const ListActions: FC<ListActionsProps> = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      /> */}
    </TopToolbar>
  );
};

// const exporter = (records: any) => {
//   const selectedColumns = records.map((record: any) => {
//     const {
//       customer_name, // COMPANY NAME
//       contact_name, // CONTACT
//       delivery_instructions, // CUSTOMER NOTES
//       delivery_time, // TIME SLOT (only the slot such as [10: 30-12: 30]
//       delivery_note, // "ABBREVITION" ITEMS TO BE DELIVERED
//       internal_note, // TEAM NOTES
//       segment, // B2B / B2C
//       shipping_address, // ADDRESS
//       telephone, // PHONE NUMBER
//       total_amount, //AMOUNT
//       driver_id, //AMOUNT
//     } = record;
//     const selectedColumns = {
//       customer_name,
//       contact_name,
//       delivery_instructions,
//       delivery_time,
//       delivery_note,
//       internal_note,
//       segment,
//       shipping_address,
//       telephone,
//       total_amount,
//       driver_id,
//     };
//     selectedColumns.delivery_note = delivery_note.replace("\n", "+");
//     selectedColumns.total_amount = total_amount + " EUR";
//     selectedColumns.shipping_address = addressFormatting(shipping_address);
//     return selectedColumns;
//   });

//   jsonExport(
//     selectedColumns,
//     {
//       headers: [
//         "customer_name",
//         "delivery_note",
//         "delivery_time",
//         "shipping_address",
//         "contact_name",
//         "telephone",
//         "total_amount",
//         "delivery_instructions",
//         "internal_note",
//         "segment",
//       ], // order fields in the export
//     },
//     (err: any, csv: any) => {
//       downloadCSV(csv, "orders"); // download as 'posts.csv` file
//     }
//   );
// };

const ReportFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <ReferenceInput label="User" source="user_id" reference="users" allowEmpty>
      <SelectInput optionText="username" />
    </ReferenceInput>
    <DateInput source="date_start" defaultValue={new Date()} />
    <DateInput source="date_end" defaultValue={new Date()} />
  </Filter>
);

const Aside = (props: any) => {
  if (_.isEmpty(props?.data)) return null;

  let euro = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  });

  const payments = Object.values(props?.data)?.reduce((acc: any, item: any) => {
    if (item.type === "payment") return acc + +item.amount;
    return acc;
  }, 0) as number;

  const expenses = Object.values(props?.data)?.reduce((acc: any, item: any) => {
    if (item.type === "expense") return acc + +item.amount;
    return acc;
  }, 0) as number;

  const total = payments - expenses;

  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h5">Report details</Typography>
      <div>
        <Typography
          variant="subtitle1"
          display="inline"
          style={{
            marginRight: 10,
          }}
        >
          Payments:
        </Typography>
        <Typography variant="subtitle2" display="inline">{`${euro.format(
          payments
        )}`}</Typography>
      </div>
      <div>
        <Typography
          variant="subtitle1"
          display="inline"
          style={{
            marginRight: 10,
          }}
        >
          Expenses:
        </Typography>
        <Typography variant="subtitle2" display="inline">{`${euro.format(
          expenses
        )}`}</Typography>
      </div>
      <div>
        <Typography
          variant="subtitle1"
          display="inline"
          style={{
            marginRight: 10,
          }}
        >
          Total:
        </Typography>
        <Typography variant="subtitle2" display="inline">{`${euro.format(
          total
        )}`}</Typography>
      </div>
    </div>
  );
};

const ReportListDesktop: FC = ({ permissions, ...props }: any) => {
  return (
    <>
      <List
        empty={false}
        {...props}
        sort={{ field: "created_at", order: "DESC" }}
        // sort={{ field: "expense", order: "ASC" }}
        perPage={25}
        filters={<ReportFilter />}
        filterDefaultValues={{ date_start: new Date(), date_end: new Date() }}
        actions={<ListActions />}
        bulkActionButtons={false}
        pagination={false}
        aside={<Aside />}
      >
        <Datagrid>
          <TextField source="type" />
          <TextField source="status" />
          <TextField source="amount" />
          <ReferenceField source="user_id" reference="users" label="Users">
            <TextField source="username" />
          </ReferenceField>
          <ItalianDateTimeField source="created_at" addLabel={true} />
        </Datagrid>
      </List>
    </>
  );
};

export default ReportListDesktop;
