
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EditDialog } from '@react-admin/ra-form-layout';
import { ManyToManyReferenceContextProvider } from '@react-admin/ra-relationships';
import React, { FC } from 'react';
import {
  BooleanInput,
  DeleteButton,
  PasswordInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm, TextField, TextInput,
  Toolbar,
  usePermissions
} from 'react-admin';
import TargetDiscountRules from './TargetDiscountRules';

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const CustomerEditTitle = (record: any) =>
  record
    ? <span>{record.company_name}</span>
    : null;

const CustomerEditToolbar = (props: any) => {
  const { loading, permissions } = usePermissions();
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {!loading && permissions.includes("admin") && <DeleteButton />}
      <SaveButton />
    </Toolbar>
  );
};


const CustomerEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <EditDialog
      {...props}
      title={<CustomerEditTitle />}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <ManyToManyReferenceContextProvider>
        <SimpleForm
          toolbar={<CustomerEditToolbar />}
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%',
          }}
        >
          <TextField
            source="company_name"
            style={{
              fontWeight: 600,
              textAlign: 'center',
              display: 'block',
              margin: 'auto',
              marginBottom: '32px'
            }}
            addLabel={false}
          />
          <TextInput
            source="custom_name"
            className={classes.mobileInput}
          />
          <TextInput
            source="username"
            className={classes.mobileInput}
          />
          <PasswordInput
            source="password"
            className={classes.mobileInput}
          />
          <ReferenceInput
            source="segment"
            reference="segments"
            className={classes.mobileInput}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <TextInput
            source="payment_terms"
            disabled
            className={classes.mobileInput}
          />
          <TargetDiscountRules />
          <BooleanInput
            source="split_payment"
            className={classes.mobileInput}
          />
          <BooleanInput
            source="show_fatture_doc"
            className={classes.mobileInput}
          />
          <BooleanInput
            source="is_active"
            className={classes.mobileInput}
          />
        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </EditDialog>
  );
};

export default CustomerEditMobile;