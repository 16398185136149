import React from "react";
import { Login } from "react-admin";
import wallpaper from './icebreaker_wallpaper.png';

export const MyLoginPage = () => (
    <Login
        // A random image that changes everyday
        // backgroundImage="https://source.unsplash.com/random/1600x900/daily"
        // backgroundImage="https://source.unsplash.com/random/1600x900"
        backgroundImage={wallpaper}

    />
);
