import React, { FC } from 'react';
import {
  SimpleShowLayout,
  TextField
} from 'react-admin';
import { ItalianDateTimeField } from '../../Components/ItalianDateField';
import useStyles from './orderStylesDesktop';



const Aside: FC = props => {
  const classes = useStyles();
  return (
    <SimpleShowLayout {...props}
      className={classes.gridItem}
    >
      <ItalianDateTimeField
        source="created_at"
        addLabel={true}
        className={classes.gridText}
      />
      <ItalianDateTimeField
        source="confirmed_at"
        addLabel={true}
        className={classes.gridText}
      />
      <TextField
        source="confirmed_by"
        addLabel={false}
        className={classes.gridText}
      />
      <ItalianDateTimeField
        source="cancelled_at"
        addLabel={true}
        className={classes.gridText}
      />
      <TextField
        source="cancelled_by"
        addLabel={false}
        className={classes.gridText}
      />
      <ItalianDateTimeField
        source="last_update"
        addLabel={true}
        className={classes.gridText}
      />
    </SimpleShowLayout>
  );
};

export default Aside;