const raPreferencesLanguageItalian = {
    'ra-preferences': {
        action: {
            choose_columns: 'Scegli le colonne',
            toggle_theme: 'Toggle Theme',
        },
        saved_queries: {
            label: 'Query salvate',
            query_name: 'Nome della query',
            new_label: 'Salva la query corrente...',
            new_dialog_title: 'Salva la query corrente come',
            remove_label: 'Rimuovi la query salvata',
            remove_label_with_name: 'Rimuovi query "%{name}"',
            remove_dialog_title: 'Rimuovere la query salvata?',
            remove_message: 'Sei sicuro di voler rimuovere quell\'elemento dalla tua lista di query salvate?',
            help: 'Filtra la lista e salva questa query per dopo',
        },
    },
};

export default raPreferencesLanguageItalian;