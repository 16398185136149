//@ts-nocheck

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import { CreateDialog, EditDialog } from '@react-admin/ra-form-layout';
import { ManyToManyReferenceContextProvider, ReferenceManyToManyField } from '@react-admin/ra-relationships';
import React, { FC } from 'react';
import {
  AutocompleteInput,
  ChipField,
  Datagrid,
  DeleteButton,
  List,
  maxValue,
  minValue,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar
} from 'react-admin';
import LongPagination from '../Components/LongPagination';
import PercentageField from '../Components/PercentageField';
import TargetCustomers from './TargetCustomers';

const DiscountRuleForm: FC = props => {
  return (
    <RowForm {...props}>
      <ReferenceInput
        source="product_id"
        reference="products"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={100}
      >
        <AutocompleteInput
          optionText="name"
        />
      </ReferenceInput>
      <NumberInput
        source="discount_percentage"
        min={0}
        max={100}
        validate={
          [
            minValue(0, "custom.zero_min_error"),
            maxValue(100, "custom.hundred_max_error"),
          ]
        }
      />
    </RowForm>
  );
};

const DiscountRuleToolbar = (props: any) => {
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <DeleteButton />
      <SaveButton />
    </Toolbar>
  );
};

const DiscountRuleListDesktop: FC = ({ id, ...props }) => {
  return (
    <>
      <List
        {...props}
        perPage={15}
        pagination={<LongPagination />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceManyToManyField
            source="id"
            reference="products"
            through="discount_items"
            using="discount_rule_id,product_id"
            label="custom.target_products"
            perPage={100}
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceManyToManyField>
          <ReferenceManyToManyField
            source="id"
            reference="customers"
            through="discounts"
            using="discount_rule_id,customer_id"
            label="custom.target_customers"
            perPage={1000}
          >
            <SingleFieldList>
              <ChipField source="company_name" />
            </SingleFieldList>
          </ReferenceManyToManyField>
          {/* <BooleanField source="is_active" /> */}
        </Datagrid>
      </List>

      <CreateDialog {...props}>
        <SimpleForm>
          <TextInput source="name" />
        </SimpleForm>
      </CreateDialog>

      <EditDialog
        {...props}
        id={id}
        fullWidth
        maxWidth="md"
      >
        <ManyToManyReferenceContextProvider>
          <SimpleForm
            toolbar={<DiscountRuleToolbar />}
            undoable={false}
          >
            <TextInput source="name" />

            <ReferenceManyField
              target="discount_rule_id"
              reference="discount_items"
            >
              <EditableDatagrid
                undoable
                createForm={
                  <DiscountRuleForm
                    initialValues={{ discount_rule_id: id }}
                  />
                }
                editForm={<DiscountRuleForm />}
              >
                <ReferenceField
                  source="product_id"
                  reference="products"
                  label="custom.target_products"
                >
                  <TextField source="name" />
                </ReferenceField>
                <PercentageField source="discount_percentage" />
              </EditableDatagrid>
            </ReferenceManyField>

            <TargetCustomers />

          </SimpleForm>
        </ManyToManyReferenceContextProvider>
      </EditDialog>
    </>
  );
};

export default DiscountRuleListDesktop;
