import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import DashboardDriverProps from "./Driver.types";
import styles from "./Driver.module.css";
import {
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import ArticleChip from "../../Components/ArticleChip";
import DeliveryDateField from "../../Components/DeliveryDateField";
import AddressField from "../../Components/AddressField";
import ContactField from "../../Components/ContactField";

const DashboardDriver: React.FC<DashboardDriverProps> = () => {
  const { loaded, identity } = useGetIdentity();
  const translate = useTranslate();

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12}>
        <Card elevation={2} className={styles.card}>
          <CardContent className={styles.cardContent}>
            <Typography variant="h6">
              {translate("ra.message.assigned_orders")}
            </Typography>
            {loaded && (
              <List
                resource="orders"
                basePath="/orders"
                filterDefaultValues={{
                  status: "confirmed",
                  driver_id: identity?.id,
                }}
                bulkActionButtons={false}
                actions={false}
                sort={{ field: "delivery_date", order: "ASC" }}
              >
                <Datagrid rowClick="show">
                  <DeliveryDateField
                    source="delivery_date"
                    sortBy="delivery_time"
                  />

                  <ReferenceField
                    source="customer_id"
                    reference="customers"
                    basePath="/customers"
                    link={false}
                  >
                    <TextField source="company_name" />
                  </ReferenceField>
                  <ReferenceManyField
                    reference="order_items"
                    target="order_id"
                    label="custom.products"
                  >
                    <ArticleChip />
                  </ReferenceManyField>

                  <AddressField source="shipping_address" />
                  <ContactField label="custom.contact" />
                  <TextField source="delivery_instructions" />
                  <NumberField
                    source="total_amount"
                    options={{
                      style: "currency",
                      currency: "EUR",
                    }}
                  />
                </Datagrid>
              </List>
            )}
          </CardContent>
          {/* <CardContent className={styles.cardContent}>
            {loaded && (
              <List
                resource="orders/list/unpaid"
                basePath="/"
                // filterDefaultValues={{
                //   status: "confirmed",
                //   driver_id: identity?.id,
                // }}
                bulkActionButtons={false}
                actions={false}
                // sort={{ field: "delivery_date", order: "ASC" }}
              >
                <Datagrid>
                  <DeliveryDateField
                    source="delivery_date"
                    sortBy="delivery_time"
                  />

                  <ReferenceField
                    source="customer_id"
                    reference="customers"
                    basePath="/customers"
                    link={false}
                  >
                    <TextField source="company_name" />
                  </ReferenceField>
                  <ReferenceManyField
                    reference="order_items"
                    target="order_id"
                    label="custom.products"
                  >
                    <ArticleChip />
                  </ReferenceManyField>

                  <AddressField source="shipping_address" />
                  <ContactField label="custom.contact" />
                  <TextField source="delivery_instructions" />
                  <NumberField
                    source="total_amount"
                    options={{
                      style: "currency",
                      currency: "EUR",
                    }}
                  />
                  <NumberField
                    source="payment_balance"
                    options={{
                      style: "currency",
                      currency: "EUR",
                    }}
                  />
                </Datagrid>
              </List>
            )}
          </CardContent> */}
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardDriver;
