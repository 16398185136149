import axios, { AxiosRequestConfig } from "axios";
import rateLimit, { RateLimitedAxiosInstance } from "axios-rate-limit";
import { HttpError } from "react-admin";

// axios object and config
export const axiosHttp = rateLimit(axios.create(), {
  // maxRequests: 2,
  // perMilliseconds: 1,
  maxRPS: 1000,
});

const env = process.env.REACT_APP_NODE_ENV;
axiosHttp.defaults.withCredentials = true;
axiosHttp.defaults.baseURL =
  !env || env === "development" ? "http://localhost/api" : "/api";

// axiosHttp.interceptors.response.use((config) => {
//   return config
// }, async (error) => {
//   console.log("ERROR.TOJSON() ", error.toJSON());
//   if (error.response) { console.log("ERROR.RESPONSE ", error.response) }
//   if (error.request) { console.log("ERROR.REQUEST ", error.request) }
//   const originalRequest = error.config;
//   if (
//     error.response.status === 422
//     && !originalRequest.url.includes("/auth/refresh")
//   ) {
//     return refresh();
//   }
//   return Promise.reject(
//     new HttpError(
//       (error.response && error.response.data.detail) || error.response.statusText,
//       error.response.status,
//       error.response
//     )
//   );
// });

let refreshing = false;

axiosHttp.interceptors.response.use(
  (config) => config,
  (error) => {
    if (
      error.response?.status === 422 &&
      !error.config?.url.includes("/auth/refresh") &&
      !refreshing
    ) {
      refreshing = true;
      return new Promise((resolve, reject) => {
        refreshToken(axiosHttp, error.config)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            refreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

const refreshToken = (
  axiosHttp: RateLimitedAxiosInstance,
  config: AxiosRequestConfig
) => {
  return new Promise((resolve, reject) => {
    refresh() // Endpoint to request new token
      .then(() => {
        axiosHttp
          .request(config) // Repeat the initial request
          .then((result) => {
            return resolve(result);
          })
          .catch((error) => {
            return reject(error);
          });
      })
      .catch((error) => {
        // ignore it
      });
  });
};

export const refresh = () => {
  return axiosHttp
    .get("/auth/refresh", {
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return data;
        },
      ],
    })
    .then((response) => {
      if (response && response.status === 200) {
        const access_token = response.data["access_token"];
        axiosHttp.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        return access_token;
      }
    })
    .catch((error) => {
      return new HttpError(
        (error.response && error.response.data.detail) ||
          error.response.statusText,
        error.response.status,
        error.response
      );
    });
};

export const login = ({ username, password }: any) => {
  const origin = "admin";
  return axiosHttp
    .post("/auth/login", { username, password, origin })
    .then((response) => {
      if (response && response.status === 200) {
        const access_token = response.data["access_token"];
        axiosHttp.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        return access_token;
      }
    })
    .catch((error) => {
      return Promise.reject(
        new HttpError(
          (error.response && error.response.data.detail) ||
            error.response.statusText,
          error.response.status,
          error.response
        )
      );
    });
};

export const logout = () => {
  axiosHttp.delete("/auth/logout");
  axiosHttp.defaults.headers.common["Authorization"] = "";
};

export const get_product_counters = (ids: any) => {
  return axiosHttp
    .post(`/admin/product_counters`, ids)
    .then((response) => {
      if (response && response.status === 200) {
        return response;
      }
      return null;
    })
    .catch((error) => {
      return Promise.reject(
        new HttpError(
          (error.response && error.response.data.detail) ||
            error.response.statusText,
          error.response.status,
          error.response
        )
      );
    });
};
