import AcUnitIcon from '@material-ui/icons/AcUnit';
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';


const products = {
    list: ProductList,
    edit: ProductEdit,
    icon: AcUnitIcon,
};

export default products;