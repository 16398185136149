import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import WarehouseList from "./WarehouseList";
import WarehouseCreateMobile from "./WarehouseCreateMobile";

const WarehouseCreate: FC = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <>{isSmall ? <WarehouseCreateMobile {...props} /> : <WarehouseList {...props} />}</>
  );
};

export default WarehouseCreate;
