import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import WarehouseListDesktop from "./WarehouseListDesktop";
import WarehouseListMobile from "./WarehouseListMobile";

const WarehouseList: FC = (props: any) => {
  const isMobile = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down("md") // 1280px min
  );

  return (
    <>
      {isMobile ? (
        <WarehouseListMobile {...props} />
      ) : (
        <WarehouseListDesktop {...props} />
      )}
    </>
  );
};

export default WarehouseList;
