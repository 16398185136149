import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useGetManyReference, useTranslate } from 'react-admin';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { components } from 'react-select';
import poweredByGoogle from './powered_by_google_on_white.png';
import { addressFormatting, googleAddressFormatting } from './utils';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmGridAddress: {
      margin: theme.spacing(1, 0),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      flex: '1 auto',
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
    },
    orderConfirmAddress: {
      width: '100%',
      minHeight: '50px',
      minWidth: '100%',
    },
    orderConfirmAddressButton: {
      width: '100%',
      margin: theme.spacing(1, 0, 0),
      fontWeight: 600,
      maxWidth: '100%',
      borderRadius: '20em',
    },
    specialButton: {
      marginTop: theme.spacing(1),
      fontWeight: 600,
      width: '50%',
      display: 'inline-block',
      borderRadius: '20em',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
      "@media (pointer: fine)": {
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
    addIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
    inputIcon: {
      color: theme.palette.primary.main,
      borderRadius: '20em',
      // color: theme.palette.primary.main,
      fontWeight: 600,
    },
  })
);

interface AddressPickerProps {
  setAddAddress: React.Dispatch<React.SetStateAction<boolean>>;
  address: string | undefined;
  setAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
  props: any;
};

export const AddressPicker = (
  {
    props,
    setAddAddress,
    address,
    setAddress
  }: AddressPickerProps,
) => {

  const classes = useStyles();
  const translate = useTranslate();
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>("");
  const [addressList, setAddressList] = useState<Array<any>>([]);

  const { data, loading, error } = useGetManyReference(
    'addresses',
    'customer_id',
    props.record.customer_id,
    { page: 1, perPage: 100 },
    { field: 'street', order: 'DESC' },
    {},
    'orders',
  );

  // set the address list array to the value of data if the contact list is empty
  useEffect(() => {
    if (data && addressList.length === 0) {
      setAddressList(Object.values(data));
    }
  }, [data, addressList.length]);

  // run on mount to set the initial address and to extend the address list with unsaved addresses
  useEffect(() => {
    if (address && !loading && !selectedAddress) {
      const jsonAddress = (
        typeof address === 'string'
          ? address
          : JSON.stringify(address)
      );
      const idx = addressList.findIndex(
        (element: any) => element.street === JSON.parse(jsonAddress).street
      );
      if (addressList[idx]) {
        setSelectedAddress(JSON.stringify(addressList[idx]));
      }
      else {
        const unsaved_address = {
          id: "unsaved",
          ...JSON.parse(jsonAddress),
        };
        setAddressList((prevAddressList: Array<string>) => {
          return ([unsaved_address, ...prevAddressList])
        });
        setSelectedAddress(JSON.stringify(unsaved_address));
      }
    }
  }, [address, selectedAddress, addressList, loading]);

  // executed every time we manually select an address
  useEffect(() => {
    setAddress(selectedAddress);
  }, [setAddress, selectedAddress]);

  if (loading) { return <></>; }
  if (error) { return <></>; }

  return (
    <>
      <TextField
        select
        className={classes.orderConfirmAddress}
        label={translate('custom.delivery_address')}
        id="demo-simple-select-helper"
        variant="outlined"
        value={selectedAddress}
        onChange={(event: any) => setSelectedAddress(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={() => setAddAddress(true)}>
              <IconButton>
                <AddCircleIcon
                  className={classes.addIcon}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      >
        <span></span>
        {
          addressList.map((address: any) => {
            const fullAddress = addressFormatting(address);
            return (
              <MenuItem
                key={address.id}
                id={address.id}
                value={JSON.stringify(address)}
              >
                {address.id === "unsaved" ? "*" : null}{fullAddress}
              </MenuItem>
            );
          })
        }
      </TextField>
    </>
  );
};

interface AddressCreateProps {
  setAddAddress: React.Dispatch<React.SetStateAction<boolean>>;
  setNewAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const AddressCreate = ({
  setAddAddress,
  setNewAddress,
}: AddressCreateProps) => {

  const classes = useStyles();
  const [googleAddress, setGoogleAddress] = useState<any>({});

  // to update the parent state when address is selected
  useEffect(() => {
    if (Object.keys(googleAddress).length !== 0) {
      setNewAddress(googleAddressFormatting(googleAddress));
    }
  }, [setNewAddress, googleAddress]);

  const Menu = (props: any) => {
    return (
      <>
        <components.Menu {...props}>
          {props.children}
          <div style={{ textAlign: 'center', zIndex: 999 }}>
            <img src={poweredByGoogle} alt="Google logo" />
          </div>
        </components.Menu>
      </>
    );
  };

  return (
    <>
      <Grid
        item
        className={classes.orderConfirmGridAddress}
      >
        <IconButton onClick={() => setAddAddress(false)} style={{ display: 'inline' }}>
          <ImportContactsIcon
            className={classes.inputIcon} />
        </IconButton>
        <GooglePlacesAutocomplete
          selectProps={{
            googleAddress,
            onChange: setGoogleAddress,
            components: {
              Menu,
            },
            menuPortalTarget: document.body,
            styles: {
              container: (provided: any) => ({
                ...provided,
                width: '100%',
              }),
              input: (provided: any) => ({
                ...provided,
                fontSize: 'clamp(0.75rem, 2vw, 1rem)',
                fontWeight: 600,
                width: '100%',
                minWidth: '100%',
                display: 'inline',
                margin: 0,
                lineHeight: '46px',
                minHeight: '50px',
                zIndex: 1000,
              })
            },
          }}
          apiOptions={{ language: 'it', region: 'it' }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['it'],
            },
            types: ["address"]
          }}
        />
      </Grid>

    </>
  );
};