import SyncIcon from '@material-ui/icons/Sync';
import { EditDialog } from '@react-admin/ra-form-layout';
import { FilterWithSave, SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, FC } from 'react';
import {
  BooleanField,
  BooleanInput,
  Button,
  Datagrid,

  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  NumberField,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  Toolbar,
  TopToolbar,
  useListContext,
  useMutation,
  useNotify,
  usePermissions, useRefresh
} from 'react-admin';
import LongPagination from '../Components/LongPagination';

const ListActions: FC<ListActionsProps> = (props: any) => {
  const refresh = useRefresh();
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  const notify = useNotify();

  const [sync, { loading: syncLoading }] = useMutation(
    {
      type: 'create',
      resource: 'admin/sync/timeslots',
      payload: { "syncme": true }
    },
    {
      onSuccess: () => {
        refresh();
        notify('custom.timeslots_sync_ok');
      },
      onFailure: error => notify('custom.sync_error', 'warning'),
    }
  );

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="timeslots.list.columns"
        columns={timeslotListColumns}
      />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />

      <Button
        onClick={sync}
        label="custom.sync_timeslots"
        disabled={syncLoading}
      >
        <SyncIcon />
      </Button>

    </TopToolbar>
  );
};

const TimeslotFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
  </FilterWithSave>
);

const TimeslotEditTitle = (record: any) =>
  record
    ? <span>{record.name}</span>
    : null;

const TimeslotEditToolbar = (props: any) => {
  const { loading, permissions } = usePermissions();
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {!loading && permissions.includes("admin") && <DeleteButton />}
      <SaveButton />
    </Toolbar>
  );
};

const timeslotListColumns = {
  id: <TextField source="id" />,
  timeslot_id: <TextField source="timeslot_id" />,
  timeslot_availability_id: <TextField source="timeslot_availability_id" />,
  name: <TextField source="name" />,
  description: <TextField source="description" />,
  start_time: <TextField source="start_time" />,
  end_time: <TextField source="end_time" />,
  cutoff_time: <TextField source="cutoff_time" />,
  capacity: <NumberField source="capacity" />,
  courier_type: <TextField source="courier_type" />,
  is_active: <BooleanField source="is_active" />,
};

const TimeslotListDesktop: FC = (props) => {

  const columns = useSelectedColumns({
    preferences: 'timeslots.list.columns',
    columns: timeslotListColumns,
    omit: [''],
  });

  return (
    <>
      <List
        empty={false}
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={50}
        pagination={<LongPagination />}
        filters={<TimeslotFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">
          {columns}
        </Datagrid>
      </List>
      <EditDialog
        {...props}
        title={<TimeslotEditTitle />}
      >
        <SimpleForm
          toolbar={<TimeslotEditToolbar />}
        >
          <BooleanInput source="is_active" />
        </SimpleForm>
      </EditDialog>
    </>
  );
};

export default TimeslotListDesktop;