const raRealTimeItalianMessages = {
  'ra-realtime': {
    action: {
      refresh: 'Aggiorna',
    },
    event_type: {
      updated: 'aggiornato',
      created: 'creato',
      deleted: 'cancellato',
    },
    notification: {
      title: 'Attenzione',
      record: {
        updated: 'Questo record è stato aggiornato da un altro utente',
        deleted: 'Questo record è stato cancellato e non è più disponibile',
      },
      list: {
        refreshed: 'Elenco aggiornato con %{smart_count} %{name} %{type} da un altro utente',
      },
      lock: {
        lockedBySomeoneElse: 'Questo record è bloccato da qualcun altro.',
        lockedByMe: 'Hai bloccato questo disco.',
        unlocked: 'Questo record è stato sbloccato.',
      },
    },
    error: {
      type: 'Manca il tipo di evento',
      topic: 'Manca l\'argomento',
      lock: {
        noLock: 'Non c\'è nessun blocco per questo record.',
        lockedBySomeoneElse: 'Questo record è bloccato da qualcun altro.',
        cannotUnlock: 'Impossibile sbloccare questo record.',
      },
    },
  },
};

export default raRealTimeItalianMessages;
