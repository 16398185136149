import { FilterWithSave, SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, FC } from 'react';
import {
  Datagrid,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  NumberField,
  ReferenceField,
  sanitizeListRestProps,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext
} from 'react-admin';
import LongPagination from '../Components/LongPagination';

const ListActions: FC<ListActionsProps> = (props: any) => {

  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      { filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })
      }
      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="addresses.list.columns"
        columns={AddressListColumns}
      />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />

    </TopToolbar >
  );
};

const AddressFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
  </FilterWithSave>
);

const AddressListColumns = {
  id: <TextField source="id" />,
  customer_id: <TextField source="customer_id" />,
  customer_name: <ReferenceField
    source="customer_id"
    reference="customers"
    label="custom.customer_name"
  >
    <TextField source="company_name" />
  </ReferenceField>,
  street: <TextField source="street" />,
  zipcode: <TextField source="zipcode" />,
  city: <TextField source="city" />,
  province: <TextField source="province" />,
  country: <TextField source="country" />,
  extra: <TextField source="extra" />,
  place_id: <TextField source="place_id" />,
  lat: <NumberField source="lat" />,
  lng: <TextField source="lng" />,
};

const AddressListDesktop: FC = (props) => {

  const columns = useSelectedColumns({
    preferences: 'addresses.list.columns',
    columns: AddressListColumns,
    omit: [''],
  });

  return (
    <>
      <List
        empty={false}
        {...props}
        sort={{ field: 'id', order: 'ASC' }}
        perPage={25}
        pagination={<LongPagination />}
        filters={<AddressFilter />}
        actions={<ListActions />}
      >
        <Datagrid>
          {columns}
        </Datagrid>
      </List>
    </>
  );
};

export default AddressListDesktop;