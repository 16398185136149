import { EditDialog } from '@react-admin/ra-form-layout';
import { FilterWithSave, SelectColumnsButton, useSelectedColumns } from '@react-admin/ra-preferences';
import React, { cloneElement, FC } from 'react';
import {
  Datagrid,
  DeleteButton,
  ExportButton,
  FilterProps,
  List,
  ListActionsProps,
  ReferenceField,
  sanitizeListRestProps,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext
} from 'react-admin';
import LongPagination from '../Components/LongPagination';

const ListActions: FC<ListActionsProps> = (props: any) => {

  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      { filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })
      }
      {/* Add your custom actions */}
      <SelectColumnsButton
        preference="contacts.list.columns"
        columns={ContactListColumns}
      />
      <ExportButton
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />

    </TopToolbar >
  );
};


const ContactEditTitle = (record: any) =>
  record
    ? <span>{record.name}</span>
    : null;

const ContactEditToolbar = (props: any) => {
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <DeleteButton />
      <SaveButton />
    </Toolbar>
  );
};

const ContactFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
  </FilterWithSave>
);

const ContactListColumns = {
  id: <TextField source="id" />,
  customer_id: <TextField source="customer_id" />,
  customer_name: <ReferenceField
    source="customer_id"
    reference="customers"
    label="custom.customer_name"
  >
    <TextField source="company_name" />
  </ReferenceField>,
  name: <TextField source="name" />,
  email: <TextField source="email" />,
  telephone: <TextField source="telephone" />,
  eletronic_post: <TextField source="eletronic_post" />,
  fax: <TextField source="fax" />,
};

const ContactListDesktop: FC = (props) => {

  const columns = useSelectedColumns({
    preferences: 'contacts.list.columns',
    columns: ContactListColumns,
    omit: [''],
  });

  return (
    <>
      <List
        empty={false}
        {...props}
        sort={{ field: 'id', order: 'ASC' }}
        perPage={25}
        pagination={<LongPagination />}
        filters={<ContactFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">
          {columns}
        </Datagrid>
      </List>
      <EditDialog
        {...props}
        title={<ContactEditTitle />}
      >
        <SimpleForm
          toolbar={<ContactEditToolbar />}
        >
          <TextInput disabled source="id" />
          <TextInput disabled source="customer_id" />
          <TextInput source="email" />
          <TextInput source="telephone" />
          <TextInput source="electronic_post" />
          <TextInput source="fax" />
        </SimpleForm>
      </EditDialog>
    </>
  );
};

export default ContactListDesktop;