import ContactsIcon from '@material-ui/icons/Contacts';
import ContactList from './ContactList';
import ContactEdit from './ContactEdit';


const Contacts = {
    list: ContactList,
    edit: ContactEdit,
    icon: ContactsIcon,
};

export default Contacts;