import React, { FC } from "react";
import { List, SimpleList } from "react-admin";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const ExpenseListMobile: FC = (props: any) => {
  return (
    <>
      <List {...props} rowClick="edit" exporter={false}>
        <SimpleList
          primaryText={(record) => record.expense}
          secondaryText={(record) => record.amount}
          // tertiaryText={(record) => {
          //   return record.is_superuser ? <SupervisorAccountIcon /> : <></>;
          // }}
          linkType="show"
        />
      </List>
    </>
  );
};

export default ExpenseListMobile;
