import englishMessages from './englishMessages';
// import fieldsEnglish from './fieldsEnglish';
import customEnglishMessages from './customEnglishMessages';
import raRealTimeEnglishMessages from './raRealTimeEnglishMessages';
import raAuditLogLanguageEnglish from './raAuditLogLanguageEnglish';
import raPreferencesLanguageEnglish from './raPreferencesLanguageEnglish';

import italianMessages from './italianMessages';
import fieldsItalian from './fieldsItalian';
import customItalianMessages from './customItalianMessages';
import raRealTimeItalianMessages from './raRealTimeItalianMessages';
import raAuditLogLanguageItalian from './raAuditLogLanguageItalian';
import raPreferencesLanguageItalian from './raPreferencesLanguageItalian';


import { mergeTranslations } from 'react-admin';

const messages: any = {
    it: mergeTranslations(
        italianMessages,
        fieldsItalian,
        customItalianMessages,
        raRealTimeItalianMessages,
        raAuditLogLanguageItalian,
        raPreferencesLanguageItalian,
    ),
    en: mergeTranslations(
        englishMessages,
        // fieldsEnglish,
        customEnglishMessages,
        raRealTimeEnglishMessages,
        raAuditLogLanguageEnglish,
        raPreferencesLanguageEnglish,
    ),
};

export default messages;