
import Link from '@material-ui/core/Link';
import React, { FC } from 'react';
import useStyles from '../orders/desktop/orderStylesDesktop';


const FattureTokenURL: FC = (props: any) => {
  const classes = useStyles();
  if (!props.record) { return null; }
  const { record } = props;
  const href = `https://compute.fattureincloud.it/doc/${record.order_confirmation_token}.pdf`
  return (
    <>
      {
        record?.order_confirmation_token && (
          <Link
            href={href}
            target="_blank"
            rel="noreferrer"
            color="inherit"
            className={classes.gridText}
          >
            Fatture:
            <br />
            {record?.order_confirmation_token}
            <br />
            <br />
          </Link>
        )
      }
    </>
  )
};

export default FattureTokenURL;