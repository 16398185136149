import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EditDialog } from "@react-admin/ra-form-layout";
import React, { FC, useState } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Toolbar,
  usePermissions,
} from "react-admin";
import AddressPickerSingle from '../Components/AddressPickerSingle';

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: "100%",
    margin: 0,
    padding: 0,
  },
}));

const WarehouseCreateTitle = (record: any) =>
  record ? <span>{record.username}</span> : null;

const WarehouseCreateToolbar = (props: JSX.IntrinsicAttributes & any) => {
  const { loading, permissions } = usePermissions();
  const condition = !loading && permissions.includes("admin");
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: condition ? "space-between" : "flex-end",
      }}
    >
      {condition && <DeleteButton />}
      <SaveButton redirect="list" />
    </Toolbar>
  );
};

const WarehouseEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const [address, setAddress] = useState<string | undefined>();

  return (
    <EditDialog
      {...props}
      title={<WarehouseCreateTitle />}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<WarehouseCreateToolbar />}
        style={{
          display: "block",
          margin: "auto",
          width: "100%",
        }}
      >
        <TextInput source="name" />
        {/* <AddressPickerSingle props={props} address={address} setAddress={setAddress} /> */}
        <SelectInput source="typology" style={{ zIndex: 0 }} choices={[
          { id: 'basepoint', name: 'Basepoint' },
          { id: 'checkpoint', name: 'Checkpoint' },
        ]} />
        <SelectInput source="hour_start" choices={[
          { id: '8:00', name: '8:00' },
          { id: '8:30', name: '8:30' },
          { id: '9:00', name: '9:00' },
          { id: '9:30', name: '9:30' },
          { id: '10:00', name: '10:00' },
          { id: '10:30', name: '10:30' },
          { id: '11:00', name: '11:00' },
          { id: '11:30', name: '11:30' },
          { id: '12:00', name: '12:00' },
          { id: '13:00', name: '13:00' },
          { id: '14:00', name: '14:00' },
          { id: '15:00', name: '15:00' },
          { id: '16:00', name: '16:00' },
          { id: '17:00', name: '17:00' },
          { id: '18:00', name: '18:00' },
          { id: '19:00', name: '19:00' },
        ]} />
        <SelectInput source="hour_end" choices={[
          { id: '8:00', name: '8:00' },
          { id: '8:30', name: '8:30' },
          { id: '9:00', name: '9:00' },
          { id: '9:30', name: '9:30' },
          { id: '10:00', name: '10:00' },
          { id: '10:30', name: '10:30' },
          { id: '11:00', name: '11:00' },
          { id: '11:30', name: '11:30' },
          { id: '12:00', name: '12:00' },
          { id: '13:00', name: '13:00' },
          { id: '14:00', name: '14:00' },
          { id: '15:00', name: '15:00' },
          { id: '16:00', name: '16:00' },
          { id: '17:00', name: '17:00' },
          { id: '18:00', name: '18:00' },
          { id: '19:00', name: '19:00' },
        ]} />
      </SimpleForm>
    </EditDialog>
  );
};

export default WarehouseEditMobile;
