import React, { FC } from 'react';
import { List, SimpleList } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const ProductListMobile: FC = (props: any) => {

  return (
    <>
      <List
        {...props}
        actions={false}
        rowClick="edit"
      >
        <SimpleList
          primaryText={record => `${record.abbreviation || record.code} ${record.name}`}
          secondaryText={record => `€ ${record.net_price}`}
          tertiaryText={record => {
            return record.shop_enabled ? <CheckIcon /> : <ClearIcon />;
          }}
          linkType="show"
        />
      </List>
    </>
  );
};

export default ProductListMobile;