import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AddressList from './AddressList';
import AddressEdit from './AddressEdit';


const Addresses = {
    list: AddressList,
    edit: AddressEdit,
    icon: ImportContactsIcon,
};

export default Addresses;