import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { useState, useEffect } from 'react';
import { get_product_counters } from '../axiosHttp';
import { useTranslate } from 'react-admin';
import { Product } from '../types/types';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    table: {
      position: 'fixed',
      right: 10,
      bottom: 10,
      backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#303030',
      padding: 0,
      maxWidth: 'calc(100vw - 150px)',
      minHeight: 100,
      zIndex: 5,
    },
    tableHead: {
      fontWeight: 600,
      color: theme.palette.primary.main,
      fontSize: '1.125rem',
      textTransform: 'uppercase',
    },
    tableBody: {
      // border: `5px solid ${theme.palette.primary.main}`,
    },
    tableRow: {
    },
    productName: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      textAlign: 'center',
      fontSize: '1rem',
    },
    productQuantity: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textAlign: 'center',
      fontSize: '1rem',
    },
  });
});


const ProductCounterBar = (props: any) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const translate = useTranslate();

  //@ts-ignore
  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    get_product_counters(props.ids)
      .then(response => {
        if (isMounted) setData(response?.data);
      })
      .catch(error => {
        //silence it 
      });
    return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  }, [props.ids]);

  return (
    <>
      {
        data ? (
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} colSpan={Object.keys(data).length}>
                  {
                    props.filterValues.status === "draft"
                    && translate('custom.pcb.draft')
                  }
                  {
                    props.filterValues.status === "pending"
                    && translate('custom.pcb.pending')
                  }
                  {
                    props.filterValues.status === "confirmed"
                    && translate('custom.pcb.confirmed')
                  }
                  {
                    props.filterValues.status === "delivered"
                    && translate('custom.pcb.delivered')
                  }
                  {
                    props.filterValues.status === "cancelled"
                    && translate('custom.pcb.cancelled')
                  }
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              <TableRow className={classes.tableRow}>
                {
                  Object.values(data).map((product: Product) => (
                    <TableCell key={product.id} className={classes.productName}>
                      {product.abbreviation || product.code}
                    </TableCell>
                  ))
                }
              </TableRow>
              <TableRow className={classes.tableRow}>
                {
                  Object.values(data).map((product: Product) => (
                    <TableCell key={product.id} className={classes.productQuantity}>
                      {product.quantity === 0 ? "-" : product.quantity}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableBody>
          </Table>
        ) : <></>
      }
    </>
  )
};

export default ProductCounterBar;