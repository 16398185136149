import SendIcon from '@material-ui/icons/Send';
import TelegramList from './TelegramList';
import TelegramCreate from './TelegramCreate';
import TelegramEdit from './TelegramEdit';


const Telegrams = {
    list: TelegramList,
    create: TelegramCreate,
    edit: TelegramEdit,
    icon: SendIcon,
};

export default Telegrams;