import OrderIcon from '@material-ui/icons/AttachMoney';
import OrderList from './OrderList';
import OrderEdit from './OrderEdit';
import OrderShow from './OrderShow';
import OrderCreate from './OrderCreate';

const orders = {
    list: OrderList,
    create: OrderCreate,
    edit: OrderEdit,
    show: OrderShow,
    icon: OrderIcon,
};

export default orders;
