
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  buttonAll: {
    color: theme.palette.common.black,
    backgroundColor: "#e0e0e0",
    "&:hover": {
      backgroundColor: '#e0e0e0'
    },
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: '#909090'
      },
    },
    fontWeight: 600,
    minHeight: 40,
    "@media (min-width: 600px)": {
      marginTop: -8,
    },
    marginTop: 10
  },
  buttonDraft: {
    color: theme.palette.common.black,
    backgroundColor: "#e0e0e0",
    "&:hover": {
      backgroundColor: '#e0e0e0'
    },
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: '#909090'
      },
    },
    fontWeight: 600,
    minHeight: 40,
    "@media (min-width: 600px)": {
      marginTop: -8,
    },
    marginTop: 10
  },
  buttonPending: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: `${theme.palette.warning.main}`
    },
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: `${theme.palette.warning.dark}`
      },
    },
    fontWeight: 600,
    minHeight: 40,
    "@media (min-width: 600px)": {
      marginTop: -8,
    },
    marginTop: 10
  },
  buttonConfirmed: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`
    },
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: `${theme.palette.primary.dark}`
      },
    },
    fontWeight: 600,
    minHeight: 40,
    "@media (min-width: 600px)": {
      marginTop: -8,
    },
    marginTop: 10
  },
  buttonDelivered: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: `${theme.palette.success.main}`
    },
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: `${theme.palette.success.dark}`
      },
    },
    fontWeight: 600,
    minHeight: 40,
    "@media (min-width: 600px)": {
      marginTop: -8,
    },
    marginTop: 10
  },
  buttonCancelled: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: `${theme.palette.error.main}`
    },
    "@media (pointer: fine)": {
      '&:hover': {
        backgroundColor: `${theme.palette.error.dark}`
      },
    },
    fontWeight: 600,
    minHeight: 40,
    "@media (min-width: 600px)": {
      marginTop: -8,
    },
    marginTop: 10
  },
  titleDraft: {
    borderRadius: 12,
    fontWeight: 600,
  },
  titlePending: {
    borderRadius: 12,
    color: theme.palette.warning.dark,
    fontWeight: 600,
  },
  titleConfirmed: {
    borderRadius: 12,
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },
  titleDelivered: {
    borderRadius: 12,
    color: theme.palette.success.dark,
    fontWeight: 600,
  },
  titleCancelled: {
    borderRadius: 12,
    color: theme.palette.error.dark,
    fontWeight: 600,
  },
  mobileText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1rem',
  },
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
  datagridText: {
    fontSize: '0.8rem',
  },
  datagridInput: {
    padding: 0,
    margin: 0,
  },
  formRow: {
    padding: 0,
    margin: 0,
  },
  formCell: {
    padding: 1,
    margin: 0,
  },
  wrapper: {
    filter: 'blur(5px)',
    userSelect: 'none',
    pointerEvents: 'none',
    transition: '0.3s',
  },
  wrapperText: {
    textAlign: 'center',
    textDecoration: 'italic',
    color: theme.palette.error.dark,
  },
  gridText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1rem',
  },
  datagrid: {
    maxWidth: '100vw !important',
    padding: 0,
    margin: 0,
    marginLeft: -15,
  },
  screenWrap: {
    maxWidth: '100vw !important',
  }
}));

export default useStyles;