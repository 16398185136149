import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import ProductList from './ProductList';
import ProductEditMobile from './ProductEditMobile';

const ProductEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <ProductEditMobile {...props} />
          : <ProductList {...props} />
      }
    </>
  );
};

export default ProductEdit;