import React, { FC } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import ExpenseList from "./ExpenseList";
import ExpenseCreateMobile from "./ExpenseCreateMobile";

const ExpenseCreate: FC = (props: any) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <>{isSmall ? <ExpenseCreateMobile {...props} /> : <ExpenseList {...props} />}</>
  );
};

export default ExpenseCreate;
