
import { TableCell, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CancelEditButton, EditableDatagrid, SaveRowButton } from '@react-admin/ra-editable-datagrid';
import React, { FC, ReactElement, useState, useEffect } from 'react';
import {
  AutocompleteInput,
  maxValue,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  TextField,
  SelectInput,
  useFormGroup,
  useTranslate
} from 'react-admin';
import { Form } from 'react-final-form';
import PercentageField from '../../Components/PercentageField';
import datagridStyles from './basketStyles';
import useStyles from './orderStylesMobile';
import { ItalianDateTimeField } from '../../Components/ItalianDateField';

const OrderItemEditForm: FC = (props: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [amount, setAmount] = useState(0);
  const classes = useStyles();
  const {
    record,
    id,
    quitEditMode,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    undoable,
    ...rest
  } = props;

  return (
    <Form
      initialValues={{ ...record }}
      onSubmit={save}
      {...rest}
    >
      {({ handleSubmit, invalid, dirty }): ReactElement => (
        <TableRow className={classes.formRow} key={id}>
          <TableCell className={classes.formCell}>
            <SelectInput source="status" validate={required()} disabled choices={[
              { id: 'Payed in cash', name: 'Payed in cash' },
              { id: 'Contabile', name: 'Contabile' },
              { id: 'Pos', name: 'Pos' },
              { id: 'Link', name: 'Link' },
              { id: 'Parziale', name: 'Parziale' },
              { id: 'Insoluto', name: 'Insoluto' },
              { id: 'Assegno', name: 'Assegno' },
              { id: 'Conto 1', name: 'Conto 1' },
              { id: 'Conto 2', name: 'Conto 2' },
            ]} />
          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              source="amount"
              validate={required()}
              record={record}
              resource={resource}
              basePath={basePath}
              className={classes.datagridInput}
              disabled
              label={<></>}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            {/* <SaveRowButton
              dirty={dirty}
              handleSubmit={handleSubmit}
              invalid={invalid}
              quitEditMode={quitEditMode}
              saving={saving}
              undoable={undoable}
            /> */}
            <CancelEditButton cancel={quitEditMode} />
          </TableCell>
        </TableRow>
      )}
    </Form>
  );
};

const OrderItemCreateForm: FC = (props: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [amount, setAmount] = useState(0);
  const classes = useStyles();
  const {
    record,
    id,
    quitEditMode,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    undoable,
    ...rest
  } = props;

  const handleStatusChange = (e:any)=> {
    let status = e.target.value;
    if (status === 'Parziale') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (status === 'Insoluto') {
      setAmount(0);
    }
  }

  const isRequired = (value:any, allValues:any, props:any) => {
    // if (!value) {
    //   return 'Amount is required';
    // }

    // if (parseFloat(value) <= 0) {
    //   return 'Amount must be greater than 0';
    // }

    // if (isDisabled) {
    //   if (!value) {
    //     return 'Amount is required';
    //   } 
    // }

    return undefined;
  };

  let choices:Array<any> = []
  let theBalance = `${props.balance}`
  if (theBalance === '0.0') {
    // payments done
    choices = []
  } else if (theBalance === '0') {
    // initial payment
    choices = [
      { id: 'Payed in cash', name: 'Payed in cash' },
      { id: 'Contabile', name: 'Contabile' },
      { id: 'Pos', name: 'Pos' },
      { id: 'Link', name: 'Link' },
      { id: 'Parziale', name: 'Parziale' },
      { id: 'Insoluto', name: 'Insoluto' },
      { id: 'Assegno', name: 'Assegno' },
      { id: 'Conto 1', name: 'Conto 1' },
      { id: 'Conto 2', name: 'Conto 2' },
    ]
  } else {
    // Partially paid
    choices = [
      { id: 'Parziale', name: 'Parziale' },
    ]
  }

  return (
    <Form
      initialValues={{ ...record }}
      onSubmit={save}
      {...rest}
    >
      {({ handleSubmit, invalid, dirty }): ReactElement => (
        <TableRow className={classes.formRow} key={id}>
          <TableCell className={classes.formCell}>
            <SelectInput source="status" validate={required()} onChange={handleStatusChange} choices={choices} />
          </TableCell>
          <TableCell className={classes.formCell}>
            <NumberInput
              source="amount"
              record={record}
              resource={resource}
              basePath={basePath}
              className={classes.datagridInput}
              label="Amount"
              disabled={isDisabled}
              validate={isRequired}
              {...props}
            />
          </TableCell>
          <TableCell className={classes.formCell}>
            <SaveRowButton
              dirty={dirty}
              handleSubmit={handleSubmit}
              invalid={invalid}
              quitEditMode={quitEditMode}
              saving={saving}
              undoable={undoable}
            />
            <CancelEditButton cancel={quitEditMode} />
          </TableCell>
        </TableRow>
      )}
    </Form>
  );
};

const PaymentWrapper = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const formGroupState = useFormGroup("orderMobile");
  if (formGroupState.invalid || formGroupState.dirty) {
    return <>
      <div>
        <Typography
          className={classes.wrapperText}
        >
          Save
        </Typography>
      </div>
      <div className={classes.wrapper}>
        {props.children}
      </div>
    </>;
  }
  return (
    <>
      {props.children}
    </>
  );
};

const PaymentItems: FC = (props: any) => {
  const datagrid = datagridStyles();
  const classes = useStyles();
  const [letAdd, setLetAdd] = useState(false);
  const [balance, setBalance] = useState(0);
  const id = props.record.id;
  const total_amount = props.record.total_amount || 0;
  const payment_balance = props.record.payment_balance || 0;

  useEffect(() => {
    if (payment_balance === '0.0') {
      setBalance(parseFloat(payment_balance))
      setLetAdd(false)
    } else {
      setBalance(parseFloat(payment_balance))
      setLetAdd(true)
    }
  }, [])

  return (
    <>
    <ReferenceManyField
      target="order_id"
      reference="payments"
      addLabel={false}
      {...props}
    >
      <EditableDatagrid
        undoable
        rowClick="edit"
        classes={{
          table: datagrid.table,
          thead: datagrid.thead,
          tbody: datagrid.tbody,
          headerCell: datagrid.headerCell,
          headerRow: datagrid.headerRow,
          row: datagrid.row,
          rowCell: datagrid.rowCell,
        }}
        createForm={letAdd?
          <OrderItemCreateForm
            //@ts-ignore
            initialValues={{ order_id: id, total_amount: total_amount}}
            balance={payment_balance}
          />:<></>
        }
        editForm={<OrderItemEditForm />}
      >
        <TextField
          source="status"
          className={classes.datagridText}
          label="Status"
        />
        <TextField
          source="amount"
          className={classes.datagridText}
          label="Amount"
        />
        <ItalianDateTimeField
          source="created_at"
          addLabel={true}
          label="Created at"
        />
      </EditableDatagrid>
    </ReferenceManyField>
    <div>
      Total balance: {payment_balance}
    </div>
    </>
  );
};

const PaymentMobile: FC = (props: any) => {
  if (!props.record) { return <></>; }
  return (
    <>
      <PaymentWrapper {...props}>
        <PaymentItems {...props} />
      </PaymentWrapper>
    </>
  );
};

export default PaymentMobile;