import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import CustomerList from './CustomerList';
import CustomerEditMobile from './CustomerEditMobile';

const CustomerEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <CustomerEditMobile {...props} />
          : <CustomerList {...props} />
      }
    </>
  );
};

export default CustomerEdit;