import PersonIcon from '@material-ui/icons/Person';
import CustomerList from './CustomerList';
import CustomerEdit from './CustomerEdit';

const customers = {
    list: CustomerList,
    edit: CustomerEdit,
    icon: PersonIcon,
};

export default customers;