import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EditDialog } from '@react-admin/ra-form-layout';
import React, { FC } from 'react';
import {
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar
} from 'react-admin';


const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    minWidth: '100%',
    margin: 0,
    padding: 0,
  },
}));

const SettingEditTitle = (record: any) =>
  record
    ? <span>{record.key}</span>
    : null;

const SettingEditToolbar = (props: any) => {
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <SaveButton />
    </Toolbar>
  );
};

const SettingEditMobile: FC = (props: any) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );

  return (
    <EditDialog
      {...props}
      title={<SettingEditTitle />}
      fullScreen={isSmall}
    >
      <SimpleForm
        toolbar={<SettingEditToolbar />}
        style={{
          display: 'block',
          margin: 'auto',
          width: '100%',
        }}
      >
        <TextInput disabled source="id" className={classes.mobileInput} />
        <TextInput disabled source="key" className={classes.mobileInput} />
        <TextInput source="value" className={classes.mobileInput} />
        <TextInput multiline source="description" className={classes.mobileInput} />
      </SimpleForm>
    </EditDialog>
  );
};

export default SettingEditMobile;