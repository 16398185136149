import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import SettingListDesktop from './SettingListDesktop';
import SettingEditMobile from './SettingEditMobile';

const SettingEdit: FC = (props: any) => {

  const isSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {
        isSmall
          ? <SettingEditMobile {...props} />
          : <SettingListDesktop {...props} />
      }
    </>
  );
};

export default SettingEdit;