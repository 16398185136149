//@ts-nocheck
import { AppBar } from "@react-admin/ra-enterprise";
import { LanguageSwitcher } from "@react-admin/ra-preferences";
import React from "react";
import { AppBarProps, Logout, UserMenu } from "react-admin";
import messages from "../i18n";
import IcebreakerLogo from "./icebreaker_logo_transparent.png";

const CustomUserMenu = (props: any) => (
  <UserMenu {...props} logout={<Logout />} />
);

const AppBarDesktop: FC = (props: JSX.IntrinsicAttributes & AppBarProps) => (
  <AppBar
    languages={messages}
    style={{ border: 0 }}
    userMenu={<CustomUserMenu />}
    {...props}
  >
    <img
      src={IcebreakerLogo}
      style={{
        maxHeight: 60,
        position: "absolute",
        left: "calc(50% - 50px)",
      }}
      alt="Logo"
    />
    <LanguageSwitcher
      languages={[
        { locale: "en", name: "English" },
        { locale: "it", name: "Italiano" },
      ]}
    />
  </AppBar>
);

export default AppBarDesktop;
